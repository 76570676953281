import React, {useEffect, useState} from 'react';
import { collection, getDocs } from 'firebase/firestore';


import SideBar from "./SideBar";
import {
    ArrowDownCircleIcon, ArrowPathIcon,
    ArrowUpCircleIcon, CheckCircleIcon,
    EnvelopeIcon, EyeSlashIcon, GlobeAmericasIcon, IdentificationIcon, MapIcon, MoonIcon,
    PhoneIcon, PlusIcon, PrinterIcon, SunIcon,
    UserCircleIcon, UserIcon, XCircleIcon
} from "@heroicons/react/24/outline";
import {useAuth} from "../contexts/authContext";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { storage, db } from '../firebase/firebase';
import {Button, Input, Select, Textarea} from "@headlessui/react";
import {useDarkMode} from "../contexts/darkModeContext";
import {XMarkIcon} from "@heroicons/react/16/solid";
import TicketCard from "./Profile/TicketCard";

const Profile = () => {

    const { currentUser } = useAuth();

    const {darkMode, toggleDarkMode} = useDarkMode();

    const [image, setImage] = useState(null); // To store the selected image file
    const [uploading, setUploading] = useState(false); // Upload status
    const [imageURL, setImageURL] = useState(''); // To store the uploaded image URL


    const [isPassword, setIsPassword] = useState(false);
    const [isEmail, setIsEmail] = useState(false);

    const [ticket, setTicket] = useState(false);

    const [tickets, setTickets] = useState([]);
    const [closedTickets, setClosedTickets] = useState([]);


    const [category, setCategory] = useState("");
    const [page, setPage] = useState("");
    const [notes, setNotes] = useState("");


    const [title, setTitle] = useState("");
    const [body, setBody] = useState("");


    const [open, setOpen] = useState(false);

    const openVis = () => {
        setOpen(!open);
    }




    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [fullname, setFullname] = useState("");
    const [salesCode, setSalesCode] = useState("");
    const [profileImage, setProfileImage] = useState('');
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [fax, setFax] = useState("");
    const [address, setAddress] = useState("");
    const [region, setRegion] = useState("");
    const [supervisor, setSupervisor] = useState("");
    const [accountLevel, setAccountLevel] = useState("");

    const [isVisible, setIsVisible] = useState(false); // State to track visibility

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const togglePassword = () => {
        setIsPassword(!isPassword);
    }

    const toggleEmail = () => {
        setIsEmail(!isEmail);
    }

    const toggleTicket = () => {
        setTicket(!ticket);
    }

    // Function to handle the image file input
    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
        }
    };

    // Function to upload the image to Firebase Storage and save URL to Firestore
    const handleUpload = async () => {
        if (!image) return;

        setUploading(true);
        try {
            // Create a storage reference
            const storageRef = ref(storage, `profileImages/${currentUser.uid}/${image.name}`);

            // Upload the image file to Firebase Storage
            const snapshot = await uploadBytes(storageRef, image);

            // Get the download URL for the uploaded image
            const downloadURL = await getDownloadURL(snapshot.ref);

            // Save the download URL to Firestore
            await setDoc(doc(db, 'Users', currentUser.uid), {
                profileImageUrl: downloadURL,
            }, { merge: true }); // Merge to avoid overwriting existing data

            setImageURL(downloadURL); // Update the image URL state
            alert('Profile image uploaded successfully!');
        } catch (error) {
            console.error('Error uploading image:', error);
            alert('Error uploading image, please try again.');
        } finally {
            setUploading(false);
        }
    };

    const pricingMapping = {
        AG: "Agriculture",
        DIST1: "Distributor NE/INTL",
        DIST2: "Distributor SE/CAN",
        NE: "North East",
        SE: "South East"
    };

    const supervisorMapping = {
        GP: "Grant Platz",
    }

    const accountLevelMapping = {
        D: "Developer",
        A: "Admin",
        CSR: "CSR (Customer Service Rep)",
        PFC: "PFC Fulfillment",
        DIST: "Distributor",
        S: "Sales Rep"
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setLname(userData.lname);
                        setSalesCode(userData.salesCode);
                        setFullname(userData.fname + " " + userData.lname);
                        setProfileImage(userData.profileImageUrl);
                        setEmail(userData.email);
                        setPhone(userData.phone);
                        setFax(userData.fax);
                        setAddress(userData.address);
                        setRegion(userData.region);
                        setSupervisor(userData.supervisor);
                        setAccountLevel(userData.accountLevel);
                        setSelectedReps(userData.reps);


                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setLname(userData.lname);
                            setSalesCode(userData.salesCode);
                            setProfileImage(userData.profileImageUrl);
                            setEmail(userData.email);
                            setPhone(userData.phone);
                            setFax(userData.fax);
                            setAddress(userData.address);
                            setRegion(userData.region);
                            setSupervisor(userData.supervisor);
                            setAccountLevel(userData.accountLevel);
                            setSelectedReps(userData.reps);


                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchTickets = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`tickets_${currentUser.uid}`);
                    if (storedPendingData) {
                        const pendingData = JSON.parse(storedPendingData);
                        setTickets(pendingData);
                    } else {
                        const pendingCustomers = db.collection("Tickets").doc(currentUser.uid).collection("open");
                        const snapshot = await pendingCustomers.get();

                        if (!snapshot.empty) {
                            const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setTickets(pendingData);
                            localStorage.setItem(`tickets_${currentUser.uid}`, JSON.stringify(pendingData));
                        } else {
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }

        const fetchTicketsClosed = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`ticketsClosed_${currentUser.uid}`);
                    if (storedPendingData) {
                        const pendingData = JSON.parse(storedPendingData);
                        setClosedTickets(pendingData);
                    } else {
                        const pendingCustomers = db.collection("Tickets").doc(currentUser.uid).collection("closed");
                        const snapshot = await pendingCustomers.get();

                        if (!snapshot.empty) {
                            const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setClosedTickets(pendingData);
                            localStorage.setItem(`ticketsClosed_${currentUser.uid}`, JSON.stringify(pendingData));
                        } else {
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }

        fetchUserName();
        fetchTickets();
        fetchTicketsClosed();
    }, [currentUser]);


    const onUpdate = async () => {
        try {
            // Fetch user document from Firestore based on the current user's UID
            const userDoc = await db.collection('Users').doc(currentUser.uid).get();
            if (userDoc.exists) {
                // Get the user's name from the document data
                const userData = userDoc.data();
                setFname(userData.fname);
                setLname(userData.lname);
                setSalesCode(userData.salesCode);
                setProfileImage(userData.profileImageUrl);
                setEmail(userData.email);
                setPhone(userData.phone);
                setFax(userData.fax);
                setAddress(userData.address);
                setRegion(userData.region);
                setSupervisor(userData.supervisor);
                setAccountLevel(userData.accountLevel);
                setSelectedReps(userData.reps);

                setFullname(userData.fname + " " + userData.lname);

                // Save user data to localStorage
                localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
            } else {
                console.log('User document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }
    };

    const onUpdateTickets = async () => {
        try {

            const pendingCustomers = db.collection("Tickets").doc(currentUser.uid).collection("open");
            const snapshot = await pendingCustomers.get();

            if (!snapshot.empty) {
                const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setTickets(pendingData);
                localStorage.setItem(`tickets_${currentUser.uid}`, JSON.stringify(pendingData));
            } else {
                console.log('Customer document not found');
            }

        } catch (error) {
            console.error("Error updating tickets", error);
        }

        try {

            const pendingCustomers = db.collection("Tickets").doc(currentUser.uid).collection("closed");
            const snapshot = await pendingCustomers.get();

            if (!snapshot.empty) {
                const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setTickets(pendingData);
                localStorage.setItem(`ticketsClosed_${currentUser.uid}`, JSON.stringify(pendingData));
            } else {
                console.log('Customer document not found');
            }

        } catch (error) {
            console.error("Error updating tickets", error);
        }
    };



    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };


    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            // Update user data in Firestore collection
            await db.collection('Users').doc(currentUser.uid).update({
                fname: fname,
                lname: lname,
                email: email,
                phone: phone,
                fax: fax,
                address: address,
                region: region,
                accountLevel: accountLevel,
                salesCode: salesCode
            });
            console.log('User data updated successfully');
            await onUpdate()

            alert('User updated successfully');
        } catch (error) {
            console.error("Error updating user data: ", error);
        }
    };

    const [users, setUsers] = useState([]);
    const [selectedReps, setSelectedReps] = useState([]);


    // Fetch users from Firestore on component mount
    useEffect(() => {
        const fetchUsers = async () => {
            const usersCollection = collection(db, 'Users');
            const userSnapshot = await getDocs(usersCollection);
            const userList = userSnapshot.docs.map(doc => ({
                id: doc.id, // UID from Firestore document ID
                name: `${doc.data().fname} ${doc.data().lname}`, // Combining fname and lname into a single name field
            }));
            setUsers(userList);
        };
        fetchUsers();
    }, []);


    // Handle selection of a user
    const handleSelectChange = (e) => {
        const selectedUserId = e.target.value;
        const selectedUser = users.find(user => user.id === selectedUserId);

        // Add selected user to selectedReps array if not already selected
        if (selectedUser && !selectedReps.some(rep => rep.id === selectedUserId)) {
            setSelectedReps([...selectedReps, selectedUser]);
        }
    };

    // Handle removing a rep from the selectedReps list
    const handleRemoveRep = (repId) => {
        setSelectedReps(selectedReps.filter(rep => rep.id !== repId));
    };

    // Handle the update of the reps array in Firestore
    const handleUpdateReps = async () => {
        try {
            // Update user data in Firestore collection
            await db.collection('Users').doc(currentUser.uid).update({
                reps: selectedReps.map(rep => ({ id: rep.id, name: rep.name })), // Map selected reps to just their IDs
            });
            console.log('User data updated successfully');
            await onUpdate()

            alert('User updated successfully');
        } catch (error) {
            console.error("Error updating user data: ", error);
        }
    };

    const onSubmitMain = async (e) => {
        e.preventDefault();

        const collectionRef = db.collection("Tickets").doc(currentUser.uid).collection("open");

        const parsedFormData = {
            // Ticket Info
            category: category,
            page: page,
            notes: notes,

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            salesID: currentUser.uid,
            date: new Date().toLocaleDateString(),

            status: "open",
        };

        try {
            // Create a new document with a generated ID within the "open" subcollection
            await collectionRef.doc().set(parsedFormData);

            alert("Ticket Successfully Submitted"); // Optional: alert for user feedback

            // Clear the form
            setCategory("");
            setPage("");
            setNotes("");

            // Optional actions after submission
            // handleNextTab();
            onUpdateTickets();
            toggleTicket();

        } catch (error) {
            console.error("Error submitting ticket:", error.message);
            // setIsRegistered(false);
        }
    };

    const onSubmitBanner = async (e) => {
        e.preventDefault();

        // Reference to the "banner" document within the "GlobalSettings" collection
        const docRef = db.collection("GlobalSettings").doc("Banner");

        const parsedFormData = {
            title: title,  // Assuming 'title' is state holding the banner title
            body: body,    // Assuming 'body' is state holding the banner body content
            // display: true, // Optional: to control visibility of the banner
            // imageUrl: imageUrl || "", // Optional: image URL if applicable, defaults to empty
        };

        try {
            // Set the banner data in the specified document
            await docRef.set(parsedFormData);

            alert("Banner Successfully Updated"); // Optional: alert for user feedback

        } catch (error) {
            console.error("Error updating banner:", error.message);
        }
    };

    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-full overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>

            {/*Account Info Side*/}
            <div className={`animate-slideInLeft w-1/3 ${darkMode ? 'bg-darkMainColor' : 'bg-white'} relative mt-3 mb-3 rounded-lg p-3 overflow-y-auto transition-all duration-500 ease-in-out`}>

                {/*Buttons Update*/}
                <div className="absolute items-center">
                    <Button className="rounded-full hover:animate-spin mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"
                            onClick={onUpdate}
                    >
                        <ArrowPathIcon className="h-6 text-borderColor hover:text-bgLightGreen m-auto"/>
                    </Button>
                </div>


                <div className="flex flex-col h-full">

                    {/*Profile Image / Selector*/}
                    <div className="flex flex-col h-1/3 mb-3 justify-center bg items-center">

                        <div className="w-48 h-48 bg-bgColor rounded-full items-center justify-center flex border-2 border-bgDarkGreen hover:scale-105">

                            {profileImage ? (
                                <img
                                    src={profileImage}
                                    alt="Profile"
                                    className="w-full h-full object-cover rounded-full"
                                />
                            ) : (
                                <UserCircleIcon className="stroke-1 text-white" />
                            )}
                        </div>

                        <div className="flex w-full">


                            <div className="flex flex-col items-center mr-3">
                                <Button
                                    className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={toggleVisibility}
                                >

                                    {isVisible ? (
                                        <ArrowUpCircleIcon
                                            className="h-6 text-borderColor hover:text-bgLightGreen hover:scale-110 m-auto"/>
                                    ) : (
                                        <ArrowDownCircleIcon
                                            className="h-6 text-borderColor hover:text-bgLightGreen hover:scale-110 m-auto"/>
                                    )}
                                </Button>
                            </div>


                            {isVisible && (
                                <div className={`flex w-full`}>

                                    <Input
                                        className={`rounded-lg text-xs flex items-center w-full px-1 mr-5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900'}`}
                                        aria-describedby="file_input_help"
                                        id="file_input"
                                        type="file"
                                        onChange={handleImageChange}
                                        accept="image/*"
                                    />

                                    <Button onClick={handleUpload} disabled={!image || uploading}
                                            className="px-4 rounded-lg text-white bg-blue-500">
                                        {uploading ? 'Uploading...' : 'Upload'}
                                    </Button>
                                </div>
                            )}

                        </div>

                    </div>

                    {/*Account Info*/}
                    <div className={`flex w-full flex-col h-full border-2 ${darkMode ? 'border-darkBgColor' : ''} rounded-lg p-3`}>

                        {/*Name*/}
                        <div
                            className={`text-xl w-full flex flex-col items-center border-b-2 ${darkMode ? 'border-darkBgColor' : ''} mb-3`}>
                            <div className="font-semibold tracking-wider hover:tracking-widest">{fullname || "- - - - -"}</div>
                            <div className="text-xxs tracking-widest">{accountLevelMapping[accountLevel] || "- - - - -"}</div>
                        </div>

                        {/*Email*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className={`${darkMode ? 'bg-bgLightGreen' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                <EnvelopeIcon className="w-5"/>
                            </div>

                            <div className="text-sm">{email || "- - - - -"}</div>

                            <div className="ml-auto text-sm">Email</div>
                        </div>

                        {/*Phone*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className={`${darkMode ? 'bg-bgLightGreen' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                <PhoneIcon className="w-5"/>
                            </div>

                            <div className="text-sm">{formatPhoneNumber(phone) || "- - - - -"}</div>

                            <div className="ml-auto text-sm">Phone</div>
                        </div>

                        {/*Fax*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className={`${darkMode ? 'bg-bgLightGreen' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                <PrinterIcon className="w-5"/>
                            </div>

                            <div className="text-sm">{formatPhoneNumber(fax) || "- - - - -"}</div>

                            <div className="ml-auto text-sm">Fax</div>
                        </div>

                        {/*Address*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className={`${darkMode ? 'bg-bgLightGreen' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                <MapIcon className="w-5"/>
                            </div>

                            <div className="text-sm">{address || "- - - - -"}</div>

                            <div className="ml-auto text-sm">Address</div>
                        </div>

                        {/*Region*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className={`${darkMode ? 'bg-bgLightGreen' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                <GlobeAmericasIcon className="w-5"/>
                            </div>

                            <div className="text-sm">{pricingMapping[region] || "- - - - -"}</div>

                            <div className="ml-auto text-sm">Region</div>
                        </div>

                        {/*Supervisor*/}
                        <div className="w-full flex flex-row items-center mb-auto">

                            <div className={`${darkMode ? 'bg-bgLightGreen' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                <UserIcon className="w-5"/>
                            </div>

                            <div className="text-sm">{supervisorMapping[supervisor] || "- - - - -"}</div>

                            <div className="ml-auto text-sm">Supervisor</div>
                        </div>

                        {/*Sales Code*/}
                        <div className="w-full flex flex-row items-center">

                            <div className={`${darkMode ? 'bg-bgLightGreen' : 'bg-bgColor'} hover:scale-105 w-8 h-8 rounded-full mr-6 flex items-center justify-center align-middle`}>
                                <IdentificationIcon className="w-5"/>
                            </div>

                            <div className="text-sm">{salesCode || "- - - - -"}</div>

                            <div className="ml-auto text-sm">Sales Code</div>
                        </div>

                    </div>

                </div>

            </div>

            {/*Settings*/}
            <div className={`animate-slideInBottom w-2/3 ${darkMode ? 'bg-darkMainColor' : 'bg-white'} relative m-3 p-3 rounded-lg overflow-y-auto transition-all duration-500 ease-in-out`}>

                <div className={`text-xl font-semibold text-center tracking-widest mb-1 border-b-4 border-dotted ${darkMode ? 'border-darkBgColor' : ''}`}>Settings</div>

                {/*Account Info*/}
                <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                    <div className={`text-lg font-bold tracking-widest`}>Account Info</div>
                    <div className={`text-xs ml-3 text-gray-500 mb-3`}>Update your account data below</div>

                    <form onSubmit={onSubmit} method="POST"
                          className={`space-y-2 p-3 mb-2 border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>


                        <div className="w-full flex flex-row">

                            <div className="w-1/2 mr-2.5">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 ">
                                    First Name
                                </label>
                                <div className="mt-2">
                                    <Input
                                        id="fname"
                                        name="fname"
                                        required
                                        autoComplete="fname"
                                        value={fname} onChange={(e) => setFname(e.target.value)}
                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                    />
                                </div>
                            </div>


                            <div className="w-1/2 ml-2.5">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 ">
                                    Last Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="lname"
                                        name="lname"
                                        required
                                        autoComplete="lname"
                                        value={lname} onChange={(e) => setLname(e.target.value)}
                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="w-full flex flex-row">

                            <div className="w-1/2 mr-2.5">
                                <label htmlFor="phone" className="block text-sm font-medium leading-6 ">
                                    Phone Number
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="phone"
                                        name="phone"
                                        required
                                        autoComplete="fname"
                                        value={phone} onChange={(e) => setPhone(e.target.value)}
                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                    />
                                </div>
                            </div>


                            <div className="w-1/2 ml-2.5">
                                <label htmlFor="fax" className="block text-sm font-medium leading-6 ">
                                    Fax Number
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="fax"
                                        name="fax"
                                        autoComplete="fax"
                                        value={fax} onChange={(e) => setFax(e.target.value)}
                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="w-full flex flex-row">

                            <div className="w-3/4 mr-2.5">
                                <label htmlFor="address" className="block text-sm font-medium leading-6 ">
                                    Address
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="address"
                                        name="address"
                                        required
                                        autoComplete="address"
                                        value={address} onChange={(e) => setAddress(e.target.value)}
                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                    />
                                </div>
                            </div>


                            <div className="w-1/4 ml-2.5">
                                <label htmlFor="region" className="block text-sm font-medium leading-6 ">
                                    Sales Industry
                                </label>
                                <div className="mt-2">
                                    <Select
                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                        value={region} onChange={(e) => setRegion(e.target.value)}
                                    >
                                        <option value="AG">Agriculture</option>
                                        <option value="DIST">Distributor</option>
                                        <option value="COM">Commercial</option>
                                    </Select>
                                </div>
                            </div>

                        </div>

                        <div className="w-full flex flex-row">

                            <div className="w-1/2 mr-2.5">
                                <label htmlFor="accountLevel"
                                       className="block text-sm font-medium leading-6 ">
                                    Account Type
                                </label>
                                <div className="mt-2">
                                    <Select
                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                        value={accountLevel} onChange={(e) => setAccountLevel(e.target.value)}
                                    >
                                        <option value="S">Salesperson</option>
                                        <option value="CSR">CSR (Customer Service Rep)</option>
                                        <option value="PFC">PFC Fulfillment</option>
                                        <option value="DIST">Distributor</option>
                                    </Select>
                                </div>
                            </div>

                            <div className="w-1/4 mr-2.5">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 ">
                                    Sales Code
                                </label>
                                <div className="mt-2">
                                    <div
                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                    >
                                        <div>
                                            {salesCode && <p>{salesCode}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-1/4">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 ">
                                    Supervisor
                                </label>
                                <div className="mt-2">
                                    <div
                                        className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                    >
                                        <div>
                                            {supervisor && <p>{supervisor}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 ">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    autoComplete="email"
                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                />
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                // disabled={isRegistering}
                                className="flex w-1/4 ml-auto hover:scale-105 justify-center rounded-md bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Update Account
                            </Button>
                        </div>
                    </form>

                </div>

                {/*General*/}
                <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                    <div className={`text-lg font-bold tracking-widest`}>General Account Information</div>
                    <div className={`text-xs ml-3 text-gray-500 mb-3`}>These are general settings for your account</div>

                    <div className={`space-y-5 p-3 mb-2 border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>

                        {/*DarkMode*/}
                        <div className="w-full flex flex-row items-center">

                            <label htmlFor="email" className="block w-1/4 text-sm font-medium leading-6 ">
                                Dark Mode:
                            </label>

                            {/*ToggleSwitch*/}
                            <div className="w-fit ml-10">
                                <input
                                    type="checkbox"
                                    id="spray"
                                    name="spray"
                                    checked={darkMode}
                                    onChange={toggleDarkMode} // Call the toggle function from context
                                    className="sr-only w-fit"
                                />
                                <label htmlFor="spray" className="cursor-pointer">
                                    <div
                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor drop-shadow shadow-gray-900'}`}>
                                        <div
                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${darkMode ? 'bg-darkMainColor translate-x-full' : 'bg-white'}`}>
                                            {darkMode ? (
                                                <MoonIcon
                                                    className="w-4 h-full flex-none text-gray-300 mr-auto ml-auto mt-auto mb-auto"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <SunIcon
                                                    className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </label>
                            </div>


                        </div>

                        {/*Reset Password*/}
                        <div className="w-full flex flex-row items-center">

                            <label htmlFor="email" className="block w-1/4 text-sm font-medium leading-6 ">
                                Password Reset:
                            </label>

                            {/*Button*/}
                            <div className="w-1/4 ml-10">

                                <Button
                                    onClick={togglePassword}
                                    className="flex w-full hover:scale-105 ml-auto justify-center rounded-md bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Reset Password
                                </Button>

                            </div>


                        </div>

                        {/*Reset Email*/}
                        <div className="w-full flex flex-row items-center">

                            <label htmlFor="email" className="block w-1/4 text-sm font-medium leading-6 ">
                                Email Reset:
                            </label>

                            {/*Button*/}
                            <div className="w-1/4 ml-10">

                                <Button
                                    onClick={toggleEmail}
                                    className="flex ml-auto hover:scale-105 w-full justify-center rounded-md bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Reset Email
                                </Button>

                            </div>


                        </div>

                    </div>


                </div>


                {["D", "A", "CSR"].includes(accountLevel) && (
                    <>

                        {/*CSR*/}
                        <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                            <div className={`text-lg font-bold tracking-widest`}>Customer Service Rep</div>
                            <div className={`text-xs ml-3 text-gray-500 mb-3`}>Update your CSR account below to fit your SalesReps</div>

                            <form method="POST"
                                  className={`space-y-2 p-3 mb-2 border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>


                                <div className="w-full flex flex-row">

                                    <div className="w-full">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 ">
                                            Sales Rep
                                        </label>
                                        <div className="mt-2">


                                            <Select
                                                className={`rounded-lg block w-1/2 p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                onChange={handleSelectChange}
                                                defaultValue=""
                                            >
                                                <option value="" disabled>Select a Sales Rep</option>
                                                {users.map(user => (
                                                    <option key={user.id} value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                            </Select>


                                            {/* Display selected reps */}
                                            <div className="mt-2">
                                                <h3 className="font-medium mb-2 text-sm">Selected Reps:</h3>

                                                <div className="columns-3 gap-3">

                                                    {selectedReps.length === 0 ? (
                                                        // Display custom dialog if sprays array is empty
                                                        <div className="flex w-full h-full justify-center items-center py-10">

                                                            <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                                <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                                    <EyeSlashIcon className={`w-10 stroke-2 mr-3`}/>
                                                                    <p className="text-xl mt-2 line-clamp-1 tracking-widest flex">You Have No Reps</p>
                                                                    <p className="text-md mt-2 line-clamp-1 tracking-widest flex text-gray-500">You may select reps in the profile and settings page</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ) : (
                                                        // Display SprayCard components if sprays array is not empty
                                                        selectedReps.map((rep, index) => {
                                                            return (
                                                                <div key={index}
                                                                     className={`flex hover:scale-105 w-full animate-zoomIn items-center py-1.5 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor shadow'} bg-opacity-50 mb-2 rounded-lg px-2`}>

                                                                    <span
                                                                        className="mr-2 text-gray-500 text-sm">{rep.name}</span>

                                                                    <Button
                                                                        onClick={() => handleRemoveRep(rep.id)}
                                                                        className="text-red-500 hover:text-red-700 ml-auto"
                                                                    >
                                                                        <XMarkIcon
                                                                            className="h-5 text hover:text-red-500 hover:animate-pulse hover:scale-110 m-auto"/>
                                                                    </Button>

                                                                </div>
                                                            )
                                                        })
                                                    )}

                                                    {/*{selectedReps.map((rep, index) => (*/}
                                                    {/*    <div key={index}*/}
                                                    {/*         className={`flex hover:scale-105 w-full animate-zoomIn items-center py-1.5 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor shadow'} bg-opacity-50 mb-2 rounded-lg px-2`}>*/}

                                                    {/*        <span*/}
                                                    {/*            className="mr-2 text-gray-500 text-sm">{rep.name}</span>*/}

                                                    {/*        <Button*/}
                                                    {/*            onClick={() => handleRemoveRep(rep.id)}*/}
                                                    {/*            className="text-red-500 hover:text-red-700 ml-auto"*/}
                                                    {/*        >*/}
                                                    {/*            <XMarkIcon className="h-5 text hover:text-red-500 hover:animate-pulse hover:scale-110 m-auto"/>*/}
                                                    {/*        </Button>*/}

                                                    {/*    </div>*/}
                                                    {/*))}*/}
                                                </div>


                                            </div>


                                        </div>
                                    </div>

                                </div>


                                <div className={`w-full items-center flex justify-end`}>
                                    <Button
                                        onClick={handleUpdateReps}
                                        className="flex w-1/4 ml-auto hover:scale-105 justify-center rounded-md bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Update Reps List
                                    </Button>
                                </div>
                            </form>

                        </div>

                    </>
                )}

                {/*Tech Support*/}
                <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                    <div className={`w-full flex flex-row `}>

                        <div className={`flex flex-row w-3/4 items-center`}>

                            {/*Header*/}
                            <div className={``}>
                                <div className={`flex flex-row items-center`}>
                                    <div className={`text-lg font-bold tracking-widest mr-5`}>Technical Support
                                        [{open ? closedTickets.length : tickets.length}]
                                    </div>
                                    <Button
                                        className="rounded-full hover:bg-green-200 hover:bg-opacity-20 hover:backdrop-blur hover:animate-spin mt-auto mb-auto h-6 w-6 items-center justify-center align-middle"
                                        onClick={onUpdateTickets}
                                    >
                                        <ArrowPathIcon className="h-6 text-borderColor hover:stroke-2 m-auto"/>
                                    </Button>
                                </div>
                                <div className={`text-xs ml-3 text-gray-500 mb-3`}>If you have any issues with the
                                    account or suggestions please submit a ticket!
                                </div>
                            </div>

                            {/*ToggleSwitch*/}
                            <div className="w-fit ml-10">
                                <input
                                    type="checkbox"
                                    id="open"
                                    name="open"
                                    checked={open}
                                    onChange={openVis} // Call the toggle function from context
                                    className="sr-only w-fit"
                                />
                                <label htmlFor="open" className="cursor-pointer">
                                    <div
                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative border ${open ? 'bg-pendingColor bg-opacity-20 border-2 border-pendingColor' : 'bg-orderColor bg-opacity-20 border-2 border-orderColor drop-shadow shadow-gray-900'}`}>
                                        <div
                                            className={`dot absolute left-1 top-0.5 w-6 h-6 rounded-full transition-transform duration-300 ${open ? 'bg-pendingColor bg-opacity-50 translate-x-full' : 'bg-orderColor bg-opacity-50'}`}>
                                            {open ? (
                                                <XCircleIcon
                                                    className="w-5 h-full flex-none mr-auto ml-auto mt-auto mb-auto"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <CheckCircleIcon
                                                    className="w-5 h-full flex-none mr-auto ml-auto mt-auto mb-auto"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </label>
                            </div>

                        </div>

                        <div className={`flex flex-col w-1/4 mr-3.5 items-center`}>
                            <Button
                                onClick={toggleTicket}
                                className="flex w-fit items-center line-clamp-1 ml-auto hover:scale-105 justify-center rounded-md bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                <PlusIcon className={`w-6 mr-3 stroke-2`}/>
                                Add Ticket
                            </Button>
                        </div>

                    </div>


                    {/*Ticket Map*/}
                    <div
                        className={`space-y-5 p-3 mb-2 h-96 overflow-y-auto border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>

                        {open ? (
                            <div>
                                {closedTickets.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex w-full h-full justify-center items-center py-10">

                                        <div
                                            className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                            <div
                                                className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                <p className="text-xl text-black">No Products in this Order</p>
                                                <p className="text-lg text-black mt-2">Add a Product</p>
                                                <p className="text-xs text-black">1] Search Product by name using
                                                    the search bar above.</p>
                                                <p className="text-xs text-black">2] Browse the Digital Catalog by
                                                    clicking on the button above.</p>
                                            </div>
                                        </div>

                                    </div>
                                ) : (
                                    // Display SprayCard components if sprays array is not empty
                                    closedTickets.map((ticket, index) => {
                                        return (
                                            <TicketCard
                                                key={index}
                                                index={index}
                                                ticket={ticket}
                                            />
                                        )
                                    })
                                )}
                            </div>
                        ) : (
                            <div>
                                {tickets.length === 0 ? (
                                    // Display custom dialog if sprays array is empty
                                    <div className="flex w-full h-full justify-center items-center py-10">

                                        <div
                                            className="flex items-center bg-bgColor w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg border drop-shadow">
                                            <div
                                                className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                <p className="text-xl text-black">No Products in this Order</p>
                                                <p className="text-lg text-black mt-2">Add a Product</p>
                                                <p className="text-xs text-black">1] Search Product by name using
                                                    the search bar above.</p>
                                                <p className="text-xs text-black">2] Browse the Digital Catalog by
                                                    clicking on the button above.</p>
                                            </div>
                                        </div>

                                    </div>
                                ) : (
                                    // Display SprayCard components if sprays array is not empty
                                    tickets.map((ticket, index) => {
                                        return (
                                            <TicketCard
                                                key={index}
                                                index={index}
                                                ticket={ticket}
                                            />
                                        )
                                    })
                                )}
                            </div>
                        )}


                    </div>


                </div>

                {["D", "A",].includes(accountLevel) && (
                    <>

                        {/*Banner Settings*/}
                        <div className={`w-full border-b-4 border-dotted p-3 ${darkMode ? 'border-darkBgColor' : ''}`}>
                            <div className={`text-lg font-bold tracking-widest`}>Home Page Banner</div>
                            <div className={`text-xs ml-3 text-gray-500 mb-3`}>Below you can update the banner that appears on the home screen for every user.</div>

                            <form
                                onSubmit={onSubmitBanner}
                                method="POST"
                                className={`space-y-2 p-3 mb-2 border-2 rounded-lg ${darkMode ? 'border-darkBgColor' : ''}`}>


                                {/*Title Area*/}
                                <div className="w-full flex flex-row">

                                    {/*Title*/}
                                    <div className="w-1/2 mr-2.5">
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 ">
                                            Banner Title
                                        </label>
                                        <div className="mt-2">
                                            <Input
                                                id="fname"
                                                name="fname"
                                                required
                                                placeholder="Type Banner Title"
                                                autoComplete="fname"
                                                value={title} onChange={(e) => setTitle(e.target.value)}
                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            />
                                        </div>
                                    </div>

                                </div>


                                {/*TextArea*/}
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 ">
                                        Banner Content
                                    </label>
                                    <div className="mt-2">
                                        <Textarea
                                            id="email"
                                            name="email"
                                            type="email"
                                            required
                                            placeholder="Type Banner Body"
                                            autoComplete="email"
                                            value={body} onChange={(e) => setBody(e.target.value)}
                                            className={`rounded-lg block w-full h-32 max-h-64 p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <Button
                                        type="submit"
                                        // disabled={isRegistering}
                                        className="flex w-1/4 ml-auto hover:scale-105 justify-center rounded-md bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        Update Banner
                                    </Button>
                                </div>
                            </form>

                        </div>

                    </>
                )}

            </div>


            {isPassword && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!isPassword}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        isPassword ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-full max-w-md max-h-full">

                        {/* Modal content */}
                        <div
                            className={`relative animate-zoomIn ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor' : 'bg-white drop-shadow-lg'} rounded-lg`}>

                            {/* Modal header */}
                            <div
                                className={`flex items-center justify-between p-4 md:p-5 border-b ${darkMode ? 'border-borderColor' : 'border'} rounded-t`}>
                                <h3 className="text-lg font-semibold">
                                    Password Reset
                                </h3>
                                <Button
                                    type="button"
                                    className="text-gray-400 hover:animate-pulse bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={togglePassword}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                </Button>
                            </div>

                            {/* Modal body */}
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevent default form submission
                                    // moveToActive(order.id); // Use the captured order number or adjust accordingly
                                }}
                                className="p-4 md:p-5">

                                {/*Customer Code*/}
                                <div className="grid gap-4 mb-4 grid-cols-2">

                                    <div className="col-span-2">
                                        <label htmlFor="name" className="block mb-2 text-sm font-medium">
                                            New Password
                                        </label>
                                        <div className={`flex flex-row items-center`}>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className={`rounded-lg block w-full p-2.5 ${darkMode ? 'dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-primary-600 focus:border-primary-600'}`}
                                                placeholder="Type new password"
                                                // value={orderERP} onChange={(e) => setOrderERP(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                </div>


                                <div className={`flex flex-row items-center`}>

                                    <Button
                                        type="submit"
                                        // onClick={() => moveToActive(order.id)}
                                        className="text-white inline-flex items-center bg-bgLightGreen hover:bg-opacity-50 hover:scale-105 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    >


                                        Update Password

                                    </Button>

                                    <div className={`text-xs ml-auto`}>*New password will be set</div>

                                </div>


                            </form>

                        </div>
                    </div>
                </div>

            )}

            {isEmail && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!isEmail}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        isEmail ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-full max-w-md max-h-full">

                        {/* Modal content */}
                        <div
                            className={`relative animate-zoomIn ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor' : 'bg-white drop-shadow-lg'} rounded-lg`}>

                            {/* Modal header */}
                            <div
                                className={`flex items-center justify-between p-4 md:p-5 border-b ${darkMode ? 'border-borderColor' : 'border'} rounded-t`}>
                                <h3 className="text-lg font-semibold">
                                    Email Reset
                                </h3>
                                <Button
                                    type="button"
                                    className="text-gray-400 hover:animate-pulse bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleEmail}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                </Button>
                            </div>

                            {/* Modal body */}
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevent default form submission
                                    // moveToActive(order.id); // Use the captured order number or adjust accordingly
                                }}
                                className="p-4 md:p-5">

                                {/*Customer Code*/}
                                <div className="grid gap-4 mb-4 grid-cols-2">

                                    <div className="col-span-2">
                                        <label htmlFor="name" className="block mb-2 text-sm font-medium">
                                            New Email
                                        </label>
                                        <div className={`flex flex-row items-center`}>
                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className={`rounded-lg block w-full p-2.5 ${darkMode ? 'dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-primary-600 focus:border-primary-600'}`}
                                                placeholder="Type new email"
                                                // value={orderERP} onChange={(e) => setOrderERP(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                </div>


                                <div className={`flex flex-row items-center`}>

                                    <Button
                                        type="submit"
                                        // onClick={() => moveToActive(order.id)}
                                        className="text-white inline-flex items-center bg-bgLightGreen hover:bg-opacity-50 hover:scale-105 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    >


                                        Update Email

                                    </Button>

                                    <div className={`text-xs ml-auto`}>*New email will be set</div>

                                </div>


                            </form>

                        </div>
                    </div>
                </div>

            )}


            {ticket && (


                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!ticket}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        ticket ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-1/3 max-h-full">

                        {/* Modal content */}
                        <div
                            className={`relative animate-zoomIn ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor text-white' : 'bg-white border drop-shadow-lg text-black'} rounded-lg`}>


                            {/* Modal header */}
                            <div className="flex items-center justify-between p-5 border-b rounded-t dark:border-gray-600">

                                <div className={`w-full flex flex-col`}>
                                    <h3 className="text-lg w-full font-semibold">
                                        Add Ticket
                                    </h3>
                                </div>

                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:animate-pulse hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleTicket}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                </button>
                            </div>


                            {/* Modal body */}
                            <form className="p-5"
                                  onSubmit={onSubmitMain}
                                  method="POST">


                                <div className="grid gap-3 mb-3 grid-cols-2">

                                    {/*Category*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="category"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Category
                                        </label>
                                        <Select
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            value={category}
                                            onChange={(e) => setCategory(e.target.value)}
                                        >
                                            <option value="">Select...</option>
                                            <option value="PERFORMANCE">Performance Issue</option>
                                            <option value="UI_BUG">User Interface Bug</option>
                                            <option value="FEATURE_REQUEST">Feature Request</option>
                                            <option value="NEW_PAGE">New Page Suggestion</option>
                                            <option value="BUG">Other Bug</option>
                                            <option value="OTHER">Other</option>

                                        </Select>
                                    </div>

                                    {/*Page*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="price"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Affected Page
                                        </label>
                                        <Select
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            value={page}
                                            onChange={(e) => setPage(e.target.value)}
                                        >
                                            <option value="">Select...</option>
                                            <option value="Home">Home</option>
                                            <option value="Dashboard">Dashboard</option>
                                            <option value="Catalog">Catalog</option>
                                            <option value="Orders">Current Orders</option>
                                            <option value="Archive">Archived Orders</option>
                                            <option value="Customers">Customer Management</option>
                                            <option value="SalesTeam">Sales Team</option>
                                            <option value="Guides">Help & Resources</option>
                                            <option value="ProgramBuilder">Program Builder</option>
                                            <option value="Messaging">Messaging</option>
                                            <option value="PlantOrders">Plant Orders</option>
                                            <option value="PlantCustomers">Plant Customers</option>
                                            <option value="Profile">Profile / Settings</option>
                                            <option value="SignUp">Sign Up</option>
                                            <option value="Login">Login</option>


                                        </Select>
                                    </div>

                                </div>


                                <div className="grid gap-3 mb-3 grid-cols-3">

                                    {/*Notes*/}
                                    <div className="col-span-2 sm:col-span-3">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Notes
                                        </label>
                                        <Textarea
                                            type="text"
                                            name="name"
                                            id="name"
                                            className={`rounded-lg block h-36 max-h-64 w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type your Notes"
                                            value={notes} onChange={(e) => setNotes(e.target.value)}
                                            required
                                        />
                                    </div>

                                </div>


                                <Button
                                    type="submit"
                                    className="text-white inline-flex items-center bg-bgLightGreen hover:bg-opacity-50 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    <svg
                                        className="me-1 -ms-1 w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    Submit Ticket
                                </Button>


                            </form>


                        </div>
                    </div>
                </div>

            )}
            
            
            
        </div>
    )
};


export default Profile;
