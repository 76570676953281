import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {
    ClipboardDocumentListIcon,
    EnvelopeIcon,
    GlobeAmericasIcon,
    PhoneIcon,
    PrinterIcon
} from "@heroicons/react/24/outline";

const UserCard = ({ user }) => {

    const { darkMode } = useDarkMode();

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const pricingMapping = {
        AG: "Agriculture",
        DIST1: "Distributor NE/INTL",
        DIST2: "Distributor SE/CAN",
        NE: "North East",
        SE: "South East"
    };

    const supervisorMapping = {
        GP: "Grant Platz",
    }

    const accountLevelMapping = {
        D: "Developer",
        A: "Admin",
        CSR: "CSR (Customer Service Rep)",
        PFC: "PFC Fulfillment",
        DIST: "Distributor",
        S: "Sales Rep"
    }

    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };

    return (
        <div className={`w-full flex flex-col mb-3 overflow-x-scroll transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            <div className="relative mx-auto w-full">
                <div className="relative inline-block duration-300 ease-in-out transition-transform transform hover:scale-95 w-full">

                    <div className={`shadow p-4 rounded-lg ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'}`}>

                        {/*Top Section*/}
                        <div className="flex justify-center relative rounded-lg overflow-hidden h-52">

                            {/*Image*/}
                            <div className="transition-transform duration-500 transform ease-in-out hover:scale-110 w-full">
                                <div className={`absolute inset-0 bg-black ${user.profileImageUrl ? '' : 'opacity-50'}`}>


                                    {user.profileImageUrl && (
                                        <img
                                            src={user.profileImageUrl}
                                            alt="Profile"
                                            className="w-full h-full object-cover"
                                        />
                                    )}


                                </div>
                            </div>

                            {/*Image Over Bar*/}
                            <div className="absolute flex justify-center bottom-0 mb-3">
                                <div className={`flex ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white text-black'} hover:scale-105 px-4 py-1 space-x-5 rounded-lg overflow-hidden shadow`}>

                                    <p className="flex flex-row items-center font-medium ">
                                        <ClipboardDocumentListIcon className={`w-5 text-green-600 stroke-2 mr-3`}/>
                                        2
                                    </p>

                                </div>
                            </div>

                            {/*Account Level*/}
                            <span className="absolute top-0 left-0 inline-flex mt-3 ml-3 px-3 py-2 rounded-lg z-10 bg-green-600 text-sm font-medium text-white select-none">
                                {accountLevelMapping[user.accountLevel]  || "--"}
                            </span>

                            {/*Sales Code*/}
                            <span className="absolute top-0 right-0 inline-flex mt-3 mr-3 px-3 py-2 rounded-lg z-10 bg-green-800 text-sm font-medium text-white select-none">
                                {user.salesCode || "--"}
                            </span>

                        </div>

                        {/*Name Section*/}
                        <div className="mt-4">
                            <h2 className="font-medium text-base md:text-lg line-clamp-1">
                                {user.fname || "- - - -"} {user.lname || "- - - -"}
                            </h2>
                            <p className="mt-2 text-sm text-gray-500 line-clamp-1">
                                {user.address || "- - - - - - - - -"}
                            </p>
                        </div>

                        {/*Data Section*/}
                        <div className="grid grid-cols-2 grid-rows-2 gap-4 mt-8">
                            <p className="inline-flex flex-col xl:flex-row xl:items-center ">
                                <PhoneIcon className={`w-5 text-green-600 stroke-2 mr-3`}/>
                                <span className="mt-2 text-sm xl:mt-0">{formatPhoneNumber(user.phone) || "(---) --- ----"}</span>
                            </p>


                            <p className="inline-flex flex-col xl:flex-row xl:items-center ">
                                <EnvelopeIcon className={`w-5 text-green-600 stroke-2 mr-3`}/>
                                <span className="mt-2 text-sm xl:mt-0 line-clamp-1">{user.email || "- - - -"}</span>
                            </p>


                            <p className="inline-flex flex-col xl:flex-row xl:items-center ">
                                <PrinterIcon className={`w-5 text-green-600 stroke-2 mr-3`}/>
                                <span className="mt-2 xl:mt-0">{user.fax || "- - - -"}</span>
                            </p>


                            <p className="inline-flex flex-col xl:flex-row xl:items-center ">
                                <GlobeAmericasIcon className={`w-5 text-green-600 stroke-2 mr-3`}/>
                                <span className="mt-2 xl:mt-0">{pricingMapping[user.region] || "- - - -"}</span>
                            </p>
                        </div>

                        {/*Bottom Section*/}
                        <div className="grid grid-cols-2 mt-8">
                            <div className="flex items-center">
                                <div className="relative">
                                    <div className="rounded-full w-6 h-6 md:w-8 md:h-8 bg-gray-200"></div>
                                    <span className="absolute top-0 right-0 inline-block w-3 h-3 bg-primary-red rounded-full"></span>
                                </div>

                                <p className="ml-2  line-clamp-1">
                                    {supervisorMapping[user.supervisor] || "- - - -"}
                                </p>
                            </div>

                            <div className="flex justify-end">
                                <p className="inline-block font-semibold text-primary whitespace-nowrap leading-tight rounded-xl">
                                    <span className="text-sm uppercase">$ </span>
                                    <span className="text-lg">YTD </span>USD
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UserCard;

