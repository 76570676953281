import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";

import {useAuth} from "../contexts/authContext";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../firebase/firebase";
import course from "../course.jpg";
import fullLogo from "../Plantfood Logo.png"

// import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar/CustomCalender.scss'
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from 'moment'
import {BellAlertIcon, CheckIcon} from "@heroicons/react/24/outline";
import {Button} from "@headlessui/react";
import CustomerCard from "./Customers/CustomerCard";
import {EllipsisVerticalIcon} from "@heroicons/react/16/solid";

const localizer = momentLocalizer(moment)



const Home = () => {

    const { darkMode } = useDarkMode();

    const { currentUser } = useAuth();

    const [fname, setFname] = useState("");

    const [accountLevel, setAccountLevel] = useState("");

    const [profileImage, setProfileImage] = useState('');

    const [notifications, setNotifications] = useState([]);

    const [banner, setBanner] = useState(null);

    const [ordersActive, setOrdersActive] = useState([]);
    const [ordersPending, setOrdersPending] = useState([]);
    const [customersActive, setCustomersActive] = useState([]);
    const [customersPending, setCustomersPending] = useState([]);




    useEffect(() => {
        const fetchBanner = async () => {
            const bannerDoc = doc(db, 'GlobalSettings', 'Banner');
            const docSnap = await getDoc(bannerDoc);

            if (docSnap.exists()) {
                setBanner(docSnap.data());
            } else {
                console.log("No banner found!");
            }
        };

        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(`${userData.fname} ${userData.lname}`);
                        setProfileImage(userData.profileImageUrl);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(`${userData.fname} ${userData.lname}`);
                            setProfileImage(userData.profileImageUrl);
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchOrdersActive = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeOrders_${currentUser.uid}`);
                    let activeData = [];

                    if (storedActiveData) {
                        // If data is available in localStorage
                        activeData = JSON.parse(storedActiveData);
                    } else {
                        console.log("No Data Stored")
                    }

                    // Set pending orders data
                    setOrdersActive(activeData);

                } catch (error) {
                    console.error('Error fetching active orders data:', error);
                }
            }
        };

        const fetchOrdersPending = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`pendingOrders_${currentUser.uid}`);
                    let pendingData = [];

                    if (storedPendingData) {
                        // If data is available in localStorage
                        pendingData = JSON.parse(storedPendingData);
                    } else {
                        console.log("No Data Stored");
                    }

                    // Set pending orders data
                    setOrdersPending(pendingData);

                } catch (error) {
                    console.error('Error fetching pending orders data:', error);
                }
            }
        };

        const fetchCustomersActive = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`activeCustomers_${currentUser.uid}`);
                    let pendingData = [];

                    if (storedPendingData) {
                        // If data is available in localStorage
                        pendingData = JSON.parse(storedPendingData);
                    } else {
                        console.log("No Data Stored");
                    }

                    // Set pending orders data
                    setCustomersActive(pendingData);

                } catch (error) {
                    console.error('Error fetching pending customers data:', error);
                }
            }
        };

        const fetchCustomersPending = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`pendingCustomers_${currentUser.uid}`);
                    let pendingData = [];

                    if (storedPendingData) {
                        // If data is available in localStorage
                        pendingData = JSON.parse(storedPendingData);
                    } else {
                        console.log("No Data Stored");
                    }

                    // Set pending orders data
                    setCustomersPending(pendingData);

                } catch (error) {
                    console.error('Error fetching pending customers data:', error);
                }
            }
        };


        fetchOrdersActive();
        fetchOrdersPending();
        fetchCustomersActive();
        fetchCustomersPending();
        fetchUserName();

        fetchBanner();
    }, []);

    const projects = [
        { name: 'Active Orders', initials: 'AO', href: '#', members: ordersActive.length, label:"Orders", bgColor: 'bg-pink-600' },
        { name: 'Pending Orders', initials: 'PO', href: '#', members: ordersPending.length, label:"Orders", bgColor: 'bg-purple-600' },
        { name: 'Active Customers', initials: 'AC', href: '#', members: customersActive.length, label:"Customers", bgColor: 'bg-yellow-500' },
        { name: 'Pending Customers', initials: 'PC', href: '#', members: customersPending.length, label:"Customers", bgColor: 'bg-green-500' },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'} flex h-screen overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>

            <div className="animate-fadeIn flex flex-col h-full w-full mt-3 mr-3 overflow-y-auto rounded-lg max-h-[calc(100vh-5.5rem)] hide-scrollbar">


                {/*Banner*/}
                <div className={`relative animate-slideInBottom min-h-32 mb-3 border ${darkMode ? 'border-borderColor' : 'drop-shadow'} items-center flex rounded-lg isolate overflow-hidden`}>
                    <img
                        alt=""
                        src={course}
                        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                    />
                    <div className={`flex flex-row w-full`}>
                        <div
                            className="mx-auto w-2/3 ml-6 h-24 overflow-y-auto hide-scrollbar px-6 py-2 bg-gray-400 rounded-md bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-10">
                            <div className="mx-auto lg:mx-0">
                                <h2 className="text-lg font-bold text-green-600 tracking-widest sm:text-7xl">{banner ? banner.title : "No banner available"}</h2>
                                <p className="w-full text-pretty text-xs font-medium text-white">{banner ? banner.body : "No banner available"}</p>
                            </div>
                        </div>


                        <div className="justify-center items-center mx-auto w-1/3 mt-auto mb-auto ml-6 mr-6">

                            <img
                                alt=""
                                src={fullLogo}
                                className="w-full mt-auto mb-auto"
                            />

                        </div>
                    </div>
                </div>

                {/*Data Header*/}
                <div>
                    <ul role="list" className="mb-3 grid grid-cols-1 gap-3 sm:grid-cols-2 sm:gap-3 lg:grid-cols-4 animate-slideInBottom">
                        {projects.map((project) => (
                            <li key={project.name} className="col-span-1 flex rounded-md shadow-sm">
                                <div
                                    className={classNames(
                                        project.bgColor,
                                        'flex w-16 shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white',
                                    )}
                                >
                                    {project.initials}
                                </div>
                                <div className={`flex flex-1 items-center justify-between truncate rounded-r-md ${darkMode ? 'bg-darkMainColor' : 'bg-white border-gray-200 border-b border-r border-t'}`}>
                                    <div className="flex-1 truncate px-4 py-2 text-sm">
                                        <a href={project.href}
                                           className="font-medium hover:text-gray-600">
                                            {project.name}
                                        </a>
                                        <p className="text-gray-500">{project.members} <span>{project.label}</span></p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

                {/*Level 1*/}
                <div className={`w-full min-h-96 flex flex-row mb-3`}>

                    {/*Notifications*/}
                    <div
                        className={`w-1/3 rounded-lg drop-shadow flex flex-col p-3 h-96 ${darkMode ? 'bg-darkMainColor' : 'bg-white'} mr-3`}>

                        {/*Header*/}
                        <div className="w-full flex flex-row items-center mb-2">
                            <div
                                className={`w-fit mr-auto text-center flex items-center flex-row text-lg tracking-widest`}>
                                <BellAlertIcon
                                    className={`h-5 mr-3 text-green-600 border-r-2 border-dotted pr-2 ${darkMode ? 'border-borderColor' : ''}`}/> Notifications
                            </div>
                            <Button
                                className={`w-fit flex items-center ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} hover:opacity-60 hover:scale-105 text-green-600 text-xs p-1 rounded-lg line-clamp-1`}>
                                <CheckIcon className={`h-4 mr-1`}/> Mark all as read</Button>
                        </div>

                        <div
                            className={`w-full h-full overflow-y-auto relative rounded-lg ${darkMode ? 'border-borderColor' : ''} border`}>

                            {notifications.length === 0 ? (
                                // Display custom dialog if sprays array is empty
                                <div className="flex w-full h-full justify-center items-center py-10">

                                    <div
                                        className={`flex items-center ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} w-2/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                        <div
                                            className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col">No
                                            Current Notifications
                                        </div>
                                    </div>

                                </div>
                            ) : (
                                // Display SprayCard components if sprays array is not empty
                                notifications.map((customer, index) => {
                                    return (
                                        <CustomerCard
                                            key={index}
                                            index={index}
                                            customer={customer}
                                        />
                                    )
                                })
                            )}

                        </div>

                    </div>

                    {/* Calender */}
                    <div
                        className={`w-2/3 rounded-lg drop-shadow p-5 h-96 ${darkMode ? 'bg-darkMainColor' : 'bg-white'}`}>

                        <Calendar
                            localizer={localizer}
                            defaultDate={new Date()}
                            defaultView="month"
                            className={`relative h-full text-black rounded-lg p-1.5`}
                        />

                    </div>

                </div>

                {/*Level 1*/}
                <div className={`w-full min-h-96 flex flex-row`}>

                    {/*Profile Info*/}
                    <div
                        className={`w-1/4 rounded-lg drop-shadow flex items-center h-96 ${darkMode ? 'bg-darkMainColor' : 'bg-white'} mr-3`}>

                    </div>

                    {/* Banner */}
                    <div
                        className={`w-1/2 rounded-lg drop-shadow p-3 mr-3 h-96 ${darkMode ? 'bg-darkMainColor' : 'bg-white'}`}>

                    </div>

                    {/* Banner */}
                    <div
                        className={`w-1/4 rounded-lg drop-shadow p-3 h-96 ${darkMode ? 'bg-darkMainColor' : 'bg-white'}`}>

                    </div>

                </div>


            </div>

        </div>
    )
};


export default Home;
