import {Button, Input, Select} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {
    doc,
    setDoc,
    deleteDoc,
    getDoc,
    updateDoc,
    collection,
    query,
    getCountFromServer,
    getDocs
} from "firebase/firestore";
import {XMarkIcon} from "@heroicons/react/16/solid";
import fipsCode from "../../fipCodes.json";
import {
    ArrowDownCircleIcon,
    ArrowPathIcon, ArrowsPointingInIcon, ArrowsPointingOutIcon,
    ArrowTopRightOnSquareIcon,
    ArrowUpCircleIcon,
    ArrowUpTrayIcon,
    CheckCircleIcon,
    ClipboardDocumentListIcon,
    DocumentArrowUpIcon, EyeSlashIcon,
    MagnifyingGlassIcon,
    PlusCircleIcon,
    PlusIcon, SquaresPlusIcon,
    TrashIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {db} from "../../firebase/firebase";
import {useAuth} from "../../contexts/authContext";
import {Link} from "react-router-dom";
import {useDarkMode} from "../../contexts/darkModeContext";
import Pricing from "../Pricing";
import ProductInput from "../OrderForm/ProductInput";
import ContractPrice from "./ContractPrice";

const AddressInput = ({ customer }) => {

    const [isVisible, setIsVisible] = useState(false); // State to track visibility
    const { currentUser } = useAuth();

    const [customerCode, setCustomerCode] = useState("");
    const [type, setType] = useState("");
    const [creditLimit, setCreditLimit] = useState("");
    const [priceCode, setPriceCode] = useState("");

    const [orderLength, setOrderLength] = useState(0);
    const [pendingLength, setPendingLength] = useState(0);
    const [plannedLength, setPlannedLength] = useState(0);

    const { darkMode } = useDarkMode();

    const [isAdding, setIsAdding] = useState(false);

    const [accountLevel, setAccountLevel] = useState("");
    const [disabled, setDisabled] = useState(false);


    const [Addresses, setAddresses] = useState([customer.ShippingAddresses]);


    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const toggleAdding = () => {
        setIsAdding(!isAdding);
    }

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    // // Handle the remove action with animation
    // const handleRemove = () => {
    //     setIsRemoving(true); // Trigger the animation
    //     // setTimeout(() => onRemove(id), 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    // };

    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };


    // Function to move document from "pending" to "active" collection and update status
    const moveToActive = async (customerId) => {
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);

            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the pending collection");
            }

            // Get the customer data and set status to 'active'
            const customerData = customerSnapshot.data();
            const updatedCustomerData = { ...customerData, status: "active" };

            // Reference to the destination document in the "clients" collection
            const activeDocRef = doc(db, `Customers/${currentUser.uid}/clients`, customerId);

            // Copy document to the "clients" collection with updated status
            await setDoc(activeDocRef, updatedCustomerData);

            // Delete document from the "pending" collection
            await deleteDoc(customerDocRef);

            console.log("Customer moved to clients collection and status updated to active.");
            alert("Customer moved to clients collection and status updated to active.");
        } catch (error) {
            console.error("Error moving customer to clients collection:", error);
            alert("Error moving customer to clients collection.");
        }
    };

    const submitUpdate = async (customerId) => {
        try {
            // Reference to the customer document in the "pending" collection
            const customerDocRef = doc(db, `Customers/${currentUser.uid}/pending`, customerId);

            // Get the customer document from the "pending" collection
            const customerSnapshot = await getDoc(customerDocRef);
            if (!customerSnapshot.exists()) {
                throw new Error("Customer not found in the pending collection");
            }

            // Get the customer data and set status to 'active'
            const customerData = customerSnapshot.data();
            const updatedCustomerData = {
                ...customerData,
                customerCode,
                type,
                creditLimit,
                priceType: priceCode,
            };

            // Update the document directly with customerDocRef
            await updateDoc(customerDocRef, updatedCustomerData);

            await moveToActive(customer.id)
            console.log("Customer successfully updated with internal setup.");
            alert("Customer successfully updated with internal setup and moved to Active Status.");
        } catch (error) {
            console.error("Customer update Failed.", error);
            alert("Customer update Failed.");
        }
    };

    const getSubcollectionCount = async (clientId) => {
        try {
            // Reference to the subcollection (e.g., 'planned')
            const pendingCollectionRed = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/pending`);

            // Get the count from the server without fetching documents
            const pendingCountSnapshot = await getCountFromServer(pendingCollectionRed);

            // Get the count from the aggregate query result
            const pendingCount = pendingCountSnapshot.data().count;

            setPendingLength(pendingCount);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }

        try {
            // Reference to the subcollection (e.g., 'pending')
            const activeCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/active`);

            // Get the count from the server without fetching documents
            const activeCountSnapshot = await getCountFromServer(activeCollectionRef);

            // Get the count from the aggregate query result
            const count = activeCountSnapshot.data().count;

            setOrderLength(count);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }

        try {
            // Reference to the subcollection (e.g., 'planned')
            const plannedCollectionRef = collection(db, `Customers/${currentUser.uid}/clients/${clientId}/planned`);

            // Get the count from the server without fetching documents
            const plannedCountSnapshot = await getCountFromServer(plannedCollectionRef);

            // Get the count from the aggregate query result
            const plannedCount = plannedCountSnapshot.data().count;

            setPlannedLength(plannedCount);
        } catch (error) {
            console.error("Error getting subcollection count:", error);
            return 0; // Return 0 in case of an error
        }
    };

    useEffect(() => {
        if (currentUser) {
            // Call the function to get the document count
            getSubcollectionCount(customer.id).then(count => {
                console.log(`Fetched count: ${count}`);
            });
        }
    }, [currentUser]);

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    useEffect(() => {
        setDisabled(accountLevel === "S" || accountLevel === "DIST");
    }, [accountLevel]);


    const [mainExpanded, setMainExpanded] = useState(false);
    const [contactExpanded, setContactExpanded] = useState(false);
    const [financesExpanded, setFinancesExpanded] = useState(false);
    const [shippingExpanded, setShippingExpanded] = useState(false);
    const [deliveryExpanded, setDeliveryExpanded] = useState(false);
    const [pricingExpanded, setPricingExpanded] = useState(false);

    const toggleMain = () => {
        setMainExpanded(!mainExpanded);
    }
    const toggleContacts = () => {
        setContactExpanded(!contactExpanded);
    }
    const toggleFinances = () => {
        setFinancesExpanded(!financesExpanded);
    }
    const toggleShipping = () => {
        setShippingExpanded(!shippingExpanded);
    }
    const toggleDelivery = () => {
        setDeliveryExpanded(!deliveryExpanded);
    }
    const togglePricing = () => {
        setPricingExpanded(!pricingExpanded);
    }

    const collapseAll = () => {
        setMainExpanded(false);
        setContactExpanded(false);
        setFinancesExpanded(false);
        setShippingExpanded(false);
        setDeliveryExpanded(false);
        setPricingExpanded(false);
    }
    const expandAll = () => {
        setMainExpanded(true);
        setContactExpanded(true);
        setFinancesExpanded(true);
        setShippingExpanded(true);
        setDeliveryExpanded(true);
        setPricingExpanded(true);
    }



    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showProducts, setShowProducts] = useState(false); // To control product list visibility

    const [contractPrices, setContractPrices] = useState([]);

    const categoryColors = [
        { category: 'Biostimulants', color: 'bg-bsColor', shortened: 'BS' },
        { category: 'Liquid Fertilizer', color: 'bg-lfColor', shortened: 'LF' },
        { category: 'Dry Fertilizer', color: 'bg-teal-700', shortened: 'DF' },
        { category: 'Secondary Nutrients', color: 'bg-snColor', shortened: 'SN' },
        { category: 'Micronutrients', color: 'bg-mnColor', shortened: 'MN' },
        { category: 'Penetrants & Water Holding Agents', color: 'bg-whColor', shortened: 'PHA' },
        { category: 'A-Plus® Solubilizing Surfactants', color: 'bg-apColor', shortened: 'AP' },
        { category: 'Turfgrass Pigment', color: 'bg-tpColor', shortened: 'TP' },
        { category: 'Water Treatments', color: 'bg-wtColor', shortened: 'WT' },
        { category: 'Soil Amendments', color: 'bg-saColor', shortened: 'SA' },
        { category: 'Fungicides', color: 'bg-fgColor', shortened: 'FG' },
        { category: 'Equipment', color: 'bg-gray-600', shortened: 'EQ' },
    ];

// Example usage
    const getCategoryInfo = (category) => {
        const categoryInfo = categoryColors.find(c => c.category === category);
        return categoryInfo ? { color: categoryInfo.color, shortened: categoryInfo.shortened } : { color: '', shortened: '' };
    };

    // Filter products by search term
    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchTerm(value);

        // Filter products by search term
        if (value) {
            setFilteredProducts(
                products.filter((product) =>
                    product.name.toLowerCase().includes(value.toLowerCase())
                )
            );
            setShowProducts(true); // Show the product list while typing
        } else {
            setShowProducts(false); // Hide the product list if the search bar is empty
        }
    };

    useEffect(() => {
        const fetchProducts = async () => {
            if (currentUser) {
                try {
                    // Fetch the 'productUpdated' field for the current user
                    const userDocRef = doc(db, "Users", currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const { productUpdated } = userData;

                        if (productUpdated) {
                            // If 'productUpdated' is true, fetch products from Firestore
                            console.log("Fetching products from Firestore...");
                            const productsCollection = collection(db, "Products");
                            const snapshot = await getDocs(productsCollection);

                            if (!snapshot.empty) {
                                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                                setProducts(productData);
                                setFilteredProducts(productData);
                                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));

                                // Set 'productUpdated' to false
                                await updateDoc(userDocRef, { productUpdated: false });
                                console.log("Set productUpdated to false after fetching from Firestore.");
                            } else {
                                console.log("No products found in Firestore.");
                            }
                        } else {
                            // If 'productUpdated' is false, load products from localStorage
                            console.log("Loading products from localStorage...");
                            const storedProducts = localStorage.getItem(`products_${currentUser.uid}`);
                            if (storedProducts) {
                                const storedData = JSON.parse(storedProducts);
                                setProducts(storedData);
                                setFilteredProducts(storedData);
                            } else {
                                console.log("No products found in localStorage.");
                            }
                        }
                    } else {
                        console.log("User document does not exist.");
                    }
                } catch (error) {
                    console.error("Error fetching product data:", error);
                }
            }
        };

        fetchProducts();
    }, [currentUser]);

    // Add a product to the productInputs list and reset the search bar
    const addContractPrices = (product) => {
        // if (!selectedProductIds.includes(product.id)) {
        //     setProductInputs((prevInputs) => [...prevInputs, product]);
        //     setSelectedProductIds([...selectedProductIds, product.id]); // Add the product id to the selected list
        // } else {
        //     alert(`Product ${product.name} is already added! You may not add multiple of the same product`); // Optional: alert for user feedback
        // }

        const newKey = contractPrices.length + Math.random();
        // Add the product with the key field
        const productWithKey = { ...product, key: newKey };


        setContractPrices((prevInputs) => [...prevInputs, productWithKey]);
        setSelectedProductIds([...selectedProductIds, product.id]); // Add the product id to the selected list
        setSearchTerm(''); // Clear the search bar
        // setFilteredProducts(productsData.products);
        setShowProducts(false); // Hide the product list
    };

    const handleRemoveContractPrice = (index) => {
        // Get the product ID from the productInputs array
        const removedProductId = contractPrices[index].id;

        // Update the totals and product inputs
        setContractPrices((prevInputs) => prevInputs.filter((_, indexInput) => indexInput !== index));

        // Update the selectedProductIds by removing the removed product's ID
        setSelectedProductIds((prevSelectedIds) =>
            prevSelectedIds.filter(id => id !== removedProductId)
        );

        console.log(products);
    };


    return (
        <div className={`w-full flex flex-col border-b-2 ${darkMode ? 'border-darkBgColor' : ''} p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className={`w-full flex flex-row ${darkMode ? 'hover:bg-white' : 'hover:bg-slate-400'} hover:bg-opacity-5 rounded-lg`}>

                <div className="w-full flex flex-row">

                    {/*Buttons Delete*/}
                    <div className="flex flex-col items-center mr-3">
                        <Button
                            className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            // onClick={handleRemove}
                        >
                            <TrashIcon className="h-5 text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                        </Button>
                    </div>

                    {/*Sales Rep*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-8 p-1 h-8 bg-bgColor rounded-full hover:scale-105 line-clamp-1 ${customer.existing ? `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}` : `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}`}`}>
                            <div className="flex items-center justify-center h-full text-sm line-clamp-1">{customer.salesCode || "--"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{customer.salesRep || "- - - - - - - - - - -"}</div>
                            <div className="text-xs line-clamp-1">{customer.salesEmail || "- - - - - - - - -"}</div>
                        </div>

                    </div>

                    {/*Customer*/}
                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-fit p-1 px-4 h-8 rounded-lg hover:scale-105 line-clamp-1 ${customer.existing ? `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}` : `${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black border'}`}`}>
                            <div className="flex items-center justify-center h-full text-sm line-clamp-1">{customer.customerCode || "- - - - -"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{customer.name || "- - - - - - - - -"}</div>
                            <div className={`text-xs line-clamp-1 ${customer.existing ? 'hidden' : ''}`}>{customer.contactPosition || "- - - - -"} | {formatPhoneNumber(customer.contactPhone) || "(---) --- - ----"}</div>
                        </div>


                    </div>

                    {/*Status*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">

                        {/*Status*/}
                        <div className="mr-auto w-1/2 justify-center items-center flex">
                            {customer.status === "active" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-orderColor' : 'bg-emerald-200/60 text-emerald-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <CheckCircleIcon className="w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">
                                            {customer.existing ? 'Existing' : 'Active'}
                                        </div>
                                    </div>

                                </div>
                            )}

                            {customer.status === "pending" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-pendingColor' : 'bg-pendingColor text-yellow-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <ArrowPathIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Pending
                                        </div>
                                    </div>

                                </div>
                            )}
                        </div>


                        {/*Button*/}
                        <div className="ml-auto w-1/2 justify-center items-center flex">
                            {customer.status === "active" && (
                                <div>

                                    <div className={`w-28 p-1 h-8 rounded-full flex flex-row hover:scale-105 ${darkMode ? '' : ''} ${orderLength ? `${darkMode ? 'bg-blue-200 text-blue-700' : 'bg-sky-100 text-blue-700'}`: `${darkMode ? 'bg-red-200 text-red-500' : 'bg-red-100 text-red-500'}`}`}>
                                        <ClipboardDocumentListIcon className="w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center font-semibold h-full text-xs mr-3 ml-auto">
                                            {orderLength}
                                        </div>
                                    </div>

                                </div>
                            )}

                            {customer.status === "pending" && (
                                <Button className="w-fit px-3 py-1.5 hover:scale-105 bg-bgLightGreen line-clamp-1 rounded-full text-white text-xs hover:bg-opacity-80 flex flex-row items-center justify-center"
                                    onClick={toggleAdding}
                                    disabled={disabled}
                                >
                                    <DocumentArrowUpIcon className="w-4 mt-auto hover:rotate-12 mb-auto mr-2 line-clamp-1"/>Update Status</Button>

                            )}
                        </div>

                    </div>

                    {/*YTD*/}
                    <div className="w-1/4 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                        <div className={`w-1/2 p-1 h-8 ${darkMode ? 'text-white bg-darkBgColor' : 'text-black bg-bgColor'} rounded-full`}>
                            <div className="flex items-center justify-center h-full text-sm px-4">$ USD</div>
                        </div>

                    </div>


                    {/* Buttons DropDown */}
                    <div className={`flex flex-col items-center ${customer.existing ? 'mr-8' : ''}`}>
                        <Button
                            className={`rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle 
                            ${customer.existing ? 'hidden' : ''}`}
                            onClick={toggleVisibility}
                        >
                            <ArrowTopRightOnSquareIcon
                                className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"
                            />
                        </Button>
                    </div>

                </div>

            </div>

            {/* Conditionally render content based on isVisible */}
            {isVisible && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!isVisible}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        isVisible ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-full flex items-center justify-center">

                        {/* Modal content */}
                        <div className={`animate-zoomIn w-5/6 h-[40rem] relative ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor text-white' : 'bg-white drop-shadow-lg text-black'} rounded-lg`}>

                            {/* Modal header */}
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-xs line-clamp-1 tracking-widest">
                                    <span className={`font-bold tracking-widest text-lg mr-6`}>{customer.name || "- - - -"}'s</span> Account Information
                                </h3>

                                <Button
                                    className={`ms-auto flex text-sm mr-6 hover:text-opacity-50 tracking-widest py-1 text-gray-500 rounded-full`}
                                    onClick={expandAll}
                                >
                                    <ArrowsPointingOutIcon className={`w-6`}/>
                                </Button>

                                <Button
                                    className={` flex text-sm mr-6 hover:text-opacity-50 tracking-widest py-1 text-gray-500 rounded-full`}
                                    onClick={collapseAll}
                                >
                                    <ArrowsPointingInIcon className={`w-6`}/>
                                </Button>

                                <button
                                    type="button"
                                    className="text-gray-500 bg-transparent hover:text-opacity-50 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                                    onClick={toggleVisibility}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>

                            {/* Modal body */}
                            <div className={`w-full flex flex-row p-3 h-full relative`}>

                                {/*Account Info Short*/}
                                <div className={`relative w-1/4 h-[33.6rem] mr-3 ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-neutral-50 drop-shadow'} border-2 rounded-lg`}>

                                    <div className={`top-1.5 right-1.5 absolute text-center text-xs tracking-widest line-clamp-1 bg-green-200/30 border-green-600 border font-bold rounded-full px-2`}>{customer.priceType}</div>


                                    {/*Name and Code*/}
                                    <div className={`border-b-2 p-3 border-dashed ${darkMode ? 'border-neutral-700' : ''}`}>
                                        <div className={`text-center mt-3 px-4 py-1.5 rounded-lg mr-auto ml-auto text-sm ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} w-fit`}>{customer.customerCode}</div>
                                        <div className={`text-center tracking-widest mt-3 line-clamp-1`}>{customer.name}</div>
                                    </div>

                                    <div className={`flex h-[25.5rem] overflow-y-scroll flex-col`}>
                                        <div className={`p-3 flex flex-row items-center mb-6`}>
                                            <div className={`px-4 py-1.5 rounded-lg w-3/4 font-bold text-sm tracking-widest`}>Active Orders</div>
                                            <div className={`px-4 py-0.5 text-center rounded-lg w-1/4 bg-orderColor bg-opacity-20 border-2 border-orderColor`}>{orderLength}</div>
                                        </div>

                                        <div className={`p-3 flex flex-row items-center mb-6`}>
                                            <div className={`px-4 py-1.5 rounded-lg w-3/4 font-bold text-sm tracking-widest`}>Pending Orders</div>
                                            <div className={`px-4 py-0.5 text-center rounded-lg w-1/4 bg-pendingColor bg-opacity-20 border-2 border-pendingColor`}>{pendingLength}</div>
                                        </div>

                                        <div className={`p-3 flex flex-row items-center`}>
                                            <div className={`px-4 py-1.5 rounded-lg w-3/4 font-bold text-sm tracking-widest`}>Planned Orders</div>
                                            <div className={`px-4 py-0.5 text-center rounded-lg w-1/4 bg-plannedColor bg-opacity-20 border-2 border-plannedColor`}>{plannedLength}</div>
                                        </div>
                                    </div>

                                    <div className={`text-center text-xxs tracking-widest line-clamp-1 bg-green-200/30 border-green-600 border font-bold rounded-full mx-6`}>{customer.id}</div>


                                </div>

                                {/*Account Info Long*/}
                                <div className={`w-3/4 h-[33.6rem] ${darkMode ? 'bg-darkMainColor border-neutral-700' : 'bg-neutral-50 drop-shadow'} border-2 space-y-12 rounded-lg p-4 hide-scrollbar flex flex-col overflow-y-scroll transition-all duration-500 ease-in-out`}>

                                    {/*Main Done*/}
                                    <div className="w-full h-fit">

                                        <div className="relative mb-3">
                                          <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                            <div className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`} />
                                          </div>
                                          <div className="relative flex items-center justify-between">
                                            <span className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Main</span>
                                            <Button
                                              type="button"
                                              onClick={toggleMain}
                                              className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                            >
                                                {mainExpanded ? (
                                                    <>
                                                        <XMarkIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                                        <span>Collapse</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <PlusIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                                        <span>Expand</span>
                                                    </>
                                                )}
                                            </Button>
                                          </div>
                                        </div>

                                        {/*Content*/}
                                        {mainExpanded && (
                                            <div className={`w-full animate-slideInBottom flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                                <div className={`w-full flex flex-row`}>

                                                    {/*name*/}
                                                    <div className="flex flex-col w-full mr-3">
                                                        <div className="text-xs mb-0.5 font-semibold">Customer Name
                                                        </div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.name}
                                                                placeholder={"Customer Name"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/*managment name*/}
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Management Name
                                                        </div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.managementName}
                                                                placeholder={"Management Company Name"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="flex flex-col w-full">
                                                    <div className="text-xs mb-0.5 font-semibold">Street Address [1]</div>
                                                    <div className="mt-2">
                                                        <Input
                                                            value={customer.address1}
                                                            placeholder={"Street Address 1"}
                                                            // onChange={(e) => setFname(e.target.value)}
                                                            className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex flex-col w-full">
                                                    <div className="text-xs mb-0.5 font-semibold">Street Address [2]</div>
                                                    <div className="mt-2">
                                                        <Input
                                                            value={customer.address2}
                                                            placeholder={"Street Address [2]"}
                                                            // onChange={(e) => setFname(e.target.value)}
                                                            className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                        />
                                                    </div>
                                                </div>

                                                {/*City Row*/}
                                                <div className={`flex flex-row w-full space-x-3`}>
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">City</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.city}
                                                                placeholder={"City"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">State</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.state}
                                                                placeholder={"State"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Zip</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.zip}
                                                                placeholder={"Zip Code"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*County Row*/}
                                                <div className={`flex flex-row w-full space-x-3`}>
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">County</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.county}
                                                                placeholder={"County"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Fips Code</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.fips ? formatFipsCode(customer.fips) : ""}
                                                                placeholder={customer.fips ? "" : "No Fips Code"} // Display placeholder if no fips
                                                                disabled={true}
                                                                className={`rounded-lg block w-full p-2 ${
                                                                    darkMode
                                                                        ? "bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                                                        : "bg-gray-50 border border-gray-300 text-gray-900 text-sm"
                                                                }`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*Country*/}
                                                <div className="flex flex-col w-full">
                                                    <div className="text-xs mb-0.5 font-semibold">Country</div>
                                                    <div className="mt-2">
                                                        <Input
                                                            value={customer.country}
                                                            placeholder={"Country"}
                                                            // onChange={(e) => setFname(e.target.value)}
                                                            className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        )}


                                    </div>

                                    {/*Contacts Done*/}
                                    <div className="w-full h-fit">

                                        <div className="relative mb-3">
                                            <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                            <div className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`} />
                                            </div>
                                            <div className="relative flex items-center justify-between">
                                                <span
                                                    className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Contacts</span>
                                                <Button
                                                    type="button"
                                                    onClick={toggleContacts}
                                                    className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                                >
                                                    {contactExpanded ? (
                                                        <>
                                                        <XMarkIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                                        <span>Collapse</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <PlusIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                                        <span>Expand</span>
                                                    </>
                                                )}
                                            </Button>
                                          </div>
                                        </div>


                                        {contactExpanded && (
                                            <div className={`w-full animate-slideInLeft flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                                <div className={`flex flex-row space-x-3`}>
                                                    {/*Contact*/}
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Contact Name</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.contact}
                                                                placeholder={"Contact Name"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/*Position*/}
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Contact Position</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.contactPosition}
                                                                placeholder={"Contact Position"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`flex flex-row space-x-3`}>
                                                    {/*Phone*/}
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Contact Phone</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={formatPhoneNumber(customer.contactPhone)}
                                                                placeholder={"Contact Phone"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/*Email*/}
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Contact Email</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.contactEmail}
                                                                placeholder={"Contact Email"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>                                                </div>
                                                </div>

                                                {/*Website*/}
                                                <div className="flex flex-col w-full">
                                                    <div className="text-xs mb-0.5 font-semibold">Website</div>
                                                    <div className="mt-2">
                                                        <Input
                                                            value={customer.website}
                                                            placeholder={"Website"}
                                                            // onChange={(e) => setFname(e.target.value)}
                                                            className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={`flex flex-row space-x-3`}>
                                                    {/*Billing Contact*/}
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Billing Contact</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.billingContact}
                                                                placeholder={"Billing Contact"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/*Billing Phone*/}
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Billing Phone</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={formatPhoneNumber(customer.billingPhone)}
                                                                placeholder={"Billing Phone"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`flex flex-row space-x-3`}>
                                                    {/*Invoice Emails*/}
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Invoice Email</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.invoiceEmails}
                                                                placeholder={"Invoice Email"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/*Statement Email*/}
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Statement Email</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.statementEmails}
                                                                placeholder={"Statement Email"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )}


                                    </div>

                                    {/*Shipping*/}
                                    <div className="w-full h-fit">

                                        <div className="relative mb-3">
                                          <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                              <div className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`}/>
                                          </div>
                                            <div className="relative flex items-center justify-between">
                                            <span className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Shipping</span>
                                            <Button
                                              type="button"
                                              onClick={toggleShipping}
                                              className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                            >
                                                {shippingExpanded ? (
                                                    <>
                                                        <XMarkIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                                        <span>Collapse</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <PlusIcon aria-hidden="true" className="-ml-1 -mr-0.5 size-5" />
                                                        <span>Expand</span>
                                                    </>
                                                )}
                                            </Button>
                                          </div>
                                        </div>


                                        {shippingExpanded && (
                                            <div className={`w-full animate-slideInRight flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                            <div className="text-xs mb-0.5 font-semibold">Shipping Locations [{Addresses.length}]</div>

                                                {Addresses.length === 0 ? (
                                                    // Display custom dialog if sprays array is empty
                                                    <div className="flex w-full h-full justify-center items-center py-10">

                                                        <div
                                                            className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                            <div
                                                                className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                                <p className="text-xl">No Products in this Order</p>
                                                            </div>
                                                        </div>

                                                    </div>

                                                ) : (
                                                    // Display SprayCard components if sprays array is not empty
                                                    customer.ShippingAddresses.map((address, index) => {
                                                        return (
                                                            <div key={index} className={`mb-1 w-full rounded-lg animate-zoomIn`}>
                                                                <div
                                                                    className={`flex flex-col w-full items-center p-3 border-2 rounded-lg space-y-2 ${darkMode ? 'border-neutral-700' : ''}`}>


                                                                    {/*Main*/}
                                                                    <div className={`flex flex-row w-full space-x-3`}>
                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">Shipping
                                                                                Location Nickname
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.nickName}
                                                                                    placeholder={"Nickname"}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">Contact
                                                                                Name
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.contactName}
                                                                                    placeholder={"Name"}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">Contact
                                                                                Phone
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.contactPhone}
                                                                                    placeholder={"Phone Number"}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/*Shipping Info*/}
                                                                    <div className={`flex flex-row w-full space-x-3`}>

                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">
                                                                                Ship To Email
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.shipEmail}
                                                                                    placeholder={"Optional"}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">
                                                                                Ship to Notes
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.shipNotes}
                                                                                    placeholder={"Optional"}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">
                                                                                Country
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.contactPhone}
                                                                                    placeholder={"Country"}
                                                                                    disabled={true}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/*Address*/}
                                                                    <div className={`flex flex-row w-full space-x-3`}>
                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">
                                                                                Street Address [1]
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.address1}
                                                                                    placeholder={"123 Main Street"}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">
                                                                                Street Address [2]
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.address2}
                                                                                    placeholder={"Optional"}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    {/*City Row*/}
                                                                    <div className={`flex flex-row w-full space-x-3`}>
                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">
                                                                                City
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.city}
                                                                                    placeholder={"City"}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">
                                                                                State
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.selectedState}
                                                                                    placeholder={"State"}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex flex-col w-full">
                                                                            <div
                                                                                className="text-xs mb-0.5 font-semibold">
                                                                                Zip Code
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.zip}
                                                                                    placeholder={"00000"}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/*County*/}
                                                                    <div className={`flex flex-row w-full space-x-3`}>
                                                                        <div className="flex flex-col w-full">
                                                                            <div className="text-xs mb-0.5 font-semibold">
                                                                                County
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.selectedCounty}
                                                                                    placeholder={"County"}
                                                                                    disabled={true}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex flex-col w-full">
                                                                            <div className="text-xs mb-0.5 font-semibold">
                                                                                Fips Code
                                                                            </div>
                                                                            <div className="mt-2">
                                                                                <Input
                                                                                    value={address.fips}
                                                                                    placeholder={"00-000"}
                                                                                    disabled={true}
                                                                                    // onChange={(e) => setFname(e.target.value)}
                                                                                    className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                                                />
                                                                            </div>
                                                                        </div>


                                                                    </div>


                                                                    {/*<Button*/}
                                                                    {/*    onClick={() => handleRemovePrice(index)}*/}
                                                                    {/*    className="ml-2 stroke-2 text-red-500 hover:text-red-700 hover:animate-pulse"*/}
                                                                    {/*>*/}
                                                                    {/*    <XCircleIcon className="w-6 h-6"/>*/}
                                                                    {/*</Button>*/}
                                                                </div>
                                                            </div>
                                                        )
                                                    })

                                                )}


                                            </div>
                                        )}


                                    </div>

                                    {/*Finances Done*/}
                                    <div className="w-full h-fit">


                                        <div className="relative mb-3">
                                            <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                            <div className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`} />
                                            </div>
                                            <div className="relative flex items-center justify-between">
                                                <span
                                                    className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Finances</span>
                                                <Button
                                                    type="button"
                                                    onClick={toggleFinances}
                                                    className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                                >
                                                    {financesExpanded ? (
                                                        <>
                                                            <XMarkIcon aria-hidden="true"
                                                                       className="-ml-1 -mr-0.5 size-5"/>
                                                            <span>Collapse</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <PlusIcon aria-hidden="true"
                                                                      className="-ml-1 -mr-0.5 size-5"/>
                                                            <span>Expand</span>
                                                        </>
                                                    )}
                                                </Button>
                                            </div>
                                        </div>


                                        {financesExpanded && (
                                            <div className={`w-full animate-slideInLeft flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                                <div className={`flex flex-row space-x-3`}>
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Tax ID</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.taxID}
                                                                placeholder={"TaxID"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Sales Tax Status</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.salesTaxStatus}
                                                                placeholder={"Sales Tax Status"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={`flex flex-row space-x-3`}>
                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Tax Exempt Code
                                                        </div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.taxExempCode}
                                                                placeholder={"Tax Exempt Code"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col w-full">
                                                        <div className="text-xs mb-0.5 font-semibold">Payment Type</div>
                                                        <div className="mt-2">
                                                            <Input
                                                                value={customer.paymentType}
                                                                placeholder={"Payment Type"}
                                                                // onChange={(e) => setFname(e.target.value)}
                                                                className={`rounded-lg block w-full p-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )}


                                    </div>

                                    {/*Delivery Done*/}
                                    <div className="w-full h-fit">


                                        <div className="relative mb-3">
                                            <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                            <div className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`} />
                                            </div>
                                            <div className="relative flex items-center justify-between">
                                                <span
                                                    className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Delivery</span>
                                                <Button
                                                    type="button"
                                                    onClick={toggleDelivery}
                                                    className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                                >
                                                    {deliveryExpanded ? (
                                                        <>
                                                            <XMarkIcon aria-hidden="true"
                                                                       className="-ml-1 -mr-0.5 size-5"/>
                                                            <span>Collapse</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <PlusIcon aria-hidden="true"
                                                                      className="-ml-1 -mr-0.5 size-5"/>
                                                            <span>Expand</span>
                                                        </>
                                                    )}
                                            </Button>
                                          </div>
                                        </div>


                                        {deliveryExpanded && (
                                            <div className={`w-full animate-slideInRight flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                                <div className={`flex flex-col w-full rounded-lg`}>

                                                    <div className={`flex flex-row w-full ${darkMode ? 'border-neutral-700' : ''} border-b-2`}>
                                                        <div className="w-3/4 p-3 ">Delivery Questions?</div>
                                                        <div className="w-1/4 text-center p-3">[Yes] [No]</div>
                                                    </div>

                                                    <div className="flex w-full flex-row">
                                                        <div className="space-y-7 w-3/4 p-3">
                                                            <div className="p-1 border-b">Tractor Trailer Accessible?</div>
                                                            <div className="p-1 border-b">Limited Access Site?</div>
                                                            <div className="p-1 border-b">Loading Dock Available?</div>
                                                            <div className="p-1 border-b">Lift Gate & Pallet Jack Required?</div>
                                                            <div className="p-1 border-b">Delivery Appointment Required?</div>
                                                            <div className="p-1 border-b">Heated Storage Available?</div>
                                                            <div className="p-1 border-b">Forklift Available w/ 3,000 lbs Capacity?</div>
                                                        </div>

                                                        <div className={`ml-5 w-1/4 space-y-5 drop-shadow p-3 rounded-lg`}>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="tta"
                                                                    name="tta"
                                                                    checked={customer.TTA}
                                                                    // onChange={toggleTTA} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="tta" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${customer.TTA ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {customer.TTA ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${customer.TTA ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {customer.TTA ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="las"
                                                                    name="las"
                                                                    checked={customer.LAS}
                                                                    // onChange={toggleLAS} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="las" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${customer.LAS ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {customer.LAS ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${customer.LAS ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {customer.LAS ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="lda"
                                                                    name="lda"
                                                                    checked={customer.LDA}
                                                                    // onChange={toggleLDA} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="lda" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${customer.LDA ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {customer.LDA ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${customer.LDA ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {customer.LDA ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="pjr"
                                                                    name="pjr"
                                                                    checked={customer.PJR}
                                                                    // onChange={togglePJR} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="pjr" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${customer.PJR ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {customer.PJR ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${customer.PJR ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {customer.PJR ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="dar"
                                                                    name="dar"
                                                                    checked={customer.DAR}
                                                                    // onChange={toggleDAR} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="dar" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${customer.DAR ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {customer.DAR ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${customer.DAR ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {customer.DAR ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="hsa"
                                                                    name="hsa"
                                                                    checked={customer.HSA}
                                                                    // onChange={toggleHSA} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="hsa" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${customer.HSA ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {customer.HSA ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${customer.HSA ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {customer.HSA ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="flc"
                                                                    name="flc"
                                                                    checked={customer.FLC}
                                                                    // onChange={toggleFLC} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="flc" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${customer.FLC ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {customer.FLC ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${customer.FLC ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {customer.FLC ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        )}


                                    </div>

                                    {/*Contract Pricing*/}
                                    <div className="w-full h-fit">


                                        {/*Contract Pricing Header*/}
                                        <div className="relative mb-3">
                                            <div aria-hidden="true" className="absolute inset-0 flex items-center">
                                            <div className={`w-full border-t-2 ${darkMode ? 'border-gray-500' : ''}`} />
                                            </div>
                                            <div className="relative flex items-center justify-between">
                                                <span
                                                    className={`${darkMode ? 'bg-darkMainColor' : 'bg-neutral-50'} pr-3 text-base font-semibold tracking-widest`}>Contract Pricing</span>
                                                <Button
                                                    type="button"
                                                    onClick={togglePricing}
                                                    className={`inline-flex items-center gap-x-1.5 rounded-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} px-3 py-0.5 text-xs font-semibold shadow-sm hover:scale-105`}
                                                >
                                                    {pricingExpanded ? (
                                                        <>
                                                            <XMarkIcon aria-hidden="true"
                                                                       className="-ml-1 -mr-0.5 size-5"/>
                                                            <span>Collapse</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <PlusIcon aria-hidden="true"
                                                                      className="-ml-1 -mr-0.5 size-5"/>
                                                            <span>Expand</span>
                                                        </>
                                                    )}
                                                </Button>
                                            </div>
                                        </div>


                                        {pricingExpanded && (
                                            <div className={`w-full animate-slideInLeft space-y-2`}>

                                                <div className={`w-full h-96 flex flex-col border-2 ${darkMode ? 'border-neutral-700' : ''} rounded-lg p-3 space-y-2`}>

                                                    {/*PRODUCT SEARCH BAR*/}
                                                    <div className="w-full flex flex-row relative">

                                                        {/*Search Bar*/}
                                                        <div className={`${darkMode ? 'bg-darkMainColor border-2 border-neutral-700' : 'bg-white border-2'} w-full rounded-md flex items-center justify-center hover:scale-y-110 transition-all duration-500 ease-in-out`}>
                                                            <MagnifyingGlassIcon className="text-gray-500 stroke-2 h-5 mr-6 ml-6"/>
                                                            <Input
                                                                className={`${darkMode ? 'bg-darkMainColor text-white' : 'bg-white'} text-sm transition-all duration-500 ease-in-out rounded-md p-1 w-full focus:outline-none`}
                                                                placeholder={`Search to add Contract Pricing from ${products.length} products`}
                                                                value={searchTerm}
                                                                onChange={handleSearch}
                                                                onFocus={() => setShowProducts(true)}
                                                                onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                                            />
                                                        </div>

                                                        {showProducts && (
                                                            <ul className={`absolute animate-fadeIn ${darkMode ? 'bg-neutral-800 text-white border border-gray-900' : 'bg-white border'} border rounded-md p-2 w-1/2 max-h-[28rem] overflow-y-auto mt-12 z-10 shadow-lg`}>

                                                                {filteredProducts.length > 0 ? (
                                                                    filteredProducts.map((product, index) => {
                                                                        const {
                                                                            color,
                                                                            shortened
                                                                        } = getCategoryInfo(product.category); // Get category color and shortened text

                                                                        return (
                                                                            <li
                                                                                key={product.id}
                                                                                className={`cursor-pointer tracking-widest p-2 rounded-lg flex items-center ${
                                                                                    darkMode ? 'hover:bg-darkMainColor' : 'hover:bg-gray-200'
                                                                                }`}
                                                                                onMouseDown={() => addContractPrices(product)} // Use onMouseDown to select item before blur
                                                                            >
                                                                                <div className={`flex flex-row items-center w-full`}>
                                                                                    <div className={`text-gray-500 text-xxs mr-3 w-6`}>[{index + 1}]</div>

                                                                                    <div className={`mr-3`}>
                                                                                        <div className={`flex-none w-12 rounded-full p-1 ${color} bg-opacity-40`}> {/* Apply background color */}
                                                                                            <div className={`text-xxs rounded-full ${color} bg-opacity-80 font-bold px-1 text-center text-white tracking-normal`}>{shortened}</div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className={`line-clamp-1`}>{product.name}</div>
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <div
                                                                        className={`flex items-center justify-center flex-col p-3`}>
                                                                        <EyeSlashIcon
                                                                            className={`w-6 stroke-2 text-gray-500`}/>
                                                                        <div
                                                                            className="px-4 py-2 text-sm text-gray-500">No
                                                                            product found for{' '}<span
                                                                                className={`font-bold ${darkMode ? 'text-white' : 'text-black'}`}>'{searchTerm}'</span>.
                                                                            Please try again.
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </ul>
                                                        )}


                                                    </div>

                                                    {/*Pricing Body*/}
                                                    <div className={`w-full h-full overflow-y-scroll`}>

                                                        {contractPrices.length === 0 ? (
                                                            // Display custom dialog if sprays array is empty
                                                            <div className="flex w-full h-full justify-center items-center">

                                                                <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-24 py-2 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                                    <div className="p-1 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                                        <SquaresPlusIcon className={`w-8 stroke-1 mr-3`}/>
                                                                        <p className="text-lg mt-2 line-clamp-1 tracking-widest flex">No Contract Pricing</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ) : (
                                                            // Display SprayCard components if sprays array is not empty
                                                            contractPrices.map((product, index) => (
                                                                <ContractPrice
                                                                    key={product.id}
                                                                    index={index}
                                                                    onRemove={handleRemoveContractPrice}
                                                                    // onChange={handleTotalChange}
                                                                    product={product}
                                                                    priceType={customer.priceType}
                                                                    // discount={customerType}
                                                                />
                                                            ))
                                                        )}

                                                    </div>

                                                </div>


                                            </div>
                                        )}


                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            )}


            {isAdding && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!isAdding}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        isAdding ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-full max-w-md max-h-full">

                        {/* Modal content */}
                        <div
                            className={`animate-zoomIn relative ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor text-white' : 'bg-white drop-shadow-lg text-black'} rounded-lg`}>
                            {/* Modal header */}
                            <div
                                className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                <h3 className="text-lg font-semibold line-clamp-1">
                                    [{customer.name || "- - - -"}] Internal Setup
                                </h3>
                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleAdding}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            {/* Modal body */}
                            <form className="p-4 md:p-5"
                                  onSubmit={(e) => {
                                      e.preventDefault();
                                      submitUpdate(customer.id); // Call submitUpdate with the correct customerId
                                  }}
                                  method="POST">
                                <div className="grid gap-4 mb-4 grid-cols-2">

                                    {/*Customer Code*/}
                                    <div className="col-span-2">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Customer Code
                                        </label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type Customer Code [5 Characters]"
                                            value={customerCode} onChange={(e) => setCustomerCode(e.target.value)}
                                            required
                                        />
                                    </div>

                                    {/*Type*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="price"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Type
                                        </label>
                                        <Input
                                            type="text"
                                            name="price"
                                            id="price"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type Customer Type"
                                            value={type} onChange={(e) => setType(e.target.value)}
                                            required
                                        />
                                    </div>

                                    {/*Price Type*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="category"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Price Type
                                        </label>
                                        <Select
                                            id="category"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            onChange={(event) => {
                                                const selectedValue = event.target.value;
                                                setPriceCode(selectedValue); // Update priceCode state
                                                console.log("Selected Price Code:", selectedValue); // Debugging to verify if it's working
                                            }}
                                        >
                                            <option value="AG">Agriculture</option>
                                            <option value="DIST1">Distributor NE/INTL</option>
                                            <option value="DIST2">Distributor SE/CAN</option>
                                            <option value="NE">Northeast</option>
                                            <option value="SE">Southeast</option>
                                        </Select>
                                    </div>

                                    {/*Credit Limit*/}
                                    <div className="col-span-2">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Credit Limit
                                        </label>
                                        <Input
                                            type="number"
                                            name="name"
                                            id="name"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type Credit Limit"
                                            value={creditLimit} onChange={(e) => setCreditLimit(e.target.value)}
                                            required
                                        />
                                    </div>


                                </div>
                                <Button
                                    type="submit"
                                    className="text-white inline-flex items-center bg-bgLightGreen hover:bg-opacity-50 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    <svg
                                        className="me-1 -ms-1 w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    Add Internal Setup
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>


            )}


        </div>
    )
}

export default AddressInput;
