import React, { useState } from 'react';
import {useDarkMode} from "../../contexts/darkModeContext";
import {Button} from "@headlessui/react";
import {XCircleIcon} from "@heroicons/react/24/outline";

const PDFModal = ({ pdfFile, onClose }) => {

    const { darkMode } = useDarkMode();

    return (
        <div className="absolute w-full h-full z-20 inset-0 flex items-center justify-center bg-black bg-opacity-50">

            <div className={`animate-zoomIn ${darkMode ? 'bg-darkMainColor border-gray-800 border-2 text-white' : 'bg-white text-black'} p-4 rounded-lg max-w-3xl w-full h-3/4`}>

                <div className={`flex flex-row`}>
                    <Button  onClick={onClose} className={`absolute ${darkMode ? 'text-white' : 'text-black'} hover:text-red-500 rounded`}>
                        <XCircleIcon className="w-8"></XCircleIcon>
                    </Button>

                    <div className={`w-full text-center text-xl tracking-widest mb-2`}>PDF Viewer</div>
                </div>

                <iframe
                    src={pdfFile}
                    className="w-full h-full rounded-lg max-h-[calc(100vh-19.5rem)]"
                    title="PDF Viewer"
                />

            </div>

        </div>
    );
};

export default PDFModal;