import {
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Select, Textarea
} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import logo from "../../main-logo.png";
import pdfIcon from "../../pdfIcon.png";

import { doc, setDoc, deleteDoc, getDoc, updateDoc } from "firebase/firestore";

import {
    ArrowDownCircleIcon,
    ArrowPathIcon, ArrowTopRightOnSquareIcon,
    ArrowUpCircleIcon,
    ArrowUpOnSquareStackIcon, CheckBadgeIcon,
    CheckCircleIcon,
    ClipboardDocumentIcon,
    ClockIcon,
    DocumentArrowDownIcon,
    DocumentArrowUpIcon, EnvelopeIcon,
    PencilSquareIcon, PhoneIcon,
    PlusCircleIcon, PlusIcon,
    TrashIcon,
    TruckIcon,
    UserCircleIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {useAuth} from "../../contexts/authContext";
import {useDarkMode} from "../../contexts/darkModeContext";
import LineItemCard from "./LineItemCard";
import {AgCharts} from "ag-charts-react";
import {db} from "../../firebase/firebase";
import createQuoteTemplate from "../OrderForm/QuoteTemplate";

import pdfMake from "pdfmake/build/pdfmake";
import {
    Bars3Icon, BellIcon, CalendarDaysIcon, CreditCardIcon, EllipsisVerticalIcon,
    FaceFrownIcon,
    FaceSmileIcon,
    FireIcon,
    HandThumbUpIcon,
    HeartIcon, PaperClipIcon,
    XMarkIcon
} from "@heroicons/react/16/solid";
const moods = [
    { name: 'Excited', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
    { name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400' },
    { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
    { name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
    { name: 'Thumbsy', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500' },
    { name: 'I feel nothing', value: null, icon: XMarkIcon, iconColor: 'text-gray-400', bgColor: 'bg-transparent' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function XMarkIconOutline(props) {
    return null
}

const OrderCard = ({ order, onDelete }) => {

    const [isVisible, setIsVisible] = useState(false); // State to track visibility
    const [isAdding, setIsAdding] = useState(false);
    const { currentUser } = useAuth();

    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);  // Add loading state

    const [customerID, setCustomerID] = useState("");
    const [salesID, setSalesID] = useState("");

    const [orderERP, setOrderERP] = useState("");

    const [accountLevel, setAccountLevel] = useState("");
    const [disabled, setDisabled] = useState(false);

    const [viewID, setViewID] = useState(false);

    const handleViewIDToggle = () => {
        setViewID(true);
        setTimeout(() => {
            setViewID(false);
        }, 5000);
    };




    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [selected, setSelected] = useState(moods[5])





    useEffect(() => {
        setDisabled(accountLevel === "S" || accountLevel === "DIST");
    }, [accountLevel]);

    const { darkMode } = useDarkMode();

    // Function to toggle visibility
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const toggleAdding = () => {
        setIsAdding(!isAdding);
        setIsVisible(true);
    }

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    useEffect(() => {
        setIsLoaded(true);

        // Format data for the chart: Extract 'customerCode' and 'grandTotal'
        const chartData = order.products.map((product, index) => ({
            index: "Order [" + (index+1) + "]",
            name: product.name,
            total: parseFloat(product.total),
        }));
        // Set the chart data
        setChartData(chartData);
        setLoading(false); // Mark loading as complete

        setCustomerID(order.customerUID);
        setSalesID(order.salesUID);

    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed


    // Handle the remove action with animation
    const handleRemove = () => {
        setIsRemoving(true); // Trigger the animation
        setTimeout(() => onDelete, 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    };

    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Remove any non-digit characters (just in case)
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the input is valid (10 digits)
        if (cleaned.length !== 10) {
            return phoneNumber; // Return the original input if invalid
        }

        // Format the phone number (XXX) XXX-XXXX
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return phoneNumber; // Return the original input if not formatted
    };



    // Function to move document from "pending" to "active" collection and update status
    const moveToPending = async (orderID) => {
        try {
            console.log("Status Updated");
            // Reference to the order in the 'planned' collection
            const plannedDocRef = doc(db, `Customers/${currentUser.uid}/clients/${customerID}/planned`, orderID);

            // Get the document snapshot
            const orderSnapshot = await getDoc(plannedDocRef);
            if (!orderSnapshot.exists()) {
                throw new Error("Order not found in the planned collection");
            }

            const today = new Date();
            const formattedDate = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()}`;

            const orderData = orderSnapshot.data();
            const updatedOrderData = { ...orderData, status: "pending", orderDate: formattedDate };
            console.log("Status Updated");

            // Reference to the destination document in the "pending" subcollection
            const pendingDocRef = doc(db, `Customers/${currentUser.uid}/clients/${customerID}/pending`, orderID);

            // Copy the document to the "pending" subcollection with updated status
            await setDoc(pendingDocRef, updatedOrderData);

            // Delete the document from the "planned" subcollection
            await deleteDoc(plannedDocRef);

            console.log("Order moved to Pending collection and status updated to pending.");
            alert("Order moved to Pending collection and status updated to pending.");
        } catch (error) {
            console.error("Error moving order to pending collection:", error);
            alert("Error moving order to pending collection.");
        }
    };

    const moveToActive = async (orderID) => {
        if (!salesID || !customerID) {
            console.error("SalesID or CustomerID is missing.");
            alert("Order cannot be moved to Active: SalesID or CustomerID is not available.");
            return;
        }
        try {
            console.log("Moving order to Active");

            console.log("SalesID:", salesID);
            console.log("CustomerID:", customerID);
            console.log("Order ID:", orderID);

            // Reference to the order in the 'pending' collection
            const pendingDocRef = doc(db, `Customers/${salesID}/clients/${customerID}/pending`, orderID);

            // Get the document snapshot
            const orderSnapshot = await getDoc(pendingDocRef);
            if (!orderSnapshot.exists()) {
                throw new Error("Order not found in the pending collection");
            }

            const orderData = orderSnapshot.data();
            const updatedOrderData = { ...orderData, status: "active", orderERP: orderERP };

            // Reference to the destination document in the "active" subcollection
            const activeDocRef = doc(db, `Customers/${salesID}/clients/${customerID}/active`, orderID);

            // Copy the document to the "active" subcollection with updated status
            await setDoc(activeDocRef, updatedOrderData);

            // Delete the document from the "pending" subcollection
            await deleteDoc(pendingDocRef);

            console.log("Order moved to Active collection and status updated to active.");
            alert("Order moved to Active collection and status updated to active.");
        } catch (error) {
            console.error("Error moving order to active collection:", error);
            alert("Error moving order to active collection.");
        }
    };



    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const [bgColor, setBgColor] = useState("#efefef");
    const [textColor, setTextColor] = useState("#21b372");
    const [labelColor, setLabelColor] = useState("#ffffff");

    // Function to change colors based on darkMode state
    useEffect(() => {
        if (darkMode) {
            setBgColor("#2e2e2e");   // Dark background color for dark mode
            setTextColor("#21b372"); // Light text color for dark mode
            setLabelColor("#ffffff");
        } else {
            setBgColor("#f7f7f7");   // Light background color for light mode
            setTextColor("#000000"); // Dark text color for light mode
            setLabelColor("#000000");
        }
    }, [darkMode]); // Effect will run every time darkMode changes

    const myTheme = {
        overrides: {
            common: {
                title: {
                    fontSize: 18,
                    color: textColor,
                },
                subtitle: {
                    fontSize: 10,
                },
                axes: {
                    category: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for category axis
                        },
                    },
                    number: {
                        line: {
                            stroke: textColor, // Dynamic axis line color
                        },
                        tick: {
                            stroke: textColor, // Dynamic tick color
                        },
                        label: {
                            color: textColor, // Dynamic label color for number axis
                        },
                    },
                },
            },

            donut: {
                series: {
                    innerLabels:{
                        color: labelColor,
                    },
                    cornerRadius: 5,
                    calloutLabel: {
                        color: labelColor,
                    },
                },
                legend: {
                    item:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            shape: "circle",
                        }
                    },
                    pagination:{
                        label:{
                            color: labelColor,
                        },
                        marker:{
                            color: labelColor,
                        }
                    },
                },
            },


        },
    };

    const [options, setOptions] = useState({
        container: document.getElementById("myChart"),
        data: [],
        title: {
            text: "Order Product Makeup",
        },
        subtitle: {
            text: "By Total Price",
        },
        series: [
            {
                type: "donut",
                calloutLabelKey: "total",
                angleKey: "total",
                sectorLabelKey: "name",
                innerRadius: 4,
                calloutLabel: {
                    enabled: false,
                },
                sectorLabel: {
                    formatter: ({ datum, sectorLabelKey }) => {
                        const value = datum[sectorLabelKey];
                        return numberWithCommas(value);
                    },
                },
                innerRadiusRatio: 0.7,
                innerLabels: [
                    {
                        text: "Total:",
                        fontSize: 16,
                        spacing: 10,
                    },
                    {
                        text: `$${numberWithCommas(order.grandTotal)}`,
                        fontSize: 18,
                    },
                ],
                tooltip: {
                    renderer: ({ datum, calloutLabelKey, title, sectorLabelKey }) => {
                        return {
                            title,
                            content: `${numberWithCommas(datum[sectorLabelKey])}: $${numberWithCommas(datum[calloutLabelKey])}`,
                        };
                    },
                },
                sectorSpacing: 3,
            },
        ],
    });

    // Update chart options whenever chartData is updated
    useEffect(() => {
        console.log(chartData);
        setOptions((prevOptions) => ({
            ...prevOptions,
            theme: myTheme,
            data: chartData,
            background: {
                fill: bgColor, // Update background color dynamically
            }
        }));
    }, [chartData, darkMode, bgColor]);

    const downloadJSON = () => {
        const dataStr = JSON.stringify(order, null, 2); // Convert data to JSON string
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `${order.id}.json`; // Use a unique name for each file
        link.click();

        URL.revokeObjectURL(url); // Clean up the URL object
    };

    const today = new Date();
    const deliveryDate = order.deliveryDate ? new Date(order.deliveryDate) : null;

    // Calculate the difference in days if deliveryDate is valid
    const daysRemaining = deliveryDate
        ? Math.ceil((deliveryDate - today) / (1000 * 60 * 60 * 24))
        : null;


    const downloadQuotePDF = async () => {
        const docDefinition = createQuoteTemplate(order);
        pdfMake.createPdf(docDefinition).download(`${order.customerCode}_quote_.pdf`);
    };


    return (
        <div className={`w-full flex flex-col border-b-2 ${darkMode ? 'border-darkBgColor' : ''} overflow-x-scroll p-3 transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*HEADER*/}
            <div className={`w-full flex flex-row ${darkMode ? 'hover:bg-white' : 'hover:bg-slate-400'} hover:bg-opacity-5 rounded-lg`}>

                <div className="w-full flex flex-row">

                    {/*Buttons Delete*/}
                    <div className="flex flex-col items-center justify-center mr-3">

                        {order.status === "planned" && (
                            <div>

                                <Button
                                    className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={onDelete}
                                >
                                    <TrashIcon className="h-5 text-borderColor hover:text-red-600 hover:scale-110 m-auto"/>
                                </Button>

                            </div>
                        )}

                        {order.status === "pending" && (
                            <div>
                                <Button
                                    className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={downloadJSON}
                                >
                                    <DocumentArrowDownIcon className="h-5 text-borderColor hover:text-pendingColor hover:scale-110 m-auto"/>
                                </Button>

                            </div>
                        )}

                        {order.status === "active" && (
                            <div>
                                <Button
                                    className="rounded-full hover:animate-pulse mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                                    onClick={downloadJSON}
                                >
                                    <DocumentArrowDownIcon className="h-5 text-borderColor hover:text-pendingColor hover:scale-110 m-auto"/>
                                </Button>

                            </div>
                        )}

                    </div>

                    {/*Sales Rep*/}
                    <div className="w-1/4 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-8 h-8 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} rounded-full hover:scale-105`}>
                            {/*<div className="flex items-center justify-center h-full text-sm line-clamp-1">{order.salesCode || "--"}</div>*/}
                            {order.salesPhoto ? (
                                <img
                                    src={order.salesPhoto}
                                    alt="Profile"
                                    className="w-full h-full object-cover rounded-full"
                                />
                            ) : (
                                <div className="flex items-center justify-center h-full text-sm line-clamp-1">{order.salesCode || "--"}</div>
                            )}
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{order.salesRep || "- - - - - - - - - - -"}</div>
                            <div className="text-xs line-clamp-1">{order.salesEmail || "- - - - - - - - -"}</div>
                        </div>

                    </div>


                    {/*Customer*/}
                    <div className="w-1/4 flex flex-row items-center h-full mt-auto mb-auto">

                        <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} rounded-lg hover:scale-105`}>
                            <div className="flex items-center justify-center h-full text-sm line-clamp-1"
                                 onClick={() => {
                                     const textToCopy = order.customerCode || "- - - - -";
                                     navigator.clipboard.writeText(textToCopy)
                                         .then(() => {
                                             alert("Customer code copied to clipboard!");
                                         })
                                         .catch(err => {
                                             console.error("Failed to copy text: ", err);
                                         });
                                 }}
                            >{order.customerCode || "- - - - -"}</div>
                        </div>

                        <div className="justify-center flex flex-col h-full ml-2.5">
                            <div className="text-sm line-clamp-1">{order.customerName || "- - - - - - - - -"}</div>
                            <div className="text-xs line-clamp-1">{order.customerEmail || "- - - - -"} | {formatPhoneNumber(order.customerPhone) || "(---) --- - ----"}</div>
                        </div>


                    </div>


                    {/*ERP*/}
                    <div className={`flex w-1/6 items-center justify-center h-full text-sm px-4 rounded-full line-clamp-1`}>
                        {order.status === "active" && (
                            <div>

                                <div className={`w-fit p-1 h-8 ${darkMode ? 'border-darkBgColor' : ''} rounded-full border flex flex-row hover:scale-105`}>
                                    <div className="flex items-center justify-center h-full text-xs px-2">CO-{order.orderERP || "- - - - -"}</div>
                                </div>

                            </div>
                        )}

                        {order.status === "pending" && (
                            <div>

                                <div>

                                    <Button className={`w-full ${disabled ? 'bg-opacity-50' : 'hover:scale-105'} px-3 py-1.5 mr-auto bg-bgLightGreen line-clamp-1 rounded-full text-white text-xs hover:bg-opacity-80 flex flex-row items-center justify-center`}
                                        onClick={toggleAdding}
                                            disabled={disabled}
                                    >
                                        <ArrowUpOnSquareStackIcon className="w-4 mt-auto mb-auto mr-2 line-clamp-1"/>Order Num</Button>

                                </div>

                            </div>
                        )}

                        {order.status === "planned" && (
                            <div>

                                <div>

                                    <Button className="w-full px-3 py-1.5 mr-auto bg-opacity-50 bg-bgLightGreen line-clamp-1 rounded-full text-white text-xs flex flex-row items-center justify-center"
                                            disabled={true}
                                    >
                                        <ArrowUpOnSquareStackIcon className="w-4 mt-auto mb-auto mr-2 line-clamp-1"/>Order Num</Button>

                                </div>

                            </div>
                        )}
                    </div>


                    {/*Date / Grand Total*/}
                    <div className="w-1/2 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                        {/*Status*/}
                        <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto line-clamp-1">

                            {order.status === "active" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-orderColor' : 'bg-emerald-200/60 text-emerald-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <CheckCircleIcon className="w-5 mr-3 hover:animate-pulse"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Ordered</div>
                                    </div>

                                </div>
                            )}

                            {order.status === "pending" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-pendingColor' : 'bg-pendingColor text-yellow-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>
                                        <ClockIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Pending</div>
                                    </div>

                                </div>
                            )}

                            {order.status === "planned" && (
                                <div className={`flex flex-row`}>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-plannedColor' : 'bg-plannedColor border text-blue-700'} bg-opacity-50 rounded-full flex flex-row hover:scale-105`}>
                                        <ClipboardDocumentIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Planned</div>
                                    </div>

                                    <Button className={`w-8 ml-5 flex items-center justify-center p-1 h-8 ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-green-500' : 'bg-bgColor drop-shadow border text-green-700'} bg-opacity-50 rounded-full flex flex-row hover:scale-105`}
                                            onClick={downloadQuotePDF}
                                    >
                                        <DocumentArrowDownIcon className="w-5 hover:animate-pulse"/>
                                    </Button>

                                </div>
                            )}

                            {order.status === "shipped" && (
                                <div>

                                    <div className={`w-fit p-1 h-8 ${darkMode ? 'bg-shippedColor text-black' : 'bg-shippedColor border'} rounded-full flex flex-row hover:scale-105`}>
                                        <TruckIcon className="w-5 mr-3 hover:animate-spin"/>
                                        <div className="flex items-center justify-center h-full text-xs mr-3">Shipped</div>
                                    </div>

                                </div>
                            )}

                        </div>

                        {/*Date / Submit Button*/}
                        <div className="w-1/3 p-1 h-full">
                            <div className="flex items-center justify-center h-full text-sm px-4 line-clamp-1">
                                {order.status === "planned" ? (
                                    <div>

                                        <Button className="w-full px-3 py-1.5 mr-auto bg-bgLightGreen hover:scale-105 line-clamp-1 rounded-full text-white text-xs hover:bg-opacity-80 flex flex-row items-center justify-center"
                                                onClick={() => moveToPending(order.id)}
                                        >
                                            <DocumentArrowUpIcon className="w-4 mt-auto mb-auto mr-2 line-clamp-1"/>Submit</Button>

                                    </div>
                                ) : (

                                    <div className="justify-center flex flex-col h-full ml-2.5">
                                        <div className={`flex items-center text-xs`}>
                                            <div className={`mr-2 font-bold`}>[O]:</div>
                                            <div className="text-xs">{order.orderDate || "--/--/----"}</div>
                                        </div>

                                        <div className={`text-xs items-center flex`}>
                                            {daysRemaining !== null && (
                                                <div className={`flex ${order.status === "active" ? '' : (daysRemaining < 5 ? 'text-red-500 animate-heartbeat hover:animate-none' : '')}`}><div className={`mr-2 font-bold`}>[D]:</div>
                                                    <span className="line-clamp-1">
                                                        {order.deliveryDate || "--/--/----"} {order.status !== "active" && `[${daysRemaining}]`}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/*Order Total*/}
                        <div className="w-1/3 h-8 items-center justify-end flex">
                            <div className={`flex ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} w-fit items-center justify-end h-full text-sm px-2 hover:scale-105 rounded-full line-clamp-1 tracking-wider`}>$ {numberWithCommas(order.grandTotal.toFixed(2)) || "- - - - -"} USD</div>
                        </div>

                    </div>


                    {/*Buttons DropDown*/}
                    <div className="flex flex-col items-center ml-3">
                        <Button
                            className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle"
                            onClick={toggleVisibility}
                        >

                            {isVisible ? (
                                <ArrowUpCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                            ) : (
                                <ArrowDownCircleIcon
                                    className="h-6 text-borderColor hover:text-bgLightGreen hover:stroke-2 hover:animate-pulse hover:scale-110 m-auto"/>
                            )}

                        </Button>
                    </div>


                </div>

            </div>


            {isVisible && (

                <>
                    <main className={`animate-fadeIn`}>

                        {/*Header*/}
                        <header className="relative isolate">

                            <div className="mx-auto w-full px-4 py-3 sm:px-6 lg:px-8">
                                <div className="mx-auto flex w-full relative items-center justify-between gap-x-8 lg:mx-0 lg:max-w-none">
                                    <div className="flex items-center gap-x-6">
                                        <div className={`${darkMode ? 'bg-darkBgColor border border-neutral-600' : 'bg-neutral-100 border'} rounded-full p-3 size-16 flex items-center justify-center`}>
                                            <img
                                                alt=""
                                                src={logo}
                                                className="flex-none"
                                            />
                                        </div>
                                        <h1>
                                            <div className="text-sm/6">Order: <span className="text-gray-500"># CO-{order.orderERP || "- - - - - -"}</span></div>
                                            <div className="mt-1 text-base font-semibold">{order.customerName}</div>
                                        </h1>
                                    </div>

                                    {viewID && (
                                        <div className={`absolute left-1/2 transform -translate-x-1/2 text-center`}>
                                            <div className={`flex items-center ${darkMode ? 'bg-darkBgColor border-2 border-neutral-600' : 'bg-bgColor border-2'} px-4 rounded-lg py-1`}>
                                                {/* Circular Progress Bar */}
                                                <div className="relative w-4 h-4 mr-2"> {/* Smaller size */}
                                                    <svg className="absolute top-0 left-0 transform rotate-90" width="100%" height="100%" viewBox="0 0 36 36">
                                                        <path
                                                            className="circle-background"
                                                            fill="none"
                                                            stroke="#e6e6e6"
                                                            strokeWidth="5"
                                                            strokeDasharray="100"
                                                            strokeDashoffset="0"
                                                            d="M18 2 A16 16 0 1 1 18 34 A16 16 0 1 1 18 2"
                                                        />
                                                        <path
                                                            className="circle-progress"
                                                            fill="none"
                                                            stroke="#4caf50"
                                                            strokeWidth="5"
                                                            strokeDasharray="100"
                                                            strokeDashoffset="100"
                                                            d="M18 2 A16 16 0 1 1 18 34 A16 16 0 1 1 18 2"
                                                            style={{ animation: "progress 5s linear forwards" }}
                                                        />
                                                    </svg>
                                                </div>

                                                {/* Order ID */}
                                                <div className={`text-xs`}>{order.id}</div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="flex items-center gap-x-4 sm:gap-x-6">
                                        <Button
                                            type="button"
                                            className="hidden text-sm/6 hover:scale-105 font-semibold sm:block"
                                            onClick={handleViewIDToggle}
                                        >
                                            View ID
                                        </Button>
                                        <a href="#" className="hidden text-sm/6 hover:scale-105 font-semibold sm:block">
                                            Edit
                                        </a>
                                        <a
                                            href="#"
                                            className="rounded-md bg-green-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-opacity-50"
                                        >
                                            Save
                                        </a>

                                        <Menu as="div" className="relative sm:hidden">
                                            <MenuButton className="-m-3 block p-3">
                                                <span className="sr-only">More</span>
                                                <EllipsisVerticalIcon aria-hidden="true" className="size-5 text-gray-500" />
                                            </MenuButton>

                                            <MenuItems
                                                transition
                                                className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                            >
                                                <MenuItem>
                                                    <button
                                                        type="button"
                                                        className="block w-full px-3 py-1 text-left text-sm/6 text-gray-900 hover:text-opacity-50 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                                    >
                                                        View ID
                                                    </button>
                                                </MenuItem>
                                                <MenuItem>
                                                    <a
                                                        href="#"
                                                        className="block px-3 py-1 text-sm/6 text-gray-900 hover:text-opacity-50 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                                    >
                                                        Edit
                                                    </a>
                                                </MenuItem>
                                            </MenuItems>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </header>

                        {/*Body*/}
                        <div className="mx-auto px-4 py-3 sm:px-6 lg:px-8">
                            <div className="mx-auto grid max-w-2xl grid-cols-1 grid-rows-1 items-start gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">

                                {/* Invoice summary */}
                                <div className="lg:col-start-3 lg:row-end-1">
                                    <h2 className="sr-only">Summary</h2>
                                    <div className={`rounded-lg ${darkMode ? 'bg-darkBgColor/50' : 'bg-bgColor/50'} shadow-lg ring-1 ring-gray-900/5`}>
                                        <dl className="flex flex-wrap">
                                            <div className="flex-auto pl-6 pt-6">
                                                <dt className="text-sm/6 font-semibold">Total:</dt>
                                                <dd className="mt-1 text-base font-semibold">${numberWithCommas(order.grandTotal.toFixed(2))}</dd>
                                            </div>
                                            <div className="flex-none self-end px-6 pt-4">
                                                <dt className="sr-only">Status</dt>

                                                <dd className={``}>
                                                    {order.status === "active" && (
                                                        <div>

                                                            <div className={`w-fit p-1 h-8 flex items-center ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-orderColor' : 'bg-emerald-200/60 text-emerald-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>

                                                                <div className={`flex-none rounded-full h-4 p-1 bg-orderColor/30 mr-3`}>
                                                                    <div className={`size-2 rounded-full bg-orderColor`}/>
                                                                </div>

                                                                <div className="flex items-center justify-center h-full text-xs mr-3">Ordered</div>
                                                            </div>

                                                        </div>
                                                    )}

                                                    {order.status === "pending" && (
                                                        <div>

                                                            <div className={`w-fit p-1 h-8 flex items-center ${darkMode ? 'text-pendingColor' : 'bg-pendingColor text-yellow-800 bg-opacity-50 border'} rounded-full flex flex-row hover:scale-105`}>

                                                                <div className={`flex-none rounded-full h-4 p-1 bg-pendingColor/30 mr-3`}>
                                                                    <div className={`size-2 rounded-full bg-pendingColor`} />
                                                                </div>

                                                                <div className="flex items-center justify-center h-full text-xs mr-3">Pending</div>
                                                            </div>

                                                        </div>
                                                    )}

                                                    {order.status === "planned" && (
                                                        <div>

                                                            <div className={`w-fit p-1 h-8 flex items-center ${darkMode ? 'bg-darkBgColor bg-opacity-50 text-plannedColor' : 'bg-plannedColor border text-blue-700'} bg-opacity-50 rounded-full flex flex-row hover:scale-105`}>

                                                                <div className={`flex-none rounded-full h-4 p-1 bg-plannedColor/30 mr-3`}>
                                                                    <div className={`size-2 rounded-full bg-plannedColor`}/>
                                                                </div>

                                                                <div className="flex items-center justify-center h-full text-xs mr-3">Planned</div>
                                                            </div>

                                                        </div>
                                                    )}

                                                    {order.status === "shipped" && (
                                                        <div>

                                                            <div className={`w-fit p-1 h-8 flex items-center ${darkMode ? 'bg-shippedColor text-black' : 'bg-shippedColor border'} rounded-full flex flex-row hover:scale-105`}>

                                                                <div className={`flex-none rounded-full h-4 p-1 bg-shippedColor/30 mr-3`}>
                                                                    <div className={`size-2 rounded-full bg-shippedColor`}/>
                                                                </div>

                                                                <div className="flex items-center justify-center h-full text-xs mr-3">Shipped</div>
                                                            </div>

                                                        </div>
                                                    )}
                                                </dd>
                                            </div>
                                            <div className={`mt-6 flex w-full flex-none gap-x-4 border-t ${darkMode ? 'border-neutral-700' : ''} px-6 pt-6`}>
                                                <dt className="flex-none">
                                                    <UserCircleIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                                                </dt>
                                                <dd className="text-sm/6 font-medium">{order.customerName || "- - - -"}</dd>
                                            </div>
                                            <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                                <dt className="flex-none">
                                                    <EnvelopeIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
                                                </dt>
                                                <dd className="text-sm/6">
                                                    <div className="text-sm/6 font-medium">{order.customerEmail || "- - - -"}</div>
                                                </dd>
                                            </div>

                                            {order.earlyOrder && (
                                                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                                    <dt className="flex-none">
                                                        <CheckBadgeIcon aria-hidden="true" className="h-6 w-5 stroke-2 animate-pulse text-emerald-500"/>
                                                    </dt>
                                                    <dd className="text-sm/6">Early Order</dd>
                                                </div>
                                            )}

                                            {order.prePay && (
                                                <div className="mt-4 flex w-full flex-none gap-x-4 px-6">
                                                    <dt className="flex-none">
                                                        <CreditCardIcon aria-hidden="true" className="h-6 w-5 stroke-2 animate-pulse text-emerald-500"/>
                                                    </dt>
                                                    <dd className="text-sm/6">
                                                        <div className="text-sm/6">Prepay Order</div>
                                                    </dd>
                                                </div>
                                            )}
                                        </dl>
                                        <div
                                            className={`mt-6 border-t ${darkMode ? 'border-neutral-700' : ''} px-6 py-6`}>
                                            <Button
                                                className="flex flex-row text-sm/6 font-semibold hover:text-green-600"
                                                onClick={downloadQuotePDF}
                                            >
                                                <img
                                                    alt=""
                                                    src={pdfIcon}
                                                    className="flex-none w-5 mr-3"
                                                />
                                                Download Quote PDF <span aria-hidden="true">&rarr;</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                {/* Invoice */}
                                <div className={`-mx-4 px-6 py-8 shadow-lg border ${darkMode ? 'border-neutral-700' : ''} sm:mx-0 sm:rounded-lg sm:pb-14 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:pb-20 xl:pt-16`}>


                                    <h2 className="text-base font-semibold">Order</h2>
                                    <dl className="mt-6 grid grid-cols-1 text-sm/6 sm:grid-cols-2">
                                        <div className="sm:pr-4">
                                            <dt className="inline mr-3 font-semibold">Placed on:</dt>{' '}
                                            <dd className="inline text-gray-400">
                                                <time dateTime="2023-23-01">{order.orderDate}</time>
                                            </dd>
                                        </div>
                                        <div className="mt-2 sm:mt-0 sm:pl-4 flex">
                                            <dt className="inline mr-3 font-semibold">Intended By:</dt>{' '}
                                            <dd className="inline text-gray-400">
                                                {daysRemaining !== null && (
                                                    <div className={`flex ${order.status === "active" ? '' : (daysRemaining < 5 ? 'text-red-500 animate-heartbeat hover:animate-none' : '')}`}>
                                                        <div className={`mr-2 font-bold`}></div>
                                                        <span className="line-clamp-1">{order.deliveryDate || "--/--/----"} {order.status !== "active" && `[${daysRemaining}]`}</span>
                                                    </div>
                                                )}
                                            </dd>
                                        </div>
                                        <div className={`mt-6 border-t-2 ${darkMode ? 'border-neutral-700' : ''} pt-6 sm:pr-4`}>
                                            <dt className="font-semibold">From:</dt>
                                            <dd className="mt-2 text-gray-400">
                                                <span className="font-medium">Plant Food Company Inc.</span>
                                                <br />
                                                38 Hightstown-Cranbury Station Rd,
                                                <br />
                                                Cranbury, NJ 08512
                                            </dd>
                                        </div>
                                        <div className={`mt-8 sm:mt-6 sm:border-t-2 ${darkMode ? 'sm:border-neutral-700' : ''} sm:pl-4 sm:pt-6 relative`}>
                                            <dt className="font-semibold flex items-center">To:
                                                {order.callBefore && (
                                                    <PhoneIcon aria-hidden="true" className=" stroke-2 w-5 h-5 ml-2 text-emerald-500 animate-bounce" />
                                                )}
                                            </dt>

                                            <dd className="mt-2 text-gray-400">
                                                <span className="font-medium">{order.customerName || "- - - -"}</span>
                                                <br />
                                                886 Walter Street
                                                <br />
                                                New York, NY 12345
                                            </dd>
                                        </div>
                                    </dl>


                                    {/*Product Table*/}
                                    <table className="mt-16 w-full whitespace-nowrap text-left text-sm/6">
                                        <colgroup>
                                            <col className="w-full" />
                                            <col />
                                            <col />
                                            <col />
                                        </colgroup>
                                        <thead className={`border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                        <tr className={`text-gray-500`}>
                                            <th scope="col" className="px-0 py-3 font-semibold">
                                                LineItems [{order.products.length}]
                                            </th>
                                            <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                                Price
                                            </th>
                                            <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                                Quantity
                                            </th>
                                            <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                                U/M
                                            </th>
                                            <th scope="col" className="hidden py-3 pl-8 pr-0 text-right font-semibold sm:table-cell">
                                                WH
                                            </th>
                                            <th scope="col" className="py-3 pl-8 pr-0 text-right font-semibold">
                                                Total
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {order.products.map((item, index) => (
                                            <tr key={item.id} className={`border-b-2 ${darkMode ? 'border-neutral-700' : ''}`}>
                                                <td className="max-w-0 px-0 align-middle">
                                                    <div className="truncate font-medium indent-3">{index+1}] {item.name}</div>
                                                    <div className="truncate text-gray-500 text-xxs indent-6">{item.productCode}</div>
                                                </td>
                                                <td className="items-center py-5 pl-8 pr-0 text-right align-top tabular-nums sm:table-cell flex">
                                                    $ {numberWithCommas(parseFloat(item.price).toFixed(2))} <span className={`ml-2 text-gray-500 font-bold text-xs`}>{item.discountAmount}%</span>
                                                </td>
                                                <td className="hidden py-5 pl-8 pr-0 text-right align-top tabular-nums sm:table-cell">
                                                    {numberWithCommas(item.quantity)}
                                                </td>
                                                <td className="hidden py-5 pl-8 pr-0 text-right align-top sm:table-cell">
                                                    {item.selectedVolume}
                                                </td>
                                                <td className="hidden py-5 pl-8 pr-0 text-right align-top sm:table-cell">
                                                    {item.warehouse}
                                                </td>
                                                <td className="py-5 pl-8 pr-0 text-right align-top tabular-nums">$ {numberWithCommas(parseFloat(item.total).toFixed(2))}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                        <th scope="row" className="px-0 pb-0 pt-6 font-normal sm:hidden">
                                                Subtotal
                                            </th>
                                            <th
                                                scope="row"
                                                colSpan={5}
                                                className="hidden px-0 pb-0 pt-6 text-right font-normal sm:table-cell text-gray-500"
                                            >
                                                Subtotal:
                                            </th>
                                            <td className="pb-0 pl-8 pr-0 pt-6 text-right tabular-nums text-gray-500">${numberWithCommas(parseFloat(order.subTotal).toFixed(2))}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="pt-4 font-normal sm:hidden">
                                                Discount
                                            </th>
                                            <th
                                                scope="row"
                                                colSpan={5}
                                                className="hidden pt-4 text-right font-normal sm:table-cell text-gray-500"
                                            >
                                                Discount [-{order.discount}%]:
                                            </th>
                                            <td className="pb-0 pl-8 pr-0 pt-4 text-right tabular-nums text-gray-500">${numberWithCommas((order.subTotal - order.grandTotal).toFixed(2))}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" className="pt-4 font-semibold sm:hidden">
                                                Total
                                            </th>
                                            <th
                                                scope="row"
                                                colSpan={5}
                                                className="hidden pt-4 text-right font-semibold sm:table-cell"
                                            >
                                                Total:
                                            </th>
                                            <td className="pb-0 pl-8 pr-0 pt-4 text-right font-semibold tabular-nums">
                                                ${numberWithCommas(parseFloat(order.grandTotal).toFixed(2))}
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>


                                </div>

                                {/*Analytics + Notes*/}
                                <div className={`lg:col-start-3`}>
                                    {/* Activity feed */}
                                    <div className={`flex flex-col h-fit pb-1 rounded-lg ${darkMode ? 'bg-darkBgColor/50' : 'bg-bgColor/50'} shadow-lg ring-1 ring-gray-900/5`}>
                                        <h2 className="text-sm/6 font-semibold px-4 py-2 tracking-widest">Analytics: </h2>

                                        {loading ?
                                            <div className="text-white">Loading chart...</div>
                                            :
                                            <div style={{display: 'grid', width: '100%', height: '100%'}}
                                                 className={`transition-transform duration-500 ease-in-out`}>
                                                <AgCharts options={options}/>
                                            </div>
                                        }
                                    </div>

                                    {/*Notes*/}
                                    <div className={`mt-8 flex flex-col h-fit pb-1 rounded-lg ${darkMode ? 'bg-darkBgColor/50' : 'bg-bgColor/50'} shadow-lg ring-1 ring-gray-900/5 px-4 pt-2 pb-2`}>
                                        <h2 className="text-sm/6 font-semibold mb-2 tracking-widest">Notes:</h2>

                                        <Textarea
                                            id=""
                                            name=""
                                            disabled={true}
                                            value={order.notes}
                                            autoComplete=""
                                            placeholder="Notes..."
                                            // onChange={(e) => setNotes(e.target.value)}
                                            className={`block w-full focus:outline-none ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border ring-1 ring-inset ring-gray-300'} p-2 h-32 rounded-md shadow-gray-900 py-1.5 placeholder:text-gray-400`}
                                        />

                                    </div>

                                    {/*<ul role="list" className="mt-6 space-y-6">*/}
                                    {/*    {activity.map((activityItem, activityItemIdx) => (*/}
                                    {/*        <li key={activityItem.id} className="relative flex gap-x-4">*/}
                                    {/*            <div*/}
                                    {/*                className={classNames(*/}
                                    {/*                    activityItemIdx === activity.length - 1 ? 'h-6' : '-bottom-6',*/}
                                    {/*                    'absolute left-0 top-0 flex w-6 justify-center',*/}
                                    {/*                )}*/}
                                    {/*            >*/}
                                    {/*                <div className="w-px bg-gray-200" />*/}
                                    {/*            </div>*/}
                                    {/*            {activityItem.type === 'commented' ? (*/}
                                    {/*                <>*/}
                                    {/*                    <img*/}
                                    {/*                        alt=""*/}
                                    {/*                        src={activityItem.person.imageUrl}*/}
                                    {/*                        className="relative mt-3 size-6 flex-none rounded-full bg-gray-50"*/}
                                    {/*                    />*/}
                                    {/*                    <div className="flex-auto rounded-md p-3 ring-1 ring-inset ring-gray-200">*/}
                                    {/*                        <div className="flex justify-between gap-x-4">*/}
                                    {/*                            <div className="py-0.5 text-xs/5 text-gray-500">*/}
                                    {/*                                <span className="font-medium text-gray-900">{activityItem.person.name}</span> commented*/}
                                    {/*                            </div>*/}
                                    {/*                            <time dateTime={activityItem.dateTime} className="flex-none py-0.5 text-xs/5 text-gray-500">*/}
                                    {/*                                {activityItem.date}*/}
                                    {/*                            </time>*/}
                                    {/*                        </div>*/}
                                    {/*                        <p className="text-sm/6 text-gray-500">{activityItem.comment}</p>*/}
                                    {/*                    </div>*/}
                                    {/*                </>*/}
                                    {/*            ) : (*/}
                                    {/*                <>*/}
                                    {/*                    <div className="relative flex size-6 flex-none items-center justify-center bg-white">*/}
                                    {/*                        {activityItem.type === 'paid' ? (*/}
                                    {/*                            <CheckCircleIcon aria-hidden="true" className="size-6 text-indigo-600" />*/}
                                    {/*                        ) : (*/}
                                    {/*                            <div className="size-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />*/}
                                    {/*                        )}*/}
                                    {/*                    </div>*/}
                                    {/*                    <p className="flex-auto py-0.5 text-xs/5 text-gray-500">*/}
                                    {/*                        <span className="font-medium text-gray-900">{activityItem.person.name}</span>{' '}*/}
                                    {/*                        {activityItem.type} the invoice.*/}
                                    {/*                    </p>*/}
                                    {/*                    <time dateTime={activityItem.dateTime} className="flex-none py-0.5 text-xs/5 text-gray-500">*/}
                                    {/*                        {activityItem.date}*/}
                                    {/*                    </time>*/}
                                    {/*                </>*/}
                                    {/*            )}*/}
                                    {/*        </li>*/}
                                    {/*    ))}*/}
                                    {/*</ul>*/}

                                    {/*/!* New comment form *!/*/}
                                    {/*<div className="mt-6 flex gap-x-3">*/}
                                    {/*    <img*/}
                                    {/*        alt=""*/}
                                    {/*        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"*/}
                                    {/*        className="size-6 flex-none rounded-full bg-gray-50"*/}
                                    {/*    />*/}
                                    {/*    <form action="#" className="relative flex-auto">*/}
                                    {/*        <div className="overflow-hidden rounded-lg pb-12 outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">*/}
                                    {/*            <label htmlFor="comment" className="sr-only">*/}
                                    {/*                Add your comment*/}
                                    {/*            </label>*/}
                                    {/*            <textarea*/}
                                    {/*                id="comment"*/}
                                    {/*                name="comment"*/}
                                    {/*                rows={2}*/}
                                    {/*                placeholder="Add your comment..."*/}
                                    {/*                className="block w-full resize-none bg-transparent px-3 py-1.5 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"*/}
                                    {/*                defaultValue={''}*/}
                                    {/*            />*/}
                                    {/*        </div>*/}

                                    {/*        <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">*/}
                                    {/*            <div className="flex items-center space-x-5">*/}
                                    {/*                <div className="flex items-center">*/}
                                    {/*                    <button*/}
                                    {/*                        type="button"*/}
                                    {/*                        className="-m-2.5 flex size-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"*/}
                                    {/*                    >*/}
                                    {/*                        <PaperClipIcon aria-hidden="true" className="size-5" />*/}
                                    {/*                        <span className="sr-only">Attach a file</span>*/}
                                    {/*                    </button>*/}
                                    {/*                </div>*/}
                                    {/*                <div className="flex items-center">*/}
                                    {/*                    <Listbox value={selected} onChange={setSelected}>*/}
                                    {/*                        <Label className="sr-only">Your mood</Label>*/}
                                    {/*                        <div className="relative">*/}
                                    {/*                            <ListboxButton className="relative -m-2.5 flex size-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">*/}
                                    {/*                                              <span className="flex items-center justify-center">*/}
                                    {/*                                                {selected.value === null ? (*/}
                                    {/*                                                    <span>*/}
                                    {/*                                                    <FaceSmileIcon aria-hidden="true" className="size-5 shrink-0" />*/}
                                    {/*                                                    <span className="sr-only">Add your mood</span>*/}
                                    {/*                                                  </span>*/}
                                    {/*                                                ) : (*/}
                                    {/*                                                    <span>*/}
                                    {/*                                                    <span*/}
                                    {/*                                                        className={classNames(*/}
                                    {/*                                                            selected.bgColor,*/}
                                    {/*                                                            'flex size-8 items-center justify-center rounded-full',*/}
                                    {/*                                                        )}*/}
                                    {/*                                                    >*/}
                                    {/*                                                      <selected.icon aria-hidden="true" className="size-5 shrink-0 text-white" />*/}
                                    {/*                                                    </span>*/}
                                    {/*                                                    <span className="sr-only">{selected.name}</span>*/}
                                    {/*                                                  </span>*/}
                                    {/*                                                )}*/}
                                    {/*                                              </span>*/}
                                    {/*                            </ListboxButton>*/}

                                    {/*                            <ListboxOptions*/}
                                    {/*                                transition*/}
                                    {/*                                className="absolute bottom-10 z-10 -ml-6 w-60 rounded-lg bg-white py-3 text-base shadow ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:ml-auto sm:w-64 sm:text-sm"*/}
                                    {/*                            >*/}
                                    {/*                                {moods.map((mood) => (*/}
                                    {/*                                    <ListboxOption*/}
                                    {/*                                        key={mood.value}*/}
                                    {/*                                        value={mood}*/}
                                    {/*                                        className="relative cursor-default select-none bg-white px-3 py-2 data-[focus]:bg-gray-100"*/}
                                    {/*                                    >*/}
                                    {/*                                        <div className="flex items-center">*/}
                                    {/*                                            <div*/}
                                    {/*                                                className={classNames(*/}
                                    {/*                                                    mood.bgColor,*/}
                                    {/*                                                    'flex size-8 items-center justify-center rounded-full',*/}
                                    {/*                                                )}*/}
                                    {/*                                            >*/}
                                    {/*                                                <mood.icon*/}
                                    {/*                                                    aria-hidden="true"*/}
                                    {/*                                                    className={classNames(mood.iconColor, 'size-5 shrink-0')}*/}
                                    {/*                                                />*/}
                                    {/*                                            </div>*/}
                                    {/*                                            <span className="ml-3 block truncate font-medium">{mood.name}</span>*/}
                                    {/*                                        </div>*/}
                                    {/*                                    </ListboxOption>*/}
                                    {/*                                ))}*/}
                                    {/*                            </ListboxOptions>*/}
                                    {/*                        </div>*/}
                                    {/*                    </Listbox>*/}
                                    {/*                </div>*/}
                                    {/*            </div>*/}
                                    {/*            <button*/}
                                    {/*                type="submit"*/}
                                    {/*                className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"*/}
                                    {/*            >*/}
                                    {/*                Comment*/}
                                    {/*            </button>*/}
                                    {/*        </div>*/}
                                    {/*    </form>*/}
                                    {/*</div>*/}




                                </div>


                            </div>
                        </div>

                    </main>
                </>


            )}


            {isAdding && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!isAdding}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        isAdding ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-full max-w-md max-h-full">

                        {/* Modal content */}
                        <div
                            className={`animate-zoomIn relative ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor' : 'bg-white drop-shadow-lg'} rounded-lg`}>

                            {/* Modal header */}
                            <div
                                className={`flex items-center justify-between p-4 md:p-5 border-b ${darkMode ? 'border-borderColor' : 'border'} rounded-t`}>
                                <h3 className="text-lg font-semibold">
                                    Connect to ERP
                                </h3>
                                <Button
                                    type="button"
                                    className="text-gray-400 hover:animate-pulse bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleAdding}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                </Button>
                            </div>

                            {/* Modal body */}
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevent default form submission
                                    moveToActive(order.id); // Use the captured order number or adjust accordingly
                                }}
                                className="p-4 md:p-5">

                                {/*Customer Code*/}
                                <div className="grid gap-4 mb-4 grid-cols-2">

                                    <div className="col-span-2">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Order Number
                                        </label>
                                        <div className={`flex flex-row items-center`}>
                                            <div className={`tracking-widest text-gray-500 mr-1`}>CO-</div>

                                            <input
                                                type="text"
                                                name="name"
                                                id="name"
                                                className={`rounded-lg block w-full p-2.5 ${darkMode ? 'dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-primary-600 focus:border-primary-600'}`}
                                                placeholder="Type order number"
                                                value={orderERP} onChange={(e) => setOrderERP(e.target.value)}
                                                required
                                            />
                                        </div>
                                    </div>

                                </div>


                                <div className={`flex flex-row items-center`}>

                                    <Button
                                        type="submit"
                                        // onClick={() => moveToActive(order.id)}
                                        className="text-white inline-flex items-center bg-bgLightGreen hover:bg-opacity-50 hover:scale-105 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    >
                                        <svg
                                            className="me-1 -ms-1 w-5 h-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                                clipRule="evenodd"
                                            />
                                        </svg>

                                        Update Order

                                    </Button>

                                    <div className={`text-xs ml-auto`}>*Updates Status to Active</div>

                                </div>


                            </form>

                        </div>
                    </div>
                </div>

            )}

        </div>
    )
}

export default OrderCard;
