import React, {useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import {Input} from "@headlessui/react";


const ProfileGuides = () => {

    const { darkMode } = useDarkMode();

    const posts = [
        {
            id: 1,
            title: 'How to upload a profile image?',
            href: '#',
            description:
                'On the left side of the screen below the profile image to the left you will see a dropdown button. When you press the button you will see the input field appear where you may choose a file from your computer. Once the image is chosen you will have to hit the update photo which will correctly upload an image to the cloud.',
            date: 'Nov 26, 2024',
            category: { title: 'Profile & Settings', href: '#' },
        },
        {
            id: 2,
            title: 'How to reload your account Info?',
            href: '#',
            description:
                'In the top left hand corner of the screen you will see the update button and when that is pressed this will update your account information across the entire system.',
            date: 'Nov 26, 2024',
            category: { title: 'Profile & Settings', href: '#' },
        },
        {
            id: 3,
            title: 'How to Update account information?',
            href: '#',
            description:
                'In the right hand side of the screen below the “Account Info” you may edit any of the account information in the field and then hit the update button below.',
            date: 'Nov 26, 2024',
            category: { title: 'Profile & Settings', href: '#' },
        },
        {
            id: 4,
            title: 'Password Reset',
            href: '#',
            description:
                'Hit the “Password Reset” button and follow the information in the popup window.',
            date: 'Nov 26, 2024',
            category: { title: 'Profile & Settings', href: '#' },
        },
        {
            id: 5,
            title: 'Email Reset',
            href: '#',
            description:
                'Hit the “Email Reset” button and follow the information in the popup window.',
            date: 'Nov 26, 2024',
            category: { title: 'Profile & Settings', href: '#' },
        },
        {
            id: 6,
            title: 'Technical Support',
            href: '#',
            description:
                'This is the section that you may submit any trouble tickets that you may have anywhere in the application.',
            date: 'Nov 26, 2024',
            category: { title: 'Profile & Settings', href: '#' },
        },
        {
            id: 7,
            title: 'How to submit a trouble ticket?',
            href: '#',
            description:
                'Press the “+ Add Ticket” button and fill out all of the according fields in order to submit your trouble ticket.',
            date: 'Nov 26, 2024',
            category: { title: 'Profile & Settings', href: '#' },
        },
        {
            id: 8,
            title: 'Customer Service Reps Section',
            href: '#',
            description:
                'This section is only available to customer service reps and it allows them to select the sales reps that they service.',
            date: 'Nov 26, 2024',
            category: { title: 'Profile & Settings', href: '#' },
        },
        {
            id: 9,
            title: 'How to select and or change your sales reps list?',
            href: '#',
            description:
                'In order to add a sales rep to your list just select a rep from the dropdown and you will see them added to your list. In order to remove a sales rep you just have to hit the x button. After any changes you must hit the update button in order to save your changes.',
            date: 'Nov 26, 2024',
            category: { title: 'Profile & Settings', href: '#' },
        },
        // More posts...
    ]


    return (

        <div className={`p-3 ${darkMode ? 'text-white' : 'text-black'} overflow-y-scroll animate-fadeIn`}>

            <div className="mx-auto px-6">
                <div className="mx-auto">


                    <h2 className="text-pretty text-green-600 text-4xl font-semibold tracking-widest sm:text-5xl">Profile & Settings</h2>
                    <p className={`mt-2 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>The Settings and Profile view allows users to manage their account details and preferences for a tailored experience.</p>


                    <div className={`mt-8 border-t-4 border-dashed ${darkMode ? 'border-green-600' : 'border-green-600'} pt-8`}>
                        <div className="columns-1 sm:columns-2 gap-6 space-y-6">
                            {posts.map((post) => (
                                <article key={post.id} className="break-inside-avoid p-4 rounded-lg shadow-lg hover:scale-105">
                                    <div className="flex items-center gap-x-4 text-xs">
                                        <time className={`${darkMode ? 'text-neutral-500' : 'text-gray-500'}`}>
                                            {post.date}
                                        </time>
                                        <div className={`relative z-10 rounded-full ${darkMode ? 'bg-darkBgColor text-green-600 border border-neutral-700' : 'bg-bgColor border text-green-600'} px-3 py-1.5 font-medium`}>
                                            {post.category.title}
                                        </div>
                                    </div>
                                    <div className="group relative mt-4">
                                        <h3 className="text-lg font-semibold">
                                            <div>
                                                <span className="absolute inset-0"/>
                                                {post.title}
                                            </div>
                                        </h3>
                                        <p className={`mt-3 text-sm ${darkMode ? 'text-neutral-500' : 'text-gray-600'}`}>
                                            {post.description}
                                        </p>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>


                </div>
            </div>

        </div>

    );
}

export default ProfileGuides;