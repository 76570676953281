import LandingPage from "./components/LandingPage";
import {useRoutes} from "react-router-dom";
import Login from "./components/Login";
import {AuthProvider} from "./contexts/authContext";
import SignUp from "./components/SignUp";
import Home from "./components/Home";
import NewOrder from "./components/NewOrder";
import NewCustomer from "./components/NewCustomer";
import {DarkModeProvider} from "./contexts/darkModeContext";
import Customers from "./components/Customers";
import Orders from "./components/Orders";
import Profile from "./components/Profile";
import Catalog from "./components/Catalog";
import PlantOrders from "./components/PlantOrders";
import SalesTeam from "./components/SalesTeam";
import Guides from "./components/Guides";
import Archive from "./components/Archive";
import PlantCustomers from "./components/PlantCustomers";
import Dashboard from "./components/Dashboard";
import Tickets from "./components/Tickets";
import Proven from "./components/Proven";
import Pricing from "./components/Pricing";

function App() {
  const routesArray = [
    {
      path: "*",
      element: <LandingPage />,
    },
    {
      path: "/Login",
      element: <Login />,
    },
    {
      path: "/SignUp",
      element: <SignUp />,
    },
    {
      path: "/Home",
      element: <Home />,
    },
    {
      path: "/NewOrder",
      element: <NewOrder />,
    },
    {
      path: "/NewCustomer",
      element: <NewCustomer />,
    },
    {
      path: "/Customers",
      element: <Customers />,
    },
    {
      path: "/Orders",
      element: <Orders />,
    },
    {
      path: "/Profile",
      element: <Profile />,
    },
    {
      path: "/Catalog",
      element: <Catalog />,
    },
    {
      path: "/PlantOrders",
      element: <PlantOrders />,
    },
    {
      path: "/SalesTeam",
      element: <SalesTeam />,
    },
    {
      path: "/Guides",
      element: <Guides />,
    },
    {
      path: "/Archive",
      element: <Archive />,
    },
    {
      path: "/PlantCustomers",
      element: <PlantCustomers />,
    },
    {
      path: "/Dashboard",
      element: <Dashboard />,
    },
    {
      path: "/Tickets",
      element: <Tickets />,
    },
    {
      path: "/Proven",
      element: <Proven />,
    },
    {
      path: "/Pricing",
      element: <Pricing />,
    },
  ];

  const routesElement = useRoutes(routesArray);

  return (

      <DarkModeProvider>
        <AuthProvider>
          <div className="w-full h-screen flex flex-col">{routesElement}</div>
        </AuthProvider>
      </DarkModeProvider>

  );
}

export default App;
