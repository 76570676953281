import React, {useEffect, useState} from 'react';
import logo from "../main-logo.png";
import logoFull from "../PFC_Logo_Full.png"
import Header from "./Header";
import {
    BookOpenIcon,
    ChartBarSquareIcon,
    ClipboardDocumentListIcon,
    HomeIcon,
    UserGroupIcon,
    ArrowRightCircleIcon,
    ArrowLeftCircleIcon,
    UserPlusIcon,
    ArchiveBoxIcon,
    UsersIcon,
    WrenchScrewdriverIcon,
    ChatBubbleLeftEllipsisIcon,
    InboxIcon,
    InboxStackIcon,
    UserCircleIcon,
    ClipboardIcon,
    ListBulletIcon,
    QuestionMarkCircleIcon,
    ChartPieIcon,
    TicketIcon,
    CheckBadgeIcon, CurrencyDollarIcon, FolderOpenIcon
} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import {useDarkMode} from "../contexts/darkModeContext";
import {useAuth} from "../contexts/authContext";
import {db} from "../firebase/firebase";
import {List} from "@material-tailwind/react";
import {Button} from "@headlessui/react";
const SideBar = () => {
    // Get the current pathname

    // eslint-disable-next-line
    const [currentPath, setCurrentPath] = useState(window.location.pathname); // Initialize currentPath

    const { darkMode } = useDarkMode();

    const { currentUser } = useAuth();

    const [accountLevel, setAccountLevel] = useState("");

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setAccountLevel(userData.accountLevel);

                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);



    const [collapse, setCollapse] = useState(() => {
        const savedState = localStorage.getItem('collapseState');
        return savedState === null ? false : JSON.parse(savedState);
    });

    const toggleCollapse = () => {
        setCollapse(prevCollapse => {
            const newCollapse = !prevCollapse;
            localStorage.setItem('collapseState', JSON.stringify(newCollapse));
            return newCollapse;
        });
    };

    const accountLevelMapping = {
        D: "Developer",
        A: "Admin",
        CSR: "CSR",
        PFC: "Fulfillment",
        DIST: "Distributor",
        S: "Sales Rep"
    }

    return (

        <div className={`flex flex-col justify-between m-3 h-full ${collapse ? 'w-16' : 'w-72 mr-8'}`}>

            <Header/>

            {/*Collapse Button*/}
            <div className="flex flex-row z-20 left-1 mt-1.5 fixed">
                <Button
                    className="justify-center hover:animate-rubberBand items-end text-xl text-white font-bold hover:scale-110 bg-green-600 w-8 h-8 p-0.5 rounded-full"
                    onClick={toggleCollapse}>
                    {collapse ? <ArrowRightCircleIcon/> : <ArrowLeftCircleIcon/>}
                </Button>
            </div>

            {/*<div className={`bg-bgDarkGreen h-full fixed ${collapse ? 'w-16' : 'w-64'} rounded-md shadow-inner shadow-gray-750 max-h-[calc(100vh-5.5rem)] p-1`}>*/}

            {/*<div className={`overflow-y-auto bg-bgDarkGreen h-full fixed ${collapse ? 'w-16' : 'w-64'} rounded-md shadow-inner shadow-gray-750 max-h-[calc(100vh-5.5rem)] scrollbar-none p-1`}>*/}

            <div className={`${darkMode ? 'bg-darkMainColor' : 'bg-bgDarkGreen'} h-screen fixed ${collapse ? 'w-16' : 'w-64'} rounded-md shadow-inner shadow-gray-750 max-h-[calc(100vh-5.5rem)] p-1 transition-all duration-300 ease-in-out`}>

                {/*IMG*/}
                <div className="flex">
                    <div className="flex flex-col items-center justify-between mr-auto ml-auto">
                        <Link to="/">
                            {collapse ? <img
                                    alt=""
                                    src={logo}
                                    className="h-14 hover:rotate-2 hover:animate-heartbeat mt-9 w-auto"
                                />
                                :
                                <img
                                    alt=""
                                    src={logoFull}
                                    className={`h-14 hover:rotate-2 hover:animate-heartbeat ${darkMode ? '' : 'bg-white'} w-auto mt-6 rounded-lg p-0.5`}
                                />}
                        </Link>

                        <div className="text-xxs text-center text-green-600">{accountLevelMapping[accountLevel] || "- - - - -"}</div>

                    </div>

                </div>

                {/*Links*/}
                <div className="w-full h-full max-h-[calc(100vh-13rem)] pt-3 overflow-y-auto hide-scrollbar hover:transform flex flex-col">


                    <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-1 mb-3">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <div className={`${darkMode ? 'bg-darkMainColor' : 'bg-bgDarkGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider`}>Main</div>
                        </div>

                        <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                    </div>


                    <Link to="/Home" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Home' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                        <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                            <HomeIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                        </div>
                        <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Home</div>
                    </Link>

                    <Link to="/Dashboard" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Dashboard' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                        <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                            <ChartPieIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                        </div>
                        <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Orders Dashboard</div>
                    </Link>

                    <Link to="/Catalog" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Catalog' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                        <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                            <BookOpenIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                        </div>
                        <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Catalog</div>
                    </Link>

                    <Link to="/Proven" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Proven' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                        <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                            <CheckBadgeIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                        </div>
                        <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Proven Programs</div>
                    </Link>

                    <Link to="/Profile" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Profile' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                        <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                            <UserCircleIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                        </div>
                        <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Profile & Settings</div>
                    </Link>


                    {/*---------------------------------------------*/}


                    {/*{["D", "A", "S", "DIST"].includes(accountLevel) && (*/}
                    {/*    <>*/}
                    {/*    </>*/}
                    {/*)};*/}

                    {["D", "A", "S", "DIST"].includes(accountLevel) && (
                        <>

                            <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-3 mb-3">
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className={`${darkMode ? 'bg-darkMainColor' : 'bg-bgDarkGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider`}>Data</div>
                                </div>

                                <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                            </div>

                            <Link to="/Orders" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Orders' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                                <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                    <ClipboardDocumentListIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Current Orders</div>
                            </Link>

                            <Link to="/Archive" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Archive' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                                <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                    <ArchiveBoxIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Archived Orders</div>
                            </Link>

                            <Link to="/Customers" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Customers' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                                <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                    <FolderOpenIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Customer Management</div>
                            </Link>

                            <Link to="/SalesTeam" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/SalesTeam' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                                <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                    <UserGroupIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Sales Team</div>
                            </Link>

                        </>
                    )}


{/*---------------------------------------------*/}


                    <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-3 mb-3">
                        <div className="absolute inset-0 flex items-center justify-center">
                            <div className={`${darkMode ? 'bg-darkMainColor' : 'bg-bgDarkGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider`}>Tools</div>
                        </div>

                        <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                    </div>

                    <Link to="/Guides" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Guides' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                        <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                            <QuestionMarkCircleIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                        </div>
                        <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Help & Resources</div>
                    </Link>

                    {["D", "A", "S", "DIST"].includes(accountLevel) && (
                        <>
                             <Link to="" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                                <div className={`w-5 h-5 rounded bg-bgLightGreen bg-opacity-50 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                    <WrenchScrewdriverIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Program Builder</div>
                            </Link>
                        </>
                    )}

                    {["D", "A"].includes(accountLevel) && (
                        <>
                            <Link to="/Tickets" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Tickets' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                                <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                    <TicketIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Support Tickets</div>
                            </Link>

                            <Link to="/Pricing" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/Pricing' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                                <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                    <CurrencyDollarIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Product Pricing</div>
                            </Link>
                        </>
                    )}

                    <Link to="" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                        <div className={`w-5 h-5 rounded bg-bgLightGreen bg-opacity-50 drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                            <ChatBubbleLeftEllipsisIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                        </div>
                        <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Messaging</div>
                    </Link>


{/*---------------------------------------------*/}

                    {/*{["D", "A", "S", "DIST"].includes(accountLevel) && (*/}
                    {/*    <>*/}
                    {/*    </>*/}
                    {/*)};*/}


                    {["D", "A", "CSR"].includes(accountLevel) && (
                        <>
                            <div className="relative text-xs text-center text-bgColor ml-1.5 mr-1.5 mt-3 mb-3">
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className={`${darkMode ? 'bg-darkMainColor' : 'bg-bgDarkGreen'} ${collapse ? 'px-0.5' : 'px-2'} tracking-wider`}>Plant
                                    </div>
                                </div>

                                <div className="bg-bgLightGreen w-full rounded-full h-0.5"></div>
                            </div>

                            <Link to="/PlantOrders" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/PlantOrders' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                                <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                    <InboxIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Incoming Orders</div>
                            </Link>

                            <Link to="/PlantCustomers" className={`flex items-center p-2 rounded-md hover:scale-110 hover:opacity-80 hover:shadow-inner hover:shadow-gray-900 mr-auto ml-auto ${currentPath === '/PlantCustomers' ? 'bg-bgColor bg-opacity-10 shadow-inner shadow-gray-900' : 'bg-transparent'} ${collapse ? 'justify-center w-3/4' : 'w-11/12 mb-1'}`}>
                                <div className={`w-5 h-5 rounded bg-bgLightGreen drop-shadow shadow-2xl shadow-black p-4 flex items-center justify-center ${collapse ? '' : 'mr-2'}`}>
                                    <UserPlusIcon className={`text-white w-4 flex-none`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-xs line-clamp-1 ${collapse ? 'hidden' : ''}`}>Incoming Customers</div>
                            </Link>
                        </>
                    )}


                </div>


            </div>

        </div>
    );
};

export default SideBar;

