import JugSVG from "../../PFC_Jug.svg"
import DrumSVG from "../../PFC_Drum.svg"
import ToteSVG from "../../PFC_Tote.svg"
import Drum55SVG from "../../PFC_Drum_55.svg"

import logo from "../../main-logo.png";
import {Button, Field, Input, Label, Select, Switch} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {XMarkIcon} from "@heroicons/react/16/solid";
import {useDarkMode} from "../../contexts/darkModeContext";
import {isBoolean} from "pdfmake/src/helpers";
import {ArrowDownCircleIcon, ArrowRightIcon, ArrowUpCircleIcon} from "@heroicons/react/24/outline";

const ContractPrice = ({ index, onRemove, onAdd, product, priceType }) => {

    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState("");
    const [productCode, setProductCode] = useState("");
    const [total, setTotal] = useState((price * quantity).toFixed(2));
    const [actualTotal, setActualTotal] = useState(0);
    const [selectedVolume, setSelectedVolume] = useState(product.prices.length > 0 ? product.prices[0].um : ""); // Set to the first `um` in prices or an empty string if prices is empty
    const [name, setName] = useState("");
    const [warehouse, setWarehouse] = useState("MAIN");
    const [disabled, setDisabled] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [unitLabel, setUnitLabel] = useState("Quant");
    const [percentLabel, setPercentLabel] = useState("");

    const [percent, setPercent] = useState(false);

    const { darkMode } = useDarkMode();

    const [selectedImage, setSelectedImage] = useState(JugSVG); // Initial image state
    const [defaultImage, setDefaultImage] = useState(false);

    const handleQuantityChange = (e) => {
        const value = parseFloat(e.target.value) || 0; // Default to 0 if NaN
        setQuantity(value);
    };

    const getPriceBasedOnSelection = (prices, priceType, quantity) => {
        // Ensure prices is an array
        if (!Array.isArray(prices)) {
            console.error("Prices must be an array");
            return "Invalid data";
        }

        // Convert priceType to lowercase to handle case insensitivity
        const normalizedPriceType = priceType.toLowerCase();

        // Find the price object where 'um' matches the specified quantity
        const priceObject = prices.find((price) => price.um === quantity);

        if (priceObject && priceObject[normalizedPriceType]) {
            return priceObject[normalizedPriceType];
        }

        return "Price not available";
    };

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    // Handle the remove action with animation
    const handleRemove = () => {
        setIsRemoving(true); // Trigger the animation
        setTimeout(() => onRemove(index), 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    };

    useEffect(() => {
        console.log("Product:", product);
        console.log("Prices:", product ? product.prices : []);
        console.log("Price Type:", priceType);
        console.log("Selected Volume:", selectedVolume);

        setName(product.name);

        if (product && selectedVolume) {
            const prices = product.prices;
            const newPrice = getPriceBasedOnSelection(prices, priceType, selectedVolume);

            // Find the price object that matches the selected volume and priceType
            const priceObject = prices.find((price) => price.um === selectedVolume);

            if (priceObject) {
                setProductCode(priceObject.code);
                console.log("Selected Price Code:", priceObject.code); // Log the code of the selected price object
            }

            console.log("New Price:", newPrice); // Log the selected price

            setPrice(newPrice);
            // Update disabled state based on newPrice being 0, "0", or NaN
            const isDisabled = !newPrice || newPrice === 0 || newPrice === "0" || isNaN(newPrice);
            setDisabled(isDisabled);

            // eslint-disable-next-line default-case
            switch (percent){
                case true:
                    setPercentLabel("%");
                    setUnitLabel("Percent Off");
                    break;
                case false:
                    setPercentLabel("$");
                    setUnitLabel("Custom Price");
                    break;
            }

            switch (selectedVolume) {
                case "2.5":
                    break;
                case "30":
                    break;
                case "55":
                    break;
                case "275":
                    break;
                case "TR":
                    setQuantity(100);
                    break;
                case "GAL":
                    setQuantity(600);
                    break;
                default:
            }
        }
    }, [product, selectedVolume, discountAmount, percent]);

    const handleVolumeChange = (e) => {
        setSelectedVolume(e.target.value);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Automatically pass data back to the parent whenever any key state changes
    useEffect(() => {
        const productData = {
            price,
            name,
            quantity,
            productCode,
            total,
            actualTotal,
            selectedVolume,
            warehouse,
        };
        // onAdd(index, productData); // Automatically pass the product data to the parent
    }, [price, name, quantity, productCode, total, selectedVolume, warehouse, actualTotal]);

    const umMapping = {
        ACR: "Acre",
        B12: "BAG 12#",
        B25: "BAG 25#",
        B30: "BAG 30#",
        B40: "BAG 40#",
        B5: "BAG 5#",
        B50: "BAG 50#",
        B51: "BAG 51#",
        BAG: "BAG (Unspecified Weight)",
        "2.5": "CASE (2x2.5)",
        CS4: "CASE (4X1)",
        "30": "DRUM 30-GAL",
        "55": "DRUM 55-GAL",
        DRU: "DRUM (Unspecified Volume)",
        EAC: "EACH",
        GAL: "GALLON",
        TR: "TOTE REFILL",
        LB: "POUND",
        OZ: "Ounce - Av",
        PAI: "PAIL 5-GAL",
        SAC: "Super Sack (B2000/B1000)",
        TON: "TON (2,000#)",
        "275": "TOTE 275-GAL",
    };

    return (
        <div className={`w-full font-sans flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor' : ''} mb-1.5 p-1 pb-2 transition-transform duration-500 ease-in-out ${isRemoving ? 'transform scale-75 animate-fadeOut' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*PRODCUT NAME*/}
            <div className="flex flex-row items-center w-1/3 mr-3">
                {/*Name and Product Code*/}
                <div className="flex flex-col mt-auto mb-auto w-2/3">
                    {/*Scrollable Name by Hover*/}
                    <div ref={(container) => {
                        if (container) {
                            const textElement = container.querySelector('.scrollable-text');
                            if (textElement) {
                                container.classList.toggle(
                                    'hover-enabled',
                                    textElement.scrollWidth > container.offsetWidth
                                );
                            }
                        }
                    }}
                         className={`relative overflow-hidden ${
                             darkMode ? 'text-white' : 'text-black'
                         } text-sm font-medium w-full`}
                    >
                        <div className="scrollable-text inline-block whitespace-nowrap transition-transform duration-1000 ease-linear"
                             style={{
                                 transform: 'translateX(0)',
                             }}
                             onMouseEnter={(e) => {
                                 const container = e.currentTarget.parentElement;
                                 if (container.classList.contains('hover-enabled')) {
                                     e.currentTarget.style.transform = 'translateX(-50%)';
                                 }
                             }}
                             onMouseLeave={(e) => {
                                 e.currentTarget.style.transform = 'translateX(0)';
                             }}
                        >
                            {name}
                        </div>
                    </div>
                    <div className={`ml-3 text-xxs ${darkMode ? 'text-borderColor' : 'text-borderColor'}`}>{productCode}</div>
                </div>

                {/*Early Order*/}
                <Field className="flex flex-col items-center w-1/3">
                    <Switch
                        checked={percent}
                        onChange={setPercent}
                        className="group relative inline-flex h-5 w-10 shrink-0 cursor-pointer items-center justify-center rounded-full bg-transparent"
                    >
                        <span className="sr-only">Use setting</span>
                        <span aria-hidden="true" className="pointer-events-none absolute size-full rounded-md" />
                        <span
                            aria-hidden="true"
                            className={`pointer-events-none absolute mx-auto h-4 w-9 rounded-full ${darkMode ? 'bg-neutral-700' : 'bg-gray-200'} transition-colors duration-200 ease-in-out group-data-[checked]:bg-green-600`}
                        />
                        <span
                            aria-hidden="true"
                            className="pointer-events-none absolute left-0 inline-block size-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out group-data-[checked]:translate-x-5"
                        />
                    </Switch>
                    <Label as="span" className="mt-1 text-xxs">

                        {percent ? (
                            <span className="font-medium">Percentage Off</span>
                        ) : (
                            <span className="font-medium">Custom Price</span>
                        )}

                    </Label>
                </Field>
            </div>

            {/*PRODUCT PRICING/QUANT*/}
            <div className="flex flex-row w-2/3">

                {/*PRICE*/}
                <div className={`relative w-1/2 p-1.5`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} relative flex flex-row px-4 rounded-lg h-full items-center justify-center`}>
                        <div className="text-borderColor text-md mr-1">$</div>
                        <div className={`${darkMode ? 'text-white' : 'text-black'} rounded-md text-sm p-1.5 w-full tracking-wider line-clamp-1`}>
                            {price && !isNaN(price) && price !== 0 ? numberWithCommas(parseFloat(price).toFixed(2)) : "No Price Found"}
                        </div>
                        <div className="text-borderColor text-sm">USD</div>
                    </div>
                </div>

                <div className="flex flex-row px-1 h-full items-center justify-center">
                    <ArrowRightIcon className={`text-borderColor w-4 stroke-2`}/>
                </div>

                {/*QUANTITY*/}
                <div className={`relative w-1/2 p-1.5 mr-3`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} flex flex-row px-4 rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                        {/*// When quantity changes, trigger the onChange for recalculation*/}
                        <div className="text-borderColor text-sm">{percentLabel}</div>

                        <Input
                            className={`rounded-md bg-transparent text-sm focus:outline-none p-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                            type="string"
                            placeholder={unitLabel}
                            value={quantity}
                            // disabled={disabled}
                            onChange={handleQuantityChange}
                        />

                        {/*// When price changes (in your select or other logic), ensure price updates are sent to parent.*/}
                    </div>
                </div>

                {/*U/M*/}
                <div className={`relative w-1/4 p-1.5`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} flex flex-row rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                        <Select
                            className={`p-1.5 rounded-md bg-transparent text-xs focus:outline-none ${darkMode ? 'text-white' : 'text-black'} w-full`}
                            onChange={handleVolumeChange}
                            value={selectedVolume}
                        >
                            {/* Map over prices array to generate options */}
                            {product.prices.map((price, index) => {
                                const label = umMapping[price.um]; // Get label from mapping object
                                return label ? (
                                    <option key={index} value={price.um}>
                                        {label}
                                    </option>
                                ) : null; // Exclude options with no matching label
                            })}

                        </Select>
                    </div>
                </div>

            </div>

            {/*PRODUCT TOTAL*/}
            <div className="flex flex-row w-fit justify-end align-middle items-center">

                <div className="ml-auto w-full flex flex-row justify-end">

                    <Button className="rounded-full ml-3 h-8 w-8 items-center justify-center align-middle"
                            onClick={handleRemove}>
                        <XMarkIcon className="h-6 text-borderColor hover:text-red-600 hover:animate-pulse hover:scale-110 m-auto"/>
                    </Button>
                </div>


            </div>

        </div>
    )
}

export default ContractPrice;
