import JugSVG from "../../PFC_Jug.svg"
import DrumSVG from "../../PFC_Drum.svg"
import ToteSVG from "../../PFC_Tote.svg"
import Drum55SVG from "../../PFC_Drum_55.svg"

import logo from "../../main-logo.png";
import {Button, Input, Select} from "@headlessui/react";
import React, {useEffect, useState} from "react";
import {XMarkIcon} from "@heroicons/react/16/solid";
import {useDarkMode} from "../../contexts/darkModeContext";
import {isBoolean} from "pdfmake/src/helpers";

const ProductInput = ({ index, onRemove, onAdd, onChange, product, priceType, discount }) => {

    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState("");
    const [productCode, setProductCode] = useState("");
    const [total, setTotal] = useState((price * quantity).toFixed(2));
    const [actualTotal, setActualTotal] = useState(0);
    const [selectedVolume, setSelectedVolume] = useState(product.prices.length > 0 ? product.prices[0].um : ""); // Set to the first `um` in prices or an empty string if prices is empty
    const [name, setName] = useState("");
    const [warehouse, setWarehouse] = useState("MAIN");
    const [disabled, setDisabled] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [unitLabel, setUnitLabel] = useState("Quant");

    const { darkMode } = useDarkMode();

    const [selectedImage, setSelectedImage] = useState(JugSVG); // Initial image state
    const [defaultImage, setDefaultImage] = useState(false);

    const isDiscountApplied = selectedVolume !== 'GAL' && discountAmount && discountAmount !== 0;

    useEffect(() => {
        // Calculate the total without any discount (always needed)
        const actualTotal = (price * quantity).toFixed(2);
        setActualTotal(actualTotal);
        setDiscountAmount(discount);

        // Determine if a discount should be applied
        let calculatedTotal;

        if (selectedVolume === 'GAL') {
            // No discount for GAL
            calculatedTotal = actualTotal;
            setDiscountAmount(0);
        } else {

            // Apply discount if present
            calculatedTotal = isDiscountApplied
                ? ((price * quantity) * (1 - discountAmount / 100)).toFixed(2) // Discount applied
                : actualTotal; // No discount applied
        }

        // Update the total and propagate changes
        setTotal(calculatedTotal);
        onChange(index, actualTotal, total); // Pass the total back to the parent component

        console.log('Price:', price, 'Quantity:', quantity, 'Selected Volume:', selectedVolume, 'Discount Amount:', discountAmount);
        console.log('Actual Total:', actualTotal, 'Calculated Total:', calculatedTotal, 'Is Discount Applied:', isDiscountApplied);

    }, [price, product, quantity, priceType, selectedVolume, discountAmount, index, onChange, isDiscountApplied, discount]);

    const handleQuantityChange = (e) => {
        const value = parseFloat(e.target.value) || 0; // Default to 0 if NaN
        setQuantity(value);
    };

    const handleDiscountChange = (e) => {
        const value = parseFloat(e.target.value) || 0; // Default to 0 if NaN
        setDiscountAmount(value);
    };

    const getPriceBasedOnSelection = (prices, priceType, quantity) => {
        // Ensure prices is an array
        if (!Array.isArray(prices)) {
            console.error("Prices must be an array");
            return "Invalid data";
        }

        // Convert priceType to lowercase to handle case insensitivity
        const normalizedPriceType = priceType.toLowerCase();

        // Find the price object where 'um' matches the specified quantity
        const priceObject = prices.find((price) => price.um === quantity);

        if (priceObject && priceObject[normalizedPriceType]) {
            return priceObject[normalizedPriceType];
        }

        return "Price not available";
    };


    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    // Handle the remove action with animation
    const handleRemove = () => {
        setIsRemoving(true); // Trigger the animation
        setTimeout(() => onRemove(index), 500); // Wait for the animation to complete before removing (adjust timing to match animation)
    };

    useEffect(() => {
        console.log("Product:", product);
        console.log("Prices:", product ? product.prices : []);
        console.log("Price Type:", priceType);
        console.log("Selected Volume:", selectedVolume);

        setName(product.name);

        if (product && priceType && selectedVolume) {
            const prices = product.prices;
            const newPrice = getPriceBasedOnSelection(prices, priceType, selectedVolume);

            // Find the price object that matches the selected volume and priceType
            const priceObject = prices.find((price) => price.um === selectedVolume);

            if (priceObject) {
                setProductCode(priceObject.code);
                console.log("Selected Price Code:", priceObject.code); // Log the code of the selected price object
            }

            console.log("New Price:", newPrice); // Log the selected price

            setPrice(newPrice);
            // Update disabled state based on newPrice being 0, "0", or NaN
            const isDisabled = !newPrice || newPrice === 0 || newPrice === "0" || isNaN(newPrice);
            setDisabled(isDisabled);

            switch (selectedVolume) {
                case "2.5":
                    setSelectedImage(JugSVG);
                    setDefaultImage(false);
                    setUnitLabel("Quant");
                    break;
                case "30":
                    setSelectedImage(DrumSVG);
                    setDefaultImage(false);
                    setUnitLabel("Quant");
                    break;
                case "55":
                    setSelectedImage(Drum55SVG);
                    setDefaultImage(false);
                    setUnitLabel("Quant");
                    break;
                case "275":
                    setSelectedImage(ToteSVG);
                    setDefaultImage(false);
                    setUnitLabel("Quant");
                    break;
                case "TR":
                    setSelectedImage(logo);
                    setQuantity(100);
                    setDefaultImage(true);
                    setUnitLabel("gal");
                    break;
                case "GAL":
                    setSelectedImage(logo);
                    setQuantity(600);
                    setDefaultImage(true);
                    setUnitLabel("gal");
                    break;
                default:
                    setSelectedImage(logo);
                    setUnitLabel("Quant");
                    setDefaultImage(true);
            }
        }
    }, [product, priceType, selectedVolume, discountAmount, discount]);

    const handleVolumeChange = (e) => {
        setSelectedVolume(e.target.value);
    };

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    // Automatically pass data back to the parent whenever any key state changes
    useEffect(() => {
        const productData = {
            price,
            name,
            quantity,
            productCode,
            total,
            actualTotal,
            selectedVolume,
            warehouse,
            discountAmount,
        };
        onAdd(index, productData); // Automatically pass the product data to the parent
    }, [price, name, quantity, productCode, total, selectedVolume, warehouse, actualTotal, discountAmount, discount]);

    const umMapping = {
        ACR: "Acre",
        B12: "BAG 12#",
        B25: "BAG 25#",
        B30: "BAG 30#",
        B40: "BAG 40#",
        B5: "BAG 5#",
        B50: "BAG 50#",
        B51: "BAG 51#",
        BAG: "BAG (Unspecified Weight)",
        "2.5": "CASE (2x2.5)",
        CS4: "CASE (4X1)",
        "30": "DRUM 30-GAL",
        "55": "DRUM 55-GAL",
        DRU: "DRUM (Unspecified Volume)",
        EAC: "EACH",
        GAL: "GALLON",
        TR: "TOTE REFILL",
        LB: "POUND",
        OZ: "Ounce - Av",
        PAI: "PAIL 5-GAL",
        SAC: "Super Sack (B2000/B1000)",
        TON: "TON (2,000#)",
        "275": "TOTE 275-GAL",
    };

    return (
        <div className={`w-full font-sans flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor' : ''} mb-1.5 p-1 pb-2 transition-transform duration-500 ease-in-out ${isRemoving ? 'transform scale-75 animate-fadeOut' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'}`}>

            {/*PRODCUT NAME*/}
            <div className="flex flex-row w-1/4 mr-3">
                <div className="flex flex-col mt-auto mb-auto w-1/6">
                    {/*<svg xmlns={selectedImage} className={`h-8`}/>*/}

                    <div className={`w-fit mr-auto ml-auto p-1.5 rounded-lg ${defaultImage ? '' : 'bg-white'}`}>
                        <img src={selectedImage} className={`h-8 w-fit hover:scale-105`} alt="Jug"/>
                    </div>

                </div>

                {/*Name and Product Code*/}
                <div className="flex flex-col mt-auto mb-auto w-5/6">
                    {/*Scrollable Name by Hover*/}
                    <div ref={(container) => {
                            if (container) {
                                const textElement = container.querySelector('.scrollable-text');
                                if (textElement) {
                                    container.classList.toggle(
                                        'hover-enabled',
                                        textElement.scrollWidth > container.offsetWidth
                                    );
                                }
                            }
                        }}
                        className={`relative overflow-hidden ${
                            darkMode ? 'text-white' : 'text-black'
                        } text-sm font-medium w-full`}
                    >
                        <div className="scrollable-text inline-block whitespace-nowrap transition-transform duration-1000 ease-linear"
                            style={{
                                transform: 'translateX(0)',
                            }}
                            onMouseEnter={(e) => {
                                const container = e.currentTarget.parentElement;
                                if (container.classList.contains('hover-enabled')) {
                                    e.currentTarget.style.transform = 'translateX(-50%)';
                                }
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.transform = 'translateX(0)';
                            }}
                        >
                            {name}
                        </div>
                    </div>
                    <div className={`ml-3 text-xxs ${darkMode ? 'text-borderColor' : 'text-borderColor'}`}>{productCode}</div>
                </div>
            </div>

            {/*PRODUCT PRICING/QUANT*/}
            <div className="flex flex-row w-1/2 mr-3">

                {/*PRICE*/}
                <div className={`relative w-1/2 p-1.5`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ${disabled ? 'border-2 border-red-600 rounded-lg' : ''} relative flex flex-row px-4 rounded-lg h-full items-center justify-center`}>
                        <div className="text-borderColor text-md mr-1">$</div>
                        <div className={`${darkMode ? 'text-white' : 'text-black'} rounded-md text-sm p-1.5 w-full tracking-wider line-clamp-1`}>
                            {price && !isNaN(price) && price !== 0 ? numberWithCommas(parseFloat(price).toFixed(2)) : "No Price Found"}
                        </div>
                        <div className="text-borderColor text-sm ml-3">USD</div>
                    </div>
                    <div className={`absolute top-0 right-0`}>
                        <div className={`flex-none rounded-full p-1 ${disabled ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand-delay'}`}>
                          <div className={`size-2 rounded-full ${disabled ? 'bg-red-400' : 'bg-emerald-500'}`} />
                        </div>
                    </div>
                </div>

                <div className="flex flex-row px-4 h-full items-center justify-center">
                    <div className="text-borderColor text-xs">X</div>
                </div>

                {/*QUANTITY*/}
                <div className={`relative w-1/2 p-1.5 mr-3`}>
                    <div
                        className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ${disabled ? 'border-2 border-red-600 rounded-lg' : ''} flex flex-row px-4 rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                        {/*// When quantity changes, trigger the onChange for recalculation*/}
                        <Input
                            className={`rounded-md bg-transparent text-sm focus:outline-none p-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                            type="string"
                            placeholder="Enter Quantity"
                            value={quantity}
                            // disabled={disabled}
                            onChange={handleQuantityChange}
                        />

                        <div className="text-borderColor text-sm ml-3">{unitLabel}</div>


                        {/*// When price changes (in your select or other logic), ensure price updates are sent to parent.*/}
                    </div>
                    <div className={`absolute top-0 right-0`}>
                    <div className={`flex-none rounded-full p-1 ${disabled ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand-delay'}`}>
                          <div className={`size-2 rounded-full ${disabled ? 'bg-red-400' : 'bg-emerald-500'}`} />
                        </div>
                    </div>
                </div>

                {/*U/M*/}
                <div className={`relative w-1/4 p-1.5 mr-3`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ${disabled ? 'border-2 border-red-600 rounded-lg' : ''} flex flex-row rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                        <Select
                            className={`p-1.5 rounded-md bg-transparent text-xs focus:outline-none ${darkMode ? 'text-white' : 'text-black'} w-full`}
                            onChange={handleVolumeChange}
                            value={selectedVolume}
                        >
                            {/* Map over prices array to generate options */}
                            {product.prices.map((price, index) => {
                                const label = umMapping[price.um]; // Get label from mapping object
                                return label ? (
                                    <option key={index} value={price.um}>
                                        {label}
                                    </option>
                                ) : null; // Exclude options with no matching label
                            })}

                        </Select>
                    </div>
                    <div className={`absolute top-0 right-0`}>
                        <div className={`flex-none rounded-full p-1 ${disabled ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand-delay'}`}>
                          <div className={`size-2 rounded-full ${disabled ? 'bg-red-400' : 'bg-emerald-500'}`} />
                        </div>
                    </div>
                </div>

                {/*Warehouse*/}
                <div className={`relative w-1/4 p-1.5`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ${disabled ? 'border-2 border-red-600 rounded-lg' : ''} flex flex-row rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                        <Select
                            value={warehouse} // Will display the code (e.g., JCNJ) after selection
                            onChange={(e) => setWarehouse(e.target.value)} // Store the selected code in state
                            className={`text-xs p-1.5 rounded-md bg-transparent focus:outline-none ${darkMode ? 'text-white' : 'text-black'} w-full`}
                            // disabled={disabled}
                        >
                            <option value="MAIN">MAIN</option>
                            <option value="ADL">ADL</option>
                            <option value="CFNJ">CFNJ</option>
                            <option value="CNCT">CNCT</option>
                            <option value="FLFL">FLFL</option>
                            <option value="FNFL">FNFL</option>
                            <option value="FSFL">FSFL</option>
                            <option value="FWFL">FWFL</option>
                            <option value="GCPA">GCPA</option>
                            <option value="JCNJ">JCNJ</option>
                            <option value="MFNJ">MFNJ</option>
                            <option value="NJNJ">NJNJ</option>
                            <option value="PFSY">PFSY</option>
                            <option value="PGPA">PGPA</option>
                            <option value="RSNJ">RSNJ</option>
                            <option value="SJNJ">SJNJ</option>
                            <option value="WINY">WINY</option>
                        </Select>
                    </div>
                    <div className={`absolute top-0 right-0`}>
                        <div className={`flex-none rounded-full p-1 ${disabled ? 'bg-red-500/40 animate-heartbeat' : 'bg-emerald-500/30 animate-rubberBand-delay'}`}>
                          <div className={`size-2 rounded-full ${disabled ? 'bg-red-400' : 'bg-emerald-500'}`} />
                        </div>
                    </div>

                </div>


            </div>

            {/*PRODUCT TOTAL*/}
            <div className="flex flex-row w-1/4 mr-3 justify-end align-middle items-center">

                {/*Discount*/}
                <div className={`relative w-1/4 p-1.5 h-full`}>
                    <div className={`${darkMode ? 'bg-darkBgColor' : 'border bg-neutral-50'} ${disabled ? 'border-2 border-red-600 rounded-lg' : ''} ${isDiscountApplied ? '' : 'border-none'} flex px-1 flex-row rounded-lg h-full items-center justify-center hover:scale-y-105`}>
                        <div className="text-borderColor text-xs font-bold">%</div>

                        <Input
                            className={`rounded-md bg-transparent text-xs focus:outline-none ml-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                            type="number"
                            placeholder="DISC"
                            value={discountAmount}
                            disabled={true}
                            // disabled={!isDiscountApplied}
                            onChange={handleDiscountChange}
                        />
                    </div>
                    <div className={`absolute top-0 right-0`}>
                        {isBoolean(isDiscountApplied) && (
                            <div className={`flex-none rounded-full p-1 ${disabled ? 'bg-red-500/40 animate-heartbeat' : 'bg-blue-500/30 animate-rubberBand-delay'}`}>
                                <div className={`size-2 rounded-full ${disabled ? 'bg-red-400' : 'bg-blue-500'}`}/>
                            </div>
                        )}
                    </div>
                </div>

                <div className="ml-auto w-full flex flex-row justify-end">

                    <div
                        className={`flex flex-row mt-auto mb-auto align-middle justify-end items-center ${darkMode ? 'text-white' : 'text-black'}`}>
                        <div className="text-md mr-3 font-light">$</div>
                        <div className={`relative p-1.5`}>

                            <div className="text-md mr-3 font-light tracking-wider">{numberWithCommas(total)}</div>

                            <div className={`absolute top-0 right-1`}>
                                <div className={`flex-none rounded-full p-1 ${isDiscountApplied ? 'bg-blue-500/30 animate-heartbeat' : ''}`}>
                                  <div className={`size-2 rounded-full ${isDiscountApplied ? 'bg-blue-500' : ''}`} />
                                </div>
                            </div>


                        </div>
                        <div className="text-borderColor text-sm">USD</div>
                    </div>

                    <Button className="rounded-full ml-3 h-8 w-8 items-center justify-center align-middle"
                        onClick={handleRemove}>
                        <XMarkIcon className="h-6 text-borderColor hover:text-red-600 hover:animate-pulse hover:scale-110 m-auto"/>
                    </Button>
                </div>


            </div>

        </div>
    )
}

export default ProductInput;
