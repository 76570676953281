import React, {useEffect, useState} from 'react';
import SideBar from "./SideBar";
import {Button, Input, Select} from "@headlessui/react";
import {
    ArrowPathIcon, EyeSlashIcon,
    MagnifyingGlassIcon,
    PlusCircleIcon,
    TrashIcon, XCircleIcon
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import CustomerCard from "./Customers/CustomerCard";
import {useDarkMode} from "../contexts/darkModeContext";


const Customers = () => {
    const { currentUser } = useAuth();

    const [status, setStatus] = useState("A");
    const [existing, setExisting] = useState(false);

    const { darkMode } = useDarkMode();

    const [selectedIndustry, setSelectedIndustry] = useState("");

    const [customers, setCustomers] = useState([]);
    const [customersPending, setCustomersPending] = useState([]);

    const [filteredPendingCustomers, setFilteredPendingCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");

    const [updating, setUpdating] = useState(false);

    // Handle dropdown change
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);

                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        const fetchCustomersPending = async () => {
            if (currentUser) {
                try {
                    const storedPendingData = localStorage.getItem(`pendingCustomers_${currentUser.uid}`);
                    if (storedPendingData) {
                        const pendingData = JSON.parse(storedPendingData);
                        setCustomersPending(pendingData);
                        setFilteredPendingCustomers(pendingData);
                    } else {
                        const pendingCustomers = db.collection("Customers").doc(currentUser.uid).collection("pending");
                        const snapshot = await pendingCustomers.get();


                        if (!snapshot.empty) {
                            const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setCustomersPending(pendingData);
                            setFilteredPendingCustomers(pendingData);
                            localStorage.setItem(`pendingCustomers_${currentUser.uid}`, JSON.stringify(pendingData));
                        } else {
                            setCustomersPending([]);
                            setFilteredPendingCustomers([]);
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }

        const fetchCustomersActive = async () => {
            if (currentUser) {
                try {
                    const storedActiveData = localStorage.getItem(`activeCustomers_${currentUser.uid}`);
                    if (storedActiveData) {
                        const customersData = JSON.parse(storedActiveData);
                        setCustomers(customersData);
                        setFilteredCustomers(customersData);
                    } else {
                        const activeCustomers = db.collection("Customers").doc(currentUser.uid).collection("clients");
                        const snapshot = await activeCustomers.get();

                        if (!snapshot.empty) {
                            const activeData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setCustomers(activeData);
                            setFilteredCustomers(activeCustomers);
                            localStorage.setItem(`activeCustomers_${currentUser.uid}`, JSON.stringify(activeData));
                        } else {
                            setCustomers([]);
                            setFilteredCustomers([]);
                            console.log('Customer document not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching customers data:', error);
                }
            }
        }

        fetchCustomersPending();
        fetchCustomersActive();
        fetchUserName();
    }, [currentUser]);


    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Pending
        try {
            const pendingCustomers = db.collection("Customers").doc(currentUser.uid).collection("pending");
            const snapshot = await pendingCustomers.get();

            if (!snapshot.empty) {
                const pendingData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCustomersPending(pendingData);
                setFilteredPendingCustomers(pendingData);
                localStorage.setItem(`pendingCustomers_${currentUser.uid}`, JSON.stringify(pendingData));
            } else {
                setCustomersPending([]);
                setFilteredPendingCustomers([]);
                console.log('Customer document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }

        // Active
        try {
            const customers = db.collection("Customers").doc(currentUser.uid).collection("clients");
            const snapshot = await customers.get();

            if (!snapshot.empty) {
                const activeData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCustomers(activeData);
                setFilteredCustomers(activeData);
                localStorage.setItem(`activeCustomers_${currentUser.uid}`, JSON.stringify(activeData));
            } else {
                setCustomers([]);
                setFilteredCustomers([]);
                console.log('Customer document not found');
            }

        } catch (error) {
            console.error("Error updating customers", error);
        }
        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        alert(`Customers Updated in ${timeElapsed} secs`);
    };


    const handlePendingSearch = (query) => {
        setSearchQuery(query);
        const filtered = customersPending.filter((customer) =>
            customer.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredPendingCustomers(filtered);
    }

    const handleActiveSearch = (query) => {
        setSearchQuery(query);
        const filtered = customers.filter((customer) =>
            customer.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredCustomers(filtered);
    }


    // Function to toggle visibility
    const toggleVisibility = () => {
        setExisting(!existing);
    };


    const filterCustomersByIndustry = (industry) => {
        const filtered = customers.filter(customer => industry === '' || customer.industry === industry);
        setFilteredCustomers(filtered);
    };

    const filterPendingCustomersByIndustry = (industry) => {
        const filtered = customersPending.filter(customer => industry === '' || customer.industry === industry);
        setFilteredPendingCustomers(filtered);
    };

    const filterCustomersByPricing = (pricing) => {
        const filtered = customers.filter(customer => pricing === '' || customer.priceType === pricing);
        setFilteredCustomers(filtered);
    };

    const filterPendingCustomersByPricing = (pricing) => {
        const filtered = customersPending.filter(customer => pricing === '' || customer.priceType === pricing);
        setFilteredPendingCustomers(filtered);
    };



    const filtering = (e) => {
        if(status === "A"){
            filterCustomersByIndustry(e.target.value);
        }
        if (status === "P"){
            filterPendingCustomersByIndustry(e.target.value);
        }
    }

    const filteringPrice = (e) => {
        if(status === "A"){
            filterCustomersByPricing(e.target.value);
        }
        if (status === "P"){
            filterPendingCustomersByPricing(e.target.value);
        }
    }

    const [locations, setLocations] = useState([]);

    const handleAddLocation = () => {
        setLocations([...locations, { nickName: '', contactName: '', contactPhone: '' }]);
    };

    const handleRemoveLocation = (index) => {
        const newLocations = locations.filter((_, i) => i !== index);
        setLocations(newLocations);
    };

    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        const newLocations = [...locations];
        newLocations[index][name] = value;
        setLocations(newLocations);
    };


    const [customerCode, setCustomerCode] = useState("");
    const [name, setName] = useState("");
    const [priceType, setPriceType] = useState("");
    const [industry, setIndustry] = useState("");
    const [customerType, setCustomerType] = useState("");

    const [customerEmail, setCustomerEmail] = useState("");
    const [customerPhone, setCustomerPhone] = useState("");

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [fullname, setFullname] = useState("");
    const [salesCode, setSalesCode] = useState("");
    const [salesEmail, setSalesEmail] = useState("");


    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setLname(userData.lname);
                        setSalesCode(userData.salesCode);
                        setSalesEmail(userData.email);

                        setFullname(userData.fname + " " + userData.lname);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setLname(userData.lname);
                            setSalesCode(userData.salesCode);
                            setSalesEmail(userData.email);

                            setFullname(userData.fname + " " + userData.lname);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);


    const onSubmitMain = async (e) => {
        e.preventDefault();

        const collectionRef = db.collection("Customers").doc(currentUser.uid).collection("clients");

        const parsedFormData = {

            // Sales Info
            customerCode: customerCode,
            name: name,
            priceType: priceType,
            industry: industry,
            customerType: parseFloat(customerType),
            contactPhone: customerPhone,
            contactEmail: customerEmail,

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            salesEmail: salesEmail,

            status: "active",

            existing: true,

            ShippingAddress: locations,
        };

        try {
            await collectionRef.add(parsedFormData);
            // handleNextTab();
            alert(`Customer Added to the Cloud`); // Optional: alert for user feedback

            setCustomerCode("");
            setName("");
            setPriceType("");
            setIndustry("");

            setLocations([]);

            onUpdate();

        } catch (error) {
            console.error('Error registering client:', error.message);
            // setIsRegistered(false);
        }
    };

    const [count, setCount] = useState(0);

    useEffect(() => {

        if (status === "A"){
            setCount(customers.length);
        }
        if (status === "P"){
            setCount(customersPending.length);
        }


    }, [status, customers, customersPending]);


    // const downloadAsExcel = () => {
    //     const columnHeaders = ['Sales Code', 'Sales Rep', 'Sales Rep Email', 'Customer Code', 'Customer', 'Status', 'Industry'];
    //     const worksheetData = [columnHeaders, ...customers.map(cust => [cust.salesCode, cust.salesRep, cust.salesEmail, cust.customerCode, cust.customerName, cust.status, cust.industry])];
    //     const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
    //     const workbook = xlsxUtils.book_new();
    //     xlsxUtils.book_append_sheet(workbook, worksheet, 'Active Orders');
    //     const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
    //     const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //     saveAs(excelBlob, 'ActiveCustomers.xlsx');
    // };
    //
    // const downloadAsExcelPending = () => {
    //     const columnHeaders = ['Sales Code', 'Sales Rep', 'Sales Rep Email', 'Customer Code', 'Customer', 'Status', 'Industry'];
    //     const worksheetData = [columnHeaders, ...customersPending.map(cust => [cust.salesCode, cust.salesRep, cust.salesEmail, cust.customerCode, cust.customerName, cust.status, cust.industry])];
    //     const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);
    //     const workbook = xlsxUtils.book_new();
    //     xlsxUtils.book_append_sheet(workbook, worksheet, 'Active Orders');
    //     const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
    //     const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    //     saveAs(excelBlob, 'PendingCustomers.xlsx');
    // };
    //
    // const downloadMapping = () => {
    //     if(status === "A"){
    //         downloadAsExcel();
    //     }
    //     if (status === "P"){
    //         downloadAsExcelPending();
    //     }
    // }


    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-screen overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>

            <div className="animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] mt-3 mb-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">
                    <div className={`text-2xl w-1/6 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>Customers
                        {/*Buttons Delete*/}
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                            <Button className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                onClick={onUpdate}
                            >
                                <ArrowPathIcon className="h-6 text-borderColor m-auto"/>
                            </Button>
                        </div>
                    </div>

                    {/**/}
                    <div className={`text-xl mr-1.5 ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} w-full flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Active:</div>
                            <div className="text-sm rounded-md bg-orderColor bg-opacity-20 border-2 border-orderColor px-2 line-clamp-1">{customers.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Pending:</div>
                            <div className="text-sm rounded-md bg-pendingColor bg-opacity-20 border-2 border-pendingColor px-2 line-clamp-1">{customersPending.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Filtered:</div>
                            {status === "A" && (
                                <div>

                                    <div className="text-sm rounded-md border px-2 line-clamp-1">{filteredCustomers.length}</div>

                                </div>
                            )}

                            {status === "P" && (
                                <div>

                                    <div className="text-sm rounded-md border px-2 line-clamp-1">{filteredPendingCustomers.length}</div>

                                </div>
                            )}
                        </div>

                    </div>

                    <div className="w-1/6 text-black ml-1.5">
                        <Button className="bg-bgLightGreen h-full w-full hover:scale-105 hover:bg-opacity-80 rounded-md py-1.5 px-6 text-white text-sm mr-3 flex flex-row align-middle justify-center items-center"
                            onClick={toggleVisibility}
                        >
                            <PlusCircleIcon className="w-6"/>
                            <div className="w-full line-clamp-1">Existing Customer</div>
                        </Button>
                    </div>


                </div>

                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>

                    {/*Search Bar*/}
                    <div className="w-1/2 mr-1.5 h-fit text-sm">
                        {/*<div className="text-sm mb-1">Customer</div>*/}

                        <div className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>


                            {status === "A" && (
                                <div className="w-full">

                                <Input
                                        className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                        placeholder="Search Active Customers by Name"
                                        value={searchQuery}
                                        onChange={(e) => handleActiveSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                            {status === "P" && (
                                <div className="w-full">

                                    <Input
                                        className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                        placeholder="Search Pending Customers by Name"
                                        value={searchQuery}
                                        onChange={(e) => handlePendingSearch(e.target.value)}
                                        // onFocus={() => setShowProducts(true)}
                                        // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                    />

                                </div>
                            )}

                        </div>

                    </div>

                    {/*Status*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Status</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                value={status}
                                onChange={handleStatusChange}
                            >
                                <option value="A">Active</option>
                                <option value="P">Pending</option>
                            </Select>
                        </div>

                    </div>

                    {/*Industry*/}
                    <div className="w-1/6 mr-1.5 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Industry</div>*/}

                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                // onChange={(e) => filterCustomersByIndustry(e.target.value)}
                                    onChange={filtering}
                            >
                                <option value="">Select Industry...</option>
                                <option value="BOTANICAL">Botanical Garden</option>
                                <option value="DISTRIB">Product Distributor</option>
                                <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                <option value="FARM">Agriculture - Farm</option>
                                <option value="GOLF">Golf Course</option>
                                <option value="GOVERNMENT">Government Entity</option>
                                <option value="INDUSTRIAL">Industrial</option>
                                <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                <option value="MRSALE">One Time Purchase Expected</option>
                                <option value="MUNICIPAL">Municipal/ School</option>
                                <option value="Non-Profit">Non-Profit Account</option>
                                <option value="OTHER">Other</option>
                                <option value="SPORTFLD">Sportsfield</option>
                            </Select>
                        </div>

                    </div>

                    {/*Pricing Type*/}
                    <div className="w-1/6 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Pricing Type</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                onChange={filteringPrice}
                            >
                                <option value="">Select Pricing Type...</option>
                                <option value="AG">Agriculture</option>
                                <option value="DIST1">Distributor NE/INTL</option>
                                <option value="DIST2">Distributor SE/CAN</option>
                                <option value="NE">Northeast</option>
                                <option value="SE">Southeast</option>
                            </Select>
                        </div>

                    </div>


                </div>

                {/*Customer Body*/}
                <div className="w-full h-full rounded-md">

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-lg`}>

                            {/*HEADER*/}
                            <div className="w-full flex flex-row pl-3 pr-3">

                                <div className={`w-full flex flex-row border-b-2 ${darkMode ? 'border-darkBgColor' : ''}`}>

                                    {/*Buttons Delete*/}
                                    <div className="flex flex-col items-center mr-3">
                                        <div className="rounded-full mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            {/*<TrashIcon className="h-6 text-transparent m-auto"/>*/}
                                            <div className="justify-center flex flex-col items-center h-full">
                                                <div className="text-sm font-semibold">[{count}]</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Contact Name*/}
                                    <div className="w-1/3 mr-1.5 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Sales Rep</div>
                                        </div>

                                    </div>

                                    {/*Contact Phone*/}
                                    <div className="w-1/3 flex flex-row items-center h-full mt-auto mb-auto">

                                        <div className="justify-center flex flex-col h-full">
                                            <div className="text-sm font-semibold">Customer</div>
                                        </div>


                                    </div>

                                    {/*Status*/}
                                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto items-center justify-center mb-auto">

                                        <div className="w-1/2 h-8 justify-center items-center rounded-full flex flex-row mr-auto">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">Status</div>
                                        </div>

                                        <div className="w-1/2 h-8 justify-center items-center rounded-full flex flex-row ml-auto">
                                            <div className="flex items-center text-center justify-center h-full text-sm font-semibold">
                                                {status === "A" ? 'Orders' : 'Submit'}
                                            </div>
                                        </div>

                                    </div>

                                    {/**/}
                                    <div className="w-1/3 ml-5 flex flex-row h-full mt-auto mb-auto items-center justify-center">

                                        <div className="w-fit p-1 h-8 rounded-full">
                                            <div className="flex items-center justify-center h-full text-sm font-semibold">YTD Sales</div>
                                        </div>


                                    </div>

                                    {/*Buttons DropDown*/}
                                    <div className="flex flex-col items-center">
                                        <div className=" mt-auto mb-auto h-8 w-8 items-center justify-center align-middle">
                                            <TrashIcon className="h-6 text-transparent m-auto"/>
                                        </div>
                                    </div>


                                </div>

                            </div>

                            {/*CUSTOMERS MAP*/}
                            <div className={`overflow-y-auto h-full ${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} p-1 rounded-lg max-h-[calc(100vh-14rem)] scroll`}>


                                {status === "A" && (
                                    <div className={`w-full h-full relative`}>

                                        {filteredCustomers.length === 0 ? (
                                            // Display custom dialog if sprays array is empty
                                            <div className="flex w-full h-full justify-center items-center py-10">

                                                <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                    <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                        <EyeSlashIcon className={`w-10 stroke-2 mr-3`}/>
                                                        <p className="text-xl mt-2 line-clamp-1 tracking-widest flex">You Have No Active Orders</p>
                                                        <p className="text-md mt-2 line-clamp-1 tracking-widest flex text-gray-500">You Must Wait For the Plant to Update Your Customer's Status</p>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            // Display SprayCard components if sprays array is not empty
                                            filteredCustomers.map((customer, index) => {
                                                return (
                                                    <CustomerCard
                                                        key={index}
                                                        index={index}
                                                        customer={customer}
                                                    />
                                                )
                                            })
                                        )}

                                    </div>
                                )}

                                {status === "P" && (
                                    <div className={`w-full h-full relative`}>

                                        {filteredPendingCustomers.length === 0 ? (
                                            // Display custom dialog if sprays array is empty
                                            <div className="flex w-full h-full justify-center items-center py-10">

                                                <div className={`flex items-center border-2 border-dashed ${darkMode ? 'text-white border-borderColor' : 'text-black bg-neutral-50'} w-fit px-24 py-3 h-fit mr-auto ml-auto justify-center rounded-lg hover:scale-105`}>
                                                    <div className="p-2 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-2">
                                                        <EyeSlashIcon className={`w-10 stroke-2 mr-3`}/>
                                                        <p className="text-xl mt-2 line-clamp-1 tracking-widest flex">You Have No Pending Customers</p>
                                                        <p className="text-md mt-2 line-clamp-1 tracking-widest flex text-gray-500">You May Onboard New Customers in the New Customer Form</p>
                                                    </div>
                                                </div>

                                            </div>
                                        ) : (
                                            // Display SprayCard components if sprays array is not empty
                                            filteredPendingCustomers.map((customer, index) => {
                                                return (
                                                    <CustomerCard
                                                        key={index}
                                                        index={index}
                                                        customer={customer}
                                                    />
                                                )
                                            })
                                        )}

                                    </div>
                                )}

                            </div>


                        </div>




                    </div>

                </div>

                {/*Customers Footer [Buttons]*/}
                <div className="w-full h-fit flex mb-3 mt-3">

                </div>

            </div>





            {existing && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!existing}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        existing ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-1/2 max-h-full">

                        {/* Modal content */}
                        <div className={`relative ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor text-white' : 'bg-white drop-shadow-lg text-black'} rounded-lg animate-zoomIn`}>


                            {/* Modal header */}
                            <div className="flex items-center justify-between p-5 border-b rounded-t dark:border-gray-600">

                                <div className={`w-full flex flex-col`}>
                                    <h3 className="text-lg w-full font-semibold">
                                         Add Existing Customer
                                    </h3>
                                </div>

                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:animate-pulse hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleVisibility}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                </button>
                            </div>


                            {/* Modal body */}
                            <form className="p-5"
                                  onSubmit={onSubmitMain}
                                  method="POST">


                                {/*Row 1*/}
                                <div className="grid gap-3 mb-3 grid-cols-2">

                                    {/*Customer Code*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Customer Code
                                        </label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type Customer Code [5 Characters]"
                                            value={customerCode} onChange={(e) => setCustomerCode(e.target.value)}
                                            required
                                        />
                                    </div>

                                    {/*Customer Name*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Customer Name
                                        </label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type Customer Name"
                                            value={name} onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>

                                </div>

                                {/*Row 2*/}
                                <div className="grid gap-3 mb-3 grid-cols-3">

                                    {/*Price Type*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="category"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Price Type
                                        </label>
                                        <Select
                                            id="category"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            onChange={(event) => {
                                                const selectedValue = event.target.value;
                                                setPriceType(selectedValue); // Update priceCode state
                                                console.log("Selected Price Code:", selectedValue); // Debugging to verify if it's working
                                            }}
                                        >
                                            <option value="AG">Agriculture</option>
                                            <option value="DIST1">Distributor NE/INTL</option>
                                            <option value="DIST2">Distributor SE/CAN</option>
                                            <option value="NE">Northeast</option>
                                            <option value="SE">Southeast</option>
                                        </Select>
                                    </div>

                                    {/*Industry*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="price"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Industry Type
                                        </label>
                                        <Select
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            value={industry}
                                            onChange={(e) => setIndustry(e.target.value)}
                                        >
                                            <option value="">Select...</option>
                                            <option value="BOTANICAL">Botanical Garden</option>
                                            <option value="DISTRIB">Product Distributor</option>
                                            <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                            <option value="FARM">Agriculture - Farm</option>
                                            <option value="GOLF">Golf Course</option>
                                            <option value="GOVERNMENT">Government Entity</option>
                                            <option value="INDUSTRIAL">Industrial</option>
                                            <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                            <option value="MRSALE">One Time Purchase Expected</option>
                                            <option value="MUNICIPAL">Municipal/ School</option>
                                            <option value="Non-Profit">Non-Profit Account</option>
                                            <option value="OTHER">Other</option>
                                            <option value="SPORTFLD">Sportsfield</option>

                                        </Select>
                                    </div>

                                    {/*Customer Type*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Discount Level
                                        </label>
                                        <Select
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            value={customerType}
                                            onChange={(e) => setCustomerType(e.target.value)}
                                        >
                                            <option value="">Select...</option>
                                            <option value="5">Level 1</option>
                                            <option value="10">Level 2</option>
                                            <option value="15">Level 3</option>
                                            <option value="20">Level 4</option>

                                        </Select>
                                    </div>

                                </div>

                                {/*Row 4*/}
                                <div className="grid gap-3 mb-3 grid-cols-2">

                                    {/*Customer Email*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="email"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Customer Email
                                        </label>
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type Customer Email"
                                            value={customerEmail} onChange={(e) => setCustomerPhone(e.target.value)}
                                            required
                                        />
                                    </div>

                                    {/*Customer Phone*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="phone"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Customer Phone
                                        </label>
                                        <Input
                                            type="text"
                                            name="phone"
                                            id="phone"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type Customer Phone"
                                            value={customerPhone} onChange={(e) => setCustomerPhone(e.target.value)}
                                            required
                                        />
                                    </div>

                                </div>

                                {/*Row 4 Locations*/}
                                <div className="grid gap-3 mb-3 grid-cols-1">

                                    {/**/}
                                    <div className="col-span-2 sm:col-span-1">

                                        <div className={`flex flex-row items-center`}>
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium"
                                            >
                                                Shipping Locations [{locations.length}]
                                            </label>

                                            <Button className="w-fit rounded-full ml-5 hover:text-green-400 hover:scale-105 hover:animate-pulse"
                                                onClick={handleAddLocation}>
                                                <PlusCircleIcon className="w-8"></PlusCircleIcon>
                                            </Button>
                                        </div>

                                        <div className="mt-2 h-40 overflow-y-auto">

                                            {locations.map((location, index) => (

                                                <div key={index} className={`mb-2 p-1 border ${darkMode ? 'border-darkBgColor' : ''} rounded-lg`}>
                                                    <div className="flex flex-row w-full p-1">
                                                        <Button
                                                            className="hover:scale-105 hover:animate-pulse text-red-500 w-fit h-fit mt-auto mb-auto rounded-full mr-1"
                                                            onClick={() => handleRemoveLocation(index)}
                                                        >
                                                            <XCircleIcon className="w-6"/>
                                                        </Button>

                                                        <input
                                                            type="text"
                                                            name="nickName"
                                                            value={location.nickName}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            placeholder="NickName"
                                                            className={`rounded-lg block w-full px-2.5 py-1.5 mr-3 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                        />
                                                        <input
                                                            type="text"
                                                            name="contactName"
                                                            value={location.contactName}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            placeholder="Ship to Contact"
                                                            className={`rounded-lg block w-full px-2.5 py-1 mr-3 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                        />
                                                        <input
                                                            type="text"
                                                            name="contactPhone"
                                                            value={location.contactphone}
                                                            onChange={(e) => handleInputChange(index, e)}
                                                            placeholder="Ship to Phone"
                                                            className={`rounded-lg block w-full px-2.5 py-1 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                                        />
                                                    </div>
                                                </div>

                                            ))}

                                        </div>
                                    </div>


                                </div>


                                <Button
                                    type="submit"
                                    className="text-white inline-flex items-center bg-bgLightGreen hover:bg-opacity-50 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    <svg
                                        className="me-1 -ms-1 w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    Add Existing Customer
                                </Button>


                            </form>


                        </div>
                    </div>
                </div>

            )}


        </div>
    )
};


export default Customers;
