import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";
import {Button, Input, Select, Textarea} from "@headlessui/react";
import {
    ArrowDownTrayIcon,
    ArrowPathIcon,
    MagnifyingGlassIcon,
    PlusCircleIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import PricingCard from "./Pricing/PricingCard";

import { utils as xlsxUtils, write as writeXlsx } from 'xlsx'
import { saveAs } from 'file-saver'

const Pricing = () => {

    const { darkMode } = useDarkMode();
    const { currentUser } = useAuth();

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            if (currentUser) {
                try {
                    const storedProducts = localStorage.getItem(`products_${currentUser.uid}`);
                    if (storedProducts) {
                        const storedData = JSON.parse(storedProducts);
                        setProducts(storedData);
                        setFilteredProducts(storedData);
                    } else {

                        const productsCollection = db.collection("Products");
                        const snapshot = await productsCollection.get();

                        if (!snapshot.empty) {
                            const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                            setProducts(productData);
                            setFilteredProducts(productData);
                            localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));
                        } else {
                            console.log('Product documents not found');
                        }

                    }
                } catch (error) {
                    console.error('Error fetching products data:', error);
                }
            }
        };

        fetchProducts();
    }, [currentUser]);

    const [updating, setUpdating] = useState(false);

    const onUpdate = async () => {
        setUpdating(true);
        const startTime = Date.now();

        // Pending
        try {
            const productsCollection = db.collection("Products");
            const snapshot = await productsCollection.get();

            if (!snapshot.empty) {
                const productData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productData);
                setFilteredProducts(productData);
                localStorage.setItem(`products_${currentUser.uid}`, JSON.stringify(productData));
            } else {
                console.log('Product documents not found');
            }

        } catch (error) {
            console.error("Error updating products", error);
        }

        const endTime = Date.now();
        const timeElapsed = (endTime - startTime) /1000;
        setUpdating(false);
        alert(`Products Updated in ${timeElapsed} secs`);
    };


    const [searchQuery, setSearchQuery] = useState("");

    const [adding, setAdding] = useState(false);

    // Function to toggle visibility
    const toggleVisibility = () => {
        setAdding(!adding);
    };

    const filterProductsByCatagory = (catagory) => {
        const filtered = products.filter(product => catagory === '' || product.category === catagory);
        setFilteredProducts(filtered);
    }

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = products.filter((product) =>
            product.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredProducts(filtered);
    }



    const [prices, setPrices] = useState([]);

    const handleAddPrice = () => {
        setPrices([...prices, { um: '', code: '', ne: 0, se: 0, ag: 0, dist1: 0, dist2: 0 }]);
    };

    const handleRemovePrice = (index) => {
        const newLocations = prices.filter((_, i) => i !== index);
        setPrices(newLocations);
    };

    // Updated handleInputChange
    const handleInputChange = (index, e) => {
        const { name, value } = e.target;
        const newPrices = [...prices];
        newPrices[index][name] = value;
        setPrices(newPrices);
    };



    const [name, setName] = useState("");
    const [category, setCategory] = useState("");
    const [description, setDescription] = useState("");

    const [ph, setPh] = useState(0);
    const [weight, setWeight] = useState(0);

    const onSubmitMain = async (e) => {
        e.preventDefault();

        const collectionRef = db.collection("Products");

        const parsedFormData = {

            // Sales Info
            name: name,
            category: category,
            description: description,
            ph: parseFloat(ph),
            weight: parseFloat(weight),

            updated: Date.toLocaleString(),

            prices: prices,
        };

        try {
            await collectionRef.add(parsedFormData);
            alert(`Product Added to the Cloud`); // Optional: alert for user feedback

            await onUpdate();

            setName("");
            setCategory("");
            setDescription("");
            setPh(0);
            setWeight(0);

            setPrices([]);

            // onUpdate();

        } catch (error) {
            console.error('Error registering product:', error.message);
            // setIsRegistered(false);
        }
    };


    const categoryColors = [
        { category: 'Biostimulants', color: 'bg-bsColor' },
        { category: 'Liquid Fertilizer', color: 'bg-lfColor' },
        { category: 'Dry Fertilizer', color: 'bg-teal-700' },
        { category: 'Secondary Nutrients', color: 'bg-snColor' },
        { category: 'Micronutrients', color: 'bg-mnColor' },
        { category: 'Penetrants & Water Holding Agents', color: 'bg-whColor' },
        { category: 'A-Plus® Solubilizing Surfactants', color: 'bg-apColor' },
        { category: 'Turfgrass Pigment', color: 'bg-tpColor' },
        { category: 'Water Treatments', color: 'bg-wtColor' },
        { category: 'Soil Amendments', color: 'bg-saColor' },
        { category: 'Fungicides', color: 'bg-fgColor' },
        { category: 'Equipment', color: 'bg-gray-600' },
    ];

    const bgColor = categoryColors.find(c => c.category === category)?.color || '';

    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const downloadAsExcel = () => {
        const columnHeaders = [
            'Item',
            'Item Description',
            'U/M',
            'Unit Price 1 (NE)',
            'Unit Price 1 (SE)',
            'Unit Price 3 (AG)',
            'Unit Price 4 (DIST 1)',
            'Unit Price 5 (DIST 2)',
        ];

        // Map through products to extract product details
        const worksheetData = [
            columnHeaders,
            ...products.flatMap(order =>
                order.prices.map(price => [
                    price.code || '',
                    order.name || '',
                    price.um || '',
                    numberWithCommas(price.ne) || 0,
                    numberWithCommas(price.se) || 0,
                    numberWithCommas(price.ag) || 0,
                    numberWithCommas(price.dist1) || 0,
                    numberWithCommas(price.dist2) || 0
                ])
            )
        ];

        // Generate Excel worksheet
        const worksheet = xlsxUtils.aoa_to_sheet(worksheetData);

        // Set custom column widths (double the default width)
        worksheet['!cols'] = [
            { wch: 20 }, // Item
            { wch: 40 }, // Item Description
            { wch: 10 }, // U/M
            { wch: 20 }, // Unit Price 1 (NE)
            { wch: 20 }, // Unit Price 1 (SE)
            { wch: 20 }, // Unit Price 3 (AG)
            { wch: 20 }, // Unit Price 4 (DIST 1)
            { wch: 20 }, // Unit Price 5 (DIST 2)
        ];

        // Create workbook and append worksheet
        const workbook = xlsxUtils.book_new();
        xlsxUtils.book_append_sheet(workbook, worksheet, 'Product Pricing');

        // Get current date in yyyymmdd format
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const dd = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${yyyy}${mm}${dd}`;

        // Convert workbook to buffer and create Blob
        const excelBuffer = writeXlsx(workbook, { bookType: 'xlsx', type: 'array' });
        const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Save the Excel file with the dynamic filename
        saveAs(excelBlob, `ItemPricingExport${formattedDate}.xlsx`);
    };



    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-screen overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>

            <div className="animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">

                {/*Pricing Header*/}
                <div className="w-full h-fit flex mb-3">

                    <div className={`text-2xl w-1/3 ${darkMode ? 'text-white' : 'text-black'} mr-5 flex flex-row`}>Product Pricing
                        {/*Buttons Delete*/}
                        <div className={`flex flex-col items-center ml-3 mt-auto mb-auto ${updating ? 'animate-pulse' : ''}`}>
                            <Button
                                className={`rounded-full hover:scale-105 hover:rotate-12 mt-auto mb-auto h-6 w-6 items-center justify-center align-middle ${updating ? 'animate-spin' : ''}`}
                                onClick={onUpdate}
                            >
                                <ArrowPathIcon className="h-6 text-borderColor m-auto"/>
                            </Button>
                        </div>
                    </div>

                    <div className={`text-xl mr-1.5 ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} w-5/6 flex rounded-md border drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Products:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1">{products.length}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3 line-clamp-1">Filtered Products:</div>
                            <div className="text-sm rounded-md border px-2 line-clamp-1">{filteredProducts.length}</div>
                        </div>

                        {/*Excel*/}
                        <div className="w-fit h-full text-sm">

                            <div className="flex flex-col items-center justify-center h-full mr-3">
                                {/*<div className="text-sm mb-1">Category</div>*/}
                                <Button
                                    className="bg-bgLightGreen w-8 h-8 rounded-full hover:scale-105 hover:bg-opacity-80 py-1.5 text-white text-sm flex flex-row align-middle justify-center items-center"
                                    onClick={downloadAsExcel}
                                >
                                    <ArrowDownTrayIcon className="w-5 stroke-2"/>
                                </Button>
                            </div>

                        </div>

                    </div>

                    <div className="w-1/6 text-black ml-1.5">
                        <Button
                            className="bg-bgLightGreen h-full w-full hover:scale-105 hover:bg-opacity-80 rounded-md py-1.5 px-6 text-white text-sm mr-3 flex flex-row align-middle justify-center items-center"
                            onClick={toggleVisibility}
                        >
                            <PlusCircleIcon className="w-6"/>
                            <div className="w-full line-clamp-1">Add Product</div>
                        </Button>
                    </div>

                </div>

                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'} space-x-3`}>

                    {/*/!*Excel*!/*/}
                    {/*<div className="w-fit h-full text-sm">*/}

                    {/*    <div className="flex flex-col w-full mr-3 h-full">*/}
                    {/*        /!*<div className="text-sm mb-1">Category</div>*!/*/}
                    {/*        <Button*/}
                    {/*            className="bg-bgLightGreen h-full w-full hover:scale-105 hover:bg-opacity-80 rounded-md py-1.5 px-6 text-white text-sm mr-3 flex flex-row align-middle justify-center items-center"*/}
                    {/*            onClick={toggleVisibility}*/}
                    {/*        >*/}
                    {/*            <ArrowDownTrayIcon className="w-6 mr-3"/>*/}
                    {/*            <div className="w-full line-clamp-1 text-xs">Download Excel</div>*/}
                    {/*        </Button>*/}
                    {/*    </div>*/}

                    {/*</div>*/}

                    {/*Search Bar*/}
                    <div className="w-3/4 h-fit text-sm">
                        {/*<div className="text-sm mb-1">Product</div>*/}

                        <div
                            className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                        <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>

                            <div className="w-full">

                                <Input
                                    className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                    placeholder="Search Products By Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                    // onFocus={() => setShowProducts(true)}
                                    // onBlur={() => setTimeout(() => setShowProducts(false), 100)} // Delay hiding to allow click
                                />

                            </div>

                        </div>

                    </div>

                    {/*Category*/}
                    <div className="w-1/4 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            {/*<div className="text-sm mb-1">Category</div>*/}
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                // value={status}
                                onChange={(e) => filterProductsByCatagory(e.target.value)}
                            >
                                <option value="">Select Category...</option>
                                <option value="Biostimulants">Biostimulants</option>
                                <option value="Liquid Fertilizer">Liquid Fertilizers</option>
                                <option value="Dry Fertilizer">Dry Fertilizers</option>
                                <option value="Secondary Nutrients">Secondary Nutrients</option>
                                <option value="Micronutrients">Micronutrients</option>
                                <option value="Penetrants & Water Holding Agents">Penetrants & Water Holding Agents</option>
                                <option value="A-Plus® Solubilizing Surfactants">A-Plus® Solubilizing Surfactants</option>
                                <option value="Turfgrass Pigment">Turfgrass Pigment</option>
                                <option value="Water Treatments">Water Treatments</option>
                                <option value="Soil Amendments">Soil Amendments</option>
                                <option value="Fungicides">Fungicides</option>
                                <option value="Equipment">Equipment</option>
                            </Select>
                        </div>

                    </div>


                </div>

                {/*Catalog Body*/}
                <div className="w-full h-full rounded-md">

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg`}>

                            {/*CUSTOMERS MAP*/}
                            <div className={`overflow-y-auto h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg max-h-[calc(100vh-11.75rem)] scroll`}>

                                <div>

                                    {filteredProducts.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center py-10">

                                            <div
                                                className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                    <p className="text-xl">No Products in this Order</p>
                                                    <p className="text-lg mt-2">Add a Product</p>
                                                    <p className="text-xs">1] Search Product by name using the search bar above.</p>
                                                    <p className="text-xs">2] Browse the Digital Catalog by clicking on the button above.</p>
                                                </div>
                                            </div>

                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-3 auto-rows-auto">
                                            {filteredProducts
                                                .map((product, index) => (
                                                    <PricingCard
                                                        index={index}
                                                        key={product.id}
                                                        product={product}
                                                    />
                                                ))}
                                        </div>
                                    )}

                                </div>

                            </div>


                        </div>


                    </div>

                </div>


            </div>


            {adding && (

                <div
                    id="crud-modal"
                    tabIndex="-1"
                    aria-hidden={!adding}
                    className={`fixed top-0 right-0 left-0 z-50 w-full h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden justify-center items-center ${
                        adding ? 'flex' : 'hidden'
                    }`}
                >
                    <div className="relative p-4 w-3/4 max-h-full">

                        {/* Modal content */}
                        <div className={`relative ${darkMode ? 'bg-neutral-800 border-2 border-darkBgColor text-white' : 'bg-white drop-shadow-lg text-black'} rounded-lg animate-zoomIn`}>


                            {/* Modal header */}
                            <div className="flex items-center justify-between p-5 border-b rounded-t dark:border-gray-600">

                                <div className={`w-full flex flex-col`}>
                                    <h3 className="text-lg w-full font-semibold">
                                        Add Product
                                    </h3>
                                </div>

                                <button
                                    type="button"
                                    className="text-gray-400 bg-transparent hover:animate-pulse hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={toggleVisibility}
                                >
                                    <svg
                                        className="w-3 h-3"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 14 14"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                        />
                                    </svg>
                                </button>
                            </div>


                            {/* Modal body */}
                            <form className="p-5"
                                onSubmit={onSubmitMain}
                                  method="POST">


                                <div className="grid gap-3 mb-3 grid-cols-2">

                                    {/*Name*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Name
                                        </label>
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type Product Name"
                                            value={name} onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>

                                    {/*Category*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label
                                            htmlFor="category"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Category
                                        </label>
                                        <Select
                                            id="category"
                                            className={`rounded-lg block w-full p-2.5  ${darkMode ? `${bgColor} bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white` : `${bgColor} bg-gray-50 border border-gray-300 text-white text-sm`}`}
                                            onChange={(event) => {
                                                const selectedValue = event.target.value;
                                                setCategory(selectedValue); // Update priceCode state
                                            }}
                                        >
                                            <option value="">Select Category...</option>
                                            <option value="Biostimulants">Biostimulants</option>
                                            <option value="Liquid Fertilizer">Liquid Fertilizers</option>
                                            <option value="Dry Fertilizer">Dry Fertilizers</option>
                                            <option value="Secondary Nutrients">Secondary Nutrients</option>
                                            <option value="Micronutrients">Micronutrients</option>
                                            <option value="Penetrants & Water Holding Agents">Penetrants & Water Holding Agents</option>
                                            <option value="A-Plus® Solubilizing Surfactants">A-Plus® Solubilizing Surfactants</option>
                                            <option value="Turfgrass Pigment">Turfgrass Pigment</option>
                                            <option value="Water Treatments">Water Treatments</option>
                                            <option value="Soil Amendments">Soil Amendments</option>
                                            <option value="Fungicides">Fungicides</option>
                                            <option value="Equipment">Equipment</option>
                                        </Select>
                                    </div>

                                </div>


                                <div className="grid gap-3 mb-3 grid-cols-2">

                                    {/*Description*/}
                                    <div className="col-span-3 sm:col-span-3">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium"
                                        >
                                            Description
                                        </label>
                                        <Textarea
                                            type="text"
                                            name="name"
                                            id="name"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type Product Description"
                                            value={description} onChange={(e) => setDescription(e.target.value)}
                                            required
                                        />
                                    </div>


                                </div>


                                <div className="grid gap-3 mb-3 grid-cols-3">

                                    {/*Ph*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="category" className="block mb-2 text-sm font-medium">
                                            Ph
                                        </label>
                                        <Input
                                            type="number"
                                            name="ph"
                                            id="name"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type PH"
                                            value={ph} onChange={(e) => setPh(e.target.value)}
                                            required
                                        />
                                    </div>

                                    {/*Weight*/}
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="price" className="block mb-2 text-sm font-medium">
                                            Weight
                                        </label>
                                        <Input
                                            type="number"
                                            name="weight"
                                            id="weight"
                                            className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}
                                            placeholder="Type Weight per Gallon"
                                            value={weight} onChange={(e) => setWeight(e.target.value)}
                                            required
                                        />
                                    </div>

                                    {/*/!*Customer Type*!/*/}
                                    {/*<div className="col-span-2 sm:col-span-1">*/}
                                    {/*    <label*/}
                                    {/*        htmlFor="name"*/}
                                    {/*        className="block mb-2 text-sm font-medium"*/}
                                    {/*    >*/}
                                    {/*        Discount Level*/}
                                    {/*    </label>*/}
                                    {/*    <Select*/}
                                    {/*        className={`rounded-lg block w-full p-2.5 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}*/}
                                    {/*        // value={customerType}*/}
                                    {/*        // onChange={(e) => setCustomerType(e.target.value)}*/}
                                    {/*    >*/}
                                    {/*        <option value="">Select...</option>*/}
                                    {/*        <option value="5">Level 1</option>*/}
                                    {/*        <option value="10">Level 2</option>*/}
                                    {/*        <option value="15">Level 3</option>*/}
                                    {/*        <option value="20">Level 4</option>*/}

                                    {/*    </Select>*/}
                                    {/*</div>*/}

                                </div>

                                {/*Prices*/}
                                <div className="grid gap-3 mb-3 grid-cols-1">

                                    {/**/}
                                    <div className="col-span-2 sm:col-span-1">

                                        {/*Prices Header*/}
                                        <div className={`flex flex-row items-center`}>
                                            <label
                                                htmlFor="name"
                                                className="block text-sm font-medium"
                                            >
                                                Prices [{prices.length}]
                                            </label>

                                            <Button
                                                className="w-fit rounded-full ml-5 hover:text-green-400 hover:scale-105 hover:animate-pulse"
                                                onClick={handleAddPrice}
                                            >
                                                <PlusCircleIcon className="w-8"></PlusCircleIcon>
                                            </Button>
                                        </div>

                                        {/*Prices Label*/}
                                        <div className={`flex flex-row items-center px-2 border-b py-1 mb-1`}>
                                            <Button className="text-transparent w-fit h-fit mt-auto mb-auto rounded-full mr-1"
                                                // onClick={() => handleRemovePrice(index)}
                                                disabled={true}
                                            >
                                                <XCircleIcon className="w-6"/>
                                            </Button>

                                            {/*U/M*/}
                                            <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                                                <div className={`text-sm font-bold`}>U/M</div>
                                            </div>

                                            {/*Code*/}
                                            <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                                                <div className={`text-sm font-bold`}>Product Code</div>
                                            </div>


                                            {/*NE*/}
                                            <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                                                <div className={`text-sm font-bold`}>NE Price</div>
                                            </div>

                                            {/*SE*/}
                                            <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                                                <div className={`text-sm font-bold`}>SE Price</div>
                                            </div>

                                            {/*AG*/}
                                            <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                                                <div className={`text-sm font-bold`}>AG Price</div>
                                            </div>

                                            {/*Dist1*/}
                                            <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                                                <div className={`text-sm font-bold`}>DIST1 Price</div>
                                            </div>

                                            {/*Dist2*/}
                                            <div className={`rounded-lg items-center justify-center flex w-full px-2 mr-2`}>
                                                <div className={`text-sm font-bold`}>DIST2 Price</div>
                                            </div>


                                        </div>

                                        {/*Prices Maps*/}
                                        <div className="mt-2 h-56 overflow-y-auto">

                                            {prices.map((price, index) => (

                                                <div key={index} className={`mb-2 p-1 border animate-zoomIn ${darkMode ? 'border-darkBgColor' : ''} rounded-lg`}>
                                                    <div className="flex flex-row w-full items-center p-1">
                                                        <Button
                                                            className="hover:scale-105 hover:animate-pulse text-red-500 w-fit h-fit mt-auto mb-auto rounded-full mr-1"
                                                            onClick={() => handleRemovePrice(index)}
                                                        >
                                                            <XCircleIcon className="w-6"/>
                                                        </Button>

                                                        {/* U/M */}
                                                        <div className={`rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                                            <Select
                                                                name="um"
                                                                className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                                                value={price.um}
                                                                onChange={(e) => handleInputChange(index, e)}
                                                            >
                                                                <option value="">Select U/M...</option>
                                                                <option value="ACR">Acre</option>
                                                                <option value="B12">BAG 12#</option>
                                                                <option value="B25">BAG 25#</option>
                                                                <option value="B30">BAG 30#</option>
                                                                <option value="B40">BAG 40#</option>
                                                                <option value="B5">BAG 5#</option>
                                                                <option value="B50">BAG 50#</option>
                                                                <option value="B51">BAG 51#</option>
                                                                <option value="BAG">BAG (Unspecified Weight)</option>
                                                                <option value="2.5">CASE (2x2.5)</option>
                                                                <option value="CS4">CASE (4X1)</option>
                                                                <option value="30">DRUM 30-GAL</option>
                                                                <option value="55">DRUM 55-GAL</option>
                                                                <option value="DRU">DRUM (Unspecified Volume)</option>
                                                                <option value="EAC">EACH</option>
                                                                <option value="GAL">GALLON</option>
                                                                <option value="TR">TOTE REFILL</option>
                                                                <option value="LB">POUND</option>
                                                                <option value="OZ">Ounce - Av</option>
                                                                <option value="PAI">PAIL 5-GAL</option>
                                                                <option value="SAC">Super Sack (B1000)</option>
                                                                <option value="SAC">Super Sack (B2000)</option>
                                                                <option value="TON">TON (2,000#)</option>
                                                                <option value="275">TOTE 275-GAL</option>
                                                            </Select>
                                                        </div>

                                                        {/* Code */}
                                                        <div className={`rounded-lg block w-full px-2 mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                                            <Input
                                                                name="code"
                                                                className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                                                type="string"
                                                                placeholder="Product Code"
                                                                value={price.code}
                                                                onChange={(e) => handleInputChange(index, e)}
                                                            />
                                                        </div>

                                                        {/* NE */}
                                                        <div className={`rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                                            <div className="text-borderColor text-xs mr-3">$</div>
                                                            <Input
                                                                name="ne"
                                                                className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                                                type="number"
                                                                placeholder="NE"
                                                                value={price.ne}
                                                                onChange={(e) => handleInputChange(index, e)}
                                                            />
                                                            <div className="text-borderColor w-fit text-xs ml-1">USD</div>
                                                        </div>

                                                        {/*SE*/}
                                                        <div className={`rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                                            <div className="text-borderColor text-xs mr-3">$</div>

                                                            <Input
                                                                name="se"
                                                                className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                                                type="number"
                                                                placeholder="SE"
                                                                value={price.se}
                                                                onChange={(e) => handleInputChange(index, e)}
                                                            />

                                                            <div className="text-borderColor w-fit text-xs ml-1">USD
                                                            </div>

                                                        </div>

                                                        {/*AG*/}
                                                        <div className={`rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                                            <div className="text-borderColor text-xs mr-3">$</div>

                                                            <Input
                                                                name="ag"
                                                                className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                                                type="number"
                                                                placeholder="AG"
                                                                value={price.ag}
                                                                onChange={(e) => handleInputChange(index, e)}
                                                            />

                                                            <div className="text-borderColor w-fit text-xs ml-1">USD
                                                            </div>

                                                        </div>

                                                        {/*Dist1*/}
                                                        <div className={`rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                                            <div className="text-borderColor text-xs mr-3">$</div>

                                                            <Input
                                                                name="dist1"
                                                                className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                                                type="number"
                                                                placeholder="DIST 1"
                                                                value={price.dist1}
                                                                onChange={(e) => handleInputChange(index, e)}
                                                            />

                                                            <div className="text-borderColor w-fit text-xs ml-1">USD
                                                            </div>

                                                        </div>

                                                        {/*Dist2*/}
                                                        <div className={`rounded-lg items-center w-full px-2 flex mr-2 ${darkMode ? 'bg-darkBgColor dark:border-gray-500 dark:placeholder-gray-400 dark:text-white' : 'bg-gray-50 border border-gray-300 text-gray-900 text-sm'}`}>
                                                            <div className="text-borderColor text-xs mr-3">$</div>

                                                            <Input
                                                                name="dist2"
                                                                className={`rounded-md text-xs bg-transparent focus:outline-none py-1.5 w-full ${darkMode ? 'text-white' : 'text-black'}`}
                                                                type="number"
                                                                placeholder="DIST 2"
                                                                value={price.dist2}
                                                                onChange={(e) => handleInputChange(index, e)}
                                                            />

                                                            <div className="text-borderColor w-fit text-xs ml-1">USD
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            ))}

                                        </div>

                                    </div>


                                </div>


                                <Button
                                    type="submit"
                                    className="text-white inline-flex items-center bg-bgLightGreen hover:bg-opacity-50 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    <svg
                                        className="me-1 -ms-1 w-5 h-5"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    Add Product
                                </Button>


                            </form>


                        </div>
                    </div>
                </div>

            )}


        </div>
    )
};


export default Pricing;
