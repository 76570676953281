import React, {useEffect, useState} from "react";
import {useDarkMode} from "../../contexts/darkModeContext";
import website from "../../website.png";

// Image Import
import impulse from "../../images/impulse.png";
import kelplant from "../../images/kelplant.png";
import sixteen from "../../images/16-0-8.jpg";

// SellSheet Import
import impulsePDF from "../../sellSheets/impulse.pdf";
import sugarCalPDF from "../../sellSheets/sugarCal.pdf";
import sixteenPDF from "../../sellSheets/16-0-8.pdf";
import kelplantPDF from "../../sellSheets/kelplant.pdf";

// SDS Import
import impulseSDS from "../../SDS/impulse.pdf";
import sugarCalSDS from "../../SDS/sugarCal.pdf";
import sixteenSDS from "../../SDS/16-0-8.pdf";
import kelplantSDS from "../../SDS/kelplant.pdf";


import mainLogo from "../../main-logo.png"
import {Button} from "@headlessui/react";

const ProductCardV2 = ({ product, onOpenModal }) => {

    const { darkMode } = useDarkMode();

    const imageMap = {
        website: website,
        impulse: impulse,
        kelplant: kelplant,
        "16-0-8": sixteen,
    };

    const sellSheetMapping ={
        impulse: impulsePDF,
        sugarCal: sugarCalPDF,
        "16-0-8": sixteenPDF,
        kelplant: kelplantPDF,
    }

    const SDSMapping ={
        impulse: impulseSDS,
        sugarCal: sugarCalSDS,
        "16-0-8": sixteenSDS,
        kelplant: kelplantSDS,
    }


    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const [isLoaded, setIsLoaded] = useState(false); // State to track when the component is loaded
    const [isRemoving, setIsRemoving] = useState(false); // Track whether the item is being removed

    const categoryColors = [
        { category: 'Biostimulants', color: 'bg-bsColor' },
        { category: 'Liquid Fertilizer', color: 'bg-lfColor' },
        { category: 'Dry Fertilizer', color: 'bg-teal-700' },
        { category: 'Secondary Nutrients', color: 'bg-snColor' },
        { category: 'Micronutrients', color: 'bg-mnColor' },
        { category: 'Penetrants & Water Holding Agents', color: 'bg-whColor' },
        { category: 'A-Plus® Solubilizing Surfactants', color: 'bg-apColor' },
        { category: 'Turfgrass Pigment', color: 'bg-tpColor' },
        { category: 'Water Treatments', color: 'bg-wtColor' },
        { category: 'Soil Amendments', color: 'bg-saColor' },
        { category: 'Fungicides', color: 'bg-fgColor' },
        { category: 'Equipment', color: 'bg-gray-600' },

    ];

    const bgColor = categoryColors.find(c => c.category === product.category)?.color || '';


    return (
        <div className={`w-full break-inside-avoid hover:scale-95 flex flex-col transition-transform duration-500 ease-in-out ${isRemoving ? 'opacity-50 transform scale-75 rotate-1 bg-red-200' : ''} ${isLoaded ? '' : 'translate-x-full opacity-0'} relative  transition-all duration-500 ease-in-out`}>

            <div className="w-full">
                <div className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white drop-shadow'} rounded-lg p-6 relative z-10`}
                     style={{cursor: "auto"}}>
                    <div className="flex flex-wrap items-center">

                        {/*Product Images*/}
                        <div className="flex w-full h-48 md:h-64 lg:h-72 relative">
                            <div className={`w-8/12 mr-4 relative flex justify-center items-center object-cover object-top rounded-lg ${darkMode ? 'text-white bg-darkBgColor' : 'text-black bg-bgColor drop-shadow'}`}>
                                <img
                                    src={imageMap[product.image] || mainLogo}
                                    alt="Main Image"
                                    className="rounded-lg h-full object-cover object-top overflow-hidden"
                                />
                            </div>
                            <div className="w-4/12 h-full">
                                <div className="flex flex-col w-full h-full">

                                    <div className="flex-1 pb-2">
                                        <div className={`w-full h-full flex justify-center items-center relative object-cover object-center rounded-lg ${darkMode ? 'text-white bg-darkBgColor' : 'text-black bg-bgColor drop-shadow'}`}>
                                            <img
                                                src={imageMap[product.image] || mainLogo}
                                                className={` top-0 w-2/3`}
                                                alt="Secondary Image"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-1 pt-2">
                                        <div className={`w-full h-full flex justify-center items-center relative object-cover object-center rounded-lg ${darkMode ? 'text-white bg-darkBgColor' : 'text-black bg-bgColor drop-shadow'}`}>
                                            <img
                                                src={imageMap[product.image] || mainLogo}
                                                className={` top-0 w-2/3`}
                                                alt="Third Image"
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        {/*Product Body*/}
                        <div className="w-full pt-8 flex flex-col justify-between">
                            <div>
                                <div className="font-bold text-xl line-clamp-1">{product.name}</div>
                                <div className="flex flex-wrap text-center pt-4 mb-2">
                                    <div className={`mr-2 mb-2 rounded-full px-3 py-1 text-xs ${bgColor} hover:scale-105 text-white`}>
                                        {product.category}
                                    </div>
                                    <div className="mr-2 mb-2 rounded-full px-3 py-1 text-xs bg-green-400 hover:scale-105 text-green-900">
                                        {product.weight} lbs
                                    </div>
                                    <div className="mr-2 mb-2 rounded-full px-3 py-1 text-xs bg-green-400 hover:scale-105 text-green-900">
                                        Ph: {product.ph}
                                    </div>
                                </div>
                                <p className="text-xs leading-relaxed h-28 overflow-y-auto">
                                    {product.description}
                                </p>
                                {/*<ul className="text-xs mt-4 list-disc list-inside leading-relaxed">*/}
                                {/*    <li>Responsive</li>*/}
                                {/*    <li>Mobile-friendly</li>*/}
                                {/*    <li>Media queries</li>*/}
                                {/*    <li>20MB of JavaScript</li>*/}
                                {/*</ul>*/}
                            </div>

                            {/*SDS and Sell Sheet Buttons*/}
                            <div className="w-full sm:flex-1 grid gap-4 grid-cols-2 pt-6">
                                <Button
                                    onClick={() => onOpenModal(sellSheetMapping[product.sell])}
                                    disabled={!product.sell} // Disable if product.sell is falsy
                                    className={`${!product.sell ? 'bg-opacity-50' : 'hover:shadow-none hover:opacity-75'} hover:scale-105 flex items-center justify-center text-center relative font-bold text-sm bg-gray-200 text-gray-800 px-8 py-3 rounded-lg shadow-lg`}
                                >
                                    Sell Sheet
                                </Button>

                                <Button
                                    onClick={() => onOpenModal(SDSMapping[product.sds])}
                                    disabled={!product.sds} // Disable if product.sell is falsy
                                    className={`${!product.sds ? 'bg-opacity-50' : 'hover:shadow-none hover:opacity-75'} hover:scale-105 w-full block text-center relative text-white font-bold text-sm bg-bgLightGreen px-4 py-3 rounded-lg shadow-lg`}
                                >
                                    SDS
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProductCardV2;