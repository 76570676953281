import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useAuth} from "../contexts/authContext";
import logo from "../main-logo.png";
import {
    ArrowRightStartOnRectangleIcon,
    PlusCircleIcon,
    PlusIcon,
    UserCircleIcon,
    UserPlusIcon
} from "@heroicons/react/24/outline";
import {db} from "../firebase/firebase";
import {
    Button,
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems
} from "@headlessui/react";
import {ChevronDownIcon, XMarkIcon} from "@heroicons/react/16/solid";
import {MoonIcon, SunIcon} from "@heroicons/react/24/outline";
import {doSignOut} from "../firebase/auth";
import {useDarkMode} from "../contexts/darkModeContext";

const Header = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [fname, setFname] = useState("");
    const [time, setTime] = useState('');
    const [date, setDate] = useState('');

    const [accountLevel, setAccountLevel] = useState("");

    const [profileImage, setProfileImage] = useState('');


    const {darkMode, toggleDarkMode} = useDarkMode();


    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setProfileImage(userData.profileImageUrl);
                        setAccountLevel(userData.accountLevel);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setProfileImage(userData.profileImageUrl);
                            setAccountLevel(userData.accountLevel);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);


    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time (hours and minutes only)
            const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            setTime(currentTime);

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();

        // Update time every minute to avoid unnecessary updates every second
        const interval = setInterval(() => {
            const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            setTime(currentTime);
        }, 60000); // Update every minute

        return () => clearInterval(interval);
    }, []);

    const [currentPath, setCurrentPath] = useState(window.location.pathname); // Initialize currentPath
    const [collapse, setCollapse] = useState(true);
    const [collapseSpin, setCollapseSpin] = useState(false);

    const [collapseCustomer, setCollapseCustomer] = useState(true);
    const [collapseSpinCustomer, setCollapseSpinCustomer] = useState(false);


    // Timeout reference to clear it when necessary
    let collapseTimeout;
    let collaspeTimeoutCustomer

    const handleMouseEnter = () => {
        clearTimeout(collapseTimeout); // Clear any existing timeout to prevent premature collapsing
        setCollapse(false); // Expand immediately on hover
    };

    const handleMouseLeave = () => {
        // Set a delay before collapsing again
        setCollapseSpin(true)
        collapseTimeout = setTimeout(() => {
            setCollapse(true); // Collapse after a delay
        }, 500); // Adjust the timeout duration as needed (300ms)

        collapseTimeout = setTimeout(() => {
            setCollapseSpin(false);
        }, 1000); // Adjust the timeout duration as needed (300ms)
    };

    const handleMouseEnterCustomer = () => {
        clearTimeout(collaspeTimeoutCustomer); // Clear any existing timeout to prevent premature collapsing
        setCollapseCustomer(false); // Expand immediately on hover
    };

    const handleMouseLeaveCustomer = () => {
        // Set a delay before collapsing again
        setCollapseSpinCustomer(true);
        collaspeTimeoutCustomer = setTimeout(() => {
            setCollapseCustomer(true); // Collapse after a delay
        }, 500); // Adjust the timeout duration as needed (300ms)

        collapseTimeout = setTimeout(() => {
            setCollapseSpinCustomer(false);
        }, 1000); // Adjust the timeout duration as needed (300ms)
    };


    const [open, setOpen] = useState(false)



    return (
        <div className={`w-full ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} z-20 h-16 pr-3 pt-3 pl-3 fixed top-0 left-0 transition-all duration-500 ease-in-out`}>

            <div className={`flex flex-row ${darkMode ? 'bg-darkMainColor' : 'bg-white'} justify-between items-center w-full h-full rounded-md drop-shadow shadow-gray-900 transition-all duration-500 ease-in-out`}>

                {/*Links*/}
                <div className="w-1/3 flex flex-row">

                    {["D", "A", "S", "DIST"].includes(accountLevel) && (
                        <>
                            <Link to="/NewCustomer"
                                  className={`flex items-center p-1 hover:bg-bgDarkGreen transition-all duration-500 ease-in-out hover:scale-105 ml-5 rounded-full ${currentPath === '/NewCustomer' ? 'bg-bgDarkGreen shadow-md shadow-gray-900' : `${darkMode ? 'bg-darkBgColor bg-opacity-50 border border-borderColor border-opacity-50 drop-shadow shadow' : 'bg-bgColor border drop-shadow shadow'}`} ${collapseCustomer ? 'justify-center' : 'w-1/3'}`}
                                  onMouseEnter={handleMouseEnterCustomer}  // Expands on hover
                                  onMouseLeave={handleMouseLeaveCustomer}  // Delays collapsing after leaving
                            >
                                <div className={`w-8 h-8 rounded-full drop-shadow ${collapseCustomer ? '' : 'bg-bgLightGreen'} shadow-2xl shadow-black flex items-center justify-center transition-all duration-500 ease-in-out ${collapseCustomer ? '' : 'mr-32'}`}>
                                    <UserPlusIcon className={`w-5 flex-none ${currentPath === '/NewCustomer' ? 'text-white' : `${darkMode ? 'text-white' : ''}`} ${collapseCustomer ? 'text-black' : 'text-white'} ${collapseSpinCustomer ? 'animate-spin' : ''}`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white absolute left-10 text-sm transition-opacity duration-500 ease-in-out line-clamp-1 ${collapseCustomer ? 'hidden' : ''}`}>New Customer Form</div>
                            </Link>


                            <Link to="/NewOrder"
                                  className={`flex items-center p-1 hover:bg-bgDarkGreen transition-all duration-500 ease-in-out hover:scale-105 ml-5 mr-auto rounded-full ${currentPath === '/NewOrder' ? 'bg-bgDarkGreen shadow-md shadow-gray-900' : `${darkMode ? 'bg-darkBgColor bg-opacity-50 border border-borderColor border-opacity-50 drop-shadow shadow' : 'bg-bgColor border drop-shadow shadow'}`} ${collapse ? 'justify-center' : 'w-1/3'}`}
                                  onMouseEnter={handleMouseEnter}  // Expands on hover
                                  onMouseLeave={handleMouseLeave}  // Delays collapsing after leaving
                            >
                                <div className={`w-8 h-8 rounded-full drop-shadow ${collapse ? '' : 'bg-bgLightGreen'} shadow-2xl shadow-black flex items-center justify-center transition-all duration-500 ease-in-out ${collapse ? '' : 'mr-32'}`}>
                                    <PlusIcon className={`w-6 flex-none ${currentPath === '/NewOrder' ? 'text-white' : `${darkMode ? 'text-white' : ''}`} ${collapse ? 'text-black' : 'text-white'} ${collapseSpin ? 'animate-spin' : ''}`} aria-hidden="true"/>
                                </div>
                                <div className={`text-white text-sm absolute left-12 transition-opacity duration-500 ease-in-out line-clamp-1 ${collapse ? 'hidden' : ''}`}>New Order Form</div>
                            </Link>
                        </>
                    )}


                </div>

                {/*Middle*/}
                <div className="w-1/3 flex flex-row items-center justify-center">

                    <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor'} p-1 rounded-lg drop-shadow hover:scale-105 line-clamp-1 text-sm`}>{date}</div>

                    {/*<img*/}
                    {/*    alt=""*/}
                    {/*    src={logo}*/}
                    {/*    className="h-10 hover:rotate-2 hover:animate-heartbeat w-auto mr-16 ml-16"*/}
                    {/*/>*/}



                    {/*ToggleSwitch*/}
                    <div className="w-fit ml-10 mr-10">
                        <input
                            type="checkbox"
                            id="spray"
                            name="spray"
                            checked={darkMode}
                            onChange={toggleDarkMode} // Call the toggle function from context
                            className="sr-only w-fit"
                        />
                        <label htmlFor="spray" className="cursor-pointer">
                            <div
                                className={`mr-auto ml-auto w-14 h-8 rounded-full relative ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor drop-shadow shadow-gray-900'}`}>
                                <div className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 ${darkMode ? 'bg-darkMainColor translate-x-full animate-rollOut' : 'bg-white animate-rollIn'}`}>
                                    {darkMode ? (
                                        <MoonIcon
                                            className="w-4 h-full flex-none text-gray-300 mr-auto ml-auto mt-auto mb-auto"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <SunIcon
                                            className="w-4 h-full flex-none text-black mr-auto ml-auto mt-auto mb-auto"
                                            aria-hidden="true"
                                        />
                                    )}
                                </div>
                            </div>
                        </label>
                    </div>

                    <div className={`${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor'} p-1 rounded-lg drop-shadow hover:scale-105 line-clamp-1 text-sm`}>{time}</div>

                </div>

                {/*Profile*/}
                <div className="w-1/3 flex flex-row align-middle items-center">


                    <div className={`rounded-full bg-bgColor hover:animate-rubberBand justify-center items-center text-center w-10 h-10 ml-auto mr-2.5 drop-shadow ${darkMode ? '' : 'border-black border-1'} hover:scale-105`}>

                        {/* Display profile image if available, otherwise show placeholder */}
                        {profileImage ? (
                            <img
                                src={profileImage}
                                alt="Profile"
                                className="w-full h-full object-cover rounded-full"
                            />
                        ) : (
                            <UserCircleIcon className="text-gray-400" />
                        )}

                    </div>



                    <div className={`text-xs mr-2.5 ${darkMode ? 'text-white' : 'text-black'}`}>Welcome {fname}!</div>

                    <Button
                        className="flex text-center justify-center items-center mr-6 text-sm hover:text-opacity-50 hover:scale-105 text-red-500 rounded-md"
                        onClick={() => {
                            doSignOut().then(() => {
                                navigate('/Login');
                            });
                        }}
                    >
                        <ArrowRightStartOnRectangleIcon className={`w-6`}/>
                    </Button>

                </div>

            </div>





            <Dialog open={open} onClose={setOpen} className={`relative z-10 ${darkMode ? 'text-white' : 'text-black'}`}>

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full h-full pl-10 pb-[5.5rem]">
                            <DialogPanel
                                transition
                                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
                            >
                                <div className={`flex flex-col h-full overflow-y-scroll ${darkMode ? 'bg-darkMainColor' : 'bg-white'} py-6 shadow-2xl mt-[4.75rem] mr-3 rounded-lg`}>
                                    <div className={`px-4 sm:px-6`}>
                                        <div className="flex items-start justify-between">
                                            <DialogTitle className="text-base font-semibold">New Order Form Reference</DialogTitle>
                                            <div className="ml-3 flex h-7 items-center">
                                                <Button
                                                    type="button"
                                                    onClick={() => setOpen(false)}
                                                    className="relative rounded-md text-gray-500 hover:text-opacity-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                >
                                                    <span className="absolute -inset-2.5" />
                                                    <span className="sr-only">Close panel</span>
                                                    <XMarkIcon aria-hidden="true" className="size-6" />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>


                                    {/*Body*/}
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6 flex-col space-y-12">

                                        {/*Green*/}
                                        <div className={`flex flex-row items-center`}>

                                            <div className={`mr-6`}>
                                                <div className={`flex-none rounded-full p-1 bg-emerald-500/30`}>
                                                    <div className={`size-2 rounded-full bg-emerald-500`}/>
                                                </div>
                                            </div>

                                            <div className={``}>
                                                <div className={`font-bold text-emerald-500`}>Green Indicators</div>
                                                <div className={`text-sm ml-3 text-gray-500`}>These indicators appear in the corner of fields that are correctly filled out and properly formatted. They signify that all necessary information for these fields has been provided, ensuring accurate order processing. On a product input card, their presence confirms that pricing is available for the selected item within the specified industry.</div>
                                            </div>

                                        </div>

                                        {/*Red*/}
                                        <div className={`flex flex-row items-center`}>

                                            <div className={`mr-6`}>
                                                <div className={`flex-none rounded-full p-1 bg-red-500/30 animate-heartbeat`}>
                                                    <div className={`size-2 rounded-full bg-red-400`}/>
                                                </div>
                                            </div>

                                            <div className={``}>
                                                <div className={`font-bold text-red-500`}>Red Indicators</div>
                                                <div className={`text-sm ml-3 text-gray-500`}>These pulsating indicators appear in the corner of fields that are either incorrectly formatted or left incomplete. They highlight essential fields required for proper order formatting. Additionally, if these indicators are present on a product input card, it signifies that pricing is unavailable for the selected item within the specified industry.</div>
                                            </div>

                                        </div>

                                        {/*Blue*/}
                                        <div className={`flex flex-row items-center`}>

                                            <div className={`mr-6`}>
                                                <div className={`flex-none rounded-full p-1 bg-blue-500/30 animate-heartbeat`}>
                                                    <div className={`size-2 rounded-full bg-blue-500`}/>
                                                </div>
                                            </div>

                                            <div className={``}>
                                                <div className={`font-bold text-blue-500`}>Blue Indicators [Discount]</div>
                                                <div className={`text-sm ml-3 text-gray-500`}>These indicators appear above the total price fields within product input cards. Their presence signifies that a discount has been applied to the corresponding line item in the order.</div>
                                            </div>

                                        </div>

                                        {/*Yellow*/}
                                        <div className={`flex flex-row items-center`}>

                                            <div className={`mr-6`}>
                                                <div className={`flex-none rounded-full p-1 bg-yellow-500/30`}>
                                                    <div className={`size-2 rounded-full bg-yellow-500`}/>
                                                </div>
                                            </div>

                                            <div className={``}>
                                                <div className={`font-bold text-yellow-500`}>Yellow Indicators</div>
                                                <div className={`text-sm ml-3 text-gray-500`}>These are above fields that are correctly formatted and will produce a good Order and or Quote.</div>
                                            </div>

                                        </div>

                                    </div>


                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </div>
            </Dialog>



        </div>
    );
};

export default Header;
