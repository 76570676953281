import React, {useState} from 'react';

import SideBar from "./SideBar";
import {useDarkMode} from "../contexts/darkModeContext";
import {Button, Input, Select} from "@headlessui/react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";
import programsData from "../programs.json";
import ProvenCard from "./Proven/ProvenCard";



const Proven = () => {

    const { darkMode } = useDarkMode();

    const [programs] = useState(programsData.programs);
    const [filteredPrograms, setFilteredPrograms] = useState(programsData.programs);
    const [searchQuery, setSearchQuery] = useState("");

    const filterProgramsByCatagory = (category) => {
        const filtered = programs.filter(product => category === '' || product.category === category);
        setFilteredPrograms(filtered);
    }

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = programs.filter((product) =>
            product.name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredPrograms(filtered);
    }
    


    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-screen overflow-y-hidden w-full mt-16 transition-all duration-500 ease-in-out`}>

            <SideBar/>

            <div className="animate-fadeIn flex flex-col w-full max-w-[calc(100%-6rem)] h-full mt-3 mb-3 mr-3">

                {/*Customers Header*/}
                <div className="w-full h-fit flex mb-3">

                    <div className={`text-2xl w-52 ${darkMode ? 'text-white' : 'text-black'} mt-auto mb-auto flex flex-row line-clamp-1 overflow-x-scroll`}>Programs <span className={`text-sm ml-3 mt-auto mb-auto font-semibold`}>[{programs.length}]</span></div>

                    {/*Categories*/}
                    <div className={`text-xl ${darkMode ? 'bg-darkMainColor border border-gray-900 text-white' : 'bg-white border text-black'} w-full flex rounded-md border drop-shadow shadow-gray-900 py-1`}>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Greens</div>
                            <div className="text-sm rounded-md border-green-600 border-2 bg-green-600 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Greens').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Stress</div>
                            <div className="text-sm rounded-md border-blue-800 border-2 bg-blue-800 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Stress Reduction').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Aerification</div>
                            <div className="text-sm rounded-md border-gray-700 border-2 bg-gray-700 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Aerification').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">LDS</div>
                            <div className="text-sm rounded-md border-red-800 border-2 bg-red-800 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'LDS Reduction').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Microbial</div>
                            <div className="text-sm rounded-md border-purple-800 border-2 bg-purple-800 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Microbial').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">GreenUp</div>
                            <div className="text-sm rounded-md border-yellow-700 border-2 bg-yellow-700 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Spring Greenup').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Grow-In</div>
                            <div className="text-sm rounded-md border-green-700 border-2 bg-green-700 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Grow-In').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Bunkers</div>
                            <div className="text-sm rounded-md border-teal-900 border-2 bg-teal-900 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Bunkers').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Warm Turf</div>
                            <div className="text-sm rounded-md border-teal-700 border-2 bg-teal-700 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Warm Season Turf').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Fairways</div>
                            <div className="text-sm rounded-md border-emerald-700 border-2 bg-emerald-700 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Fairways').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Tees</div>
                            <div className="text-sm rounded-md border-indigo-800 border-2 bg-indigo-800 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Tees').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Flowers</div>
                            <div className="text-sm rounded-md border-pink-700 border-2 bg-pink-700 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Flowers').length}</div>
                        </div>

                        <div className={`flex w-full flex-col items-center align-middle justify-center h-full border-r ${darkMode ? 'border-borderColor' : 'border-r-2'}`}>
                            <div className="text-sm line-clamp-1">Trees</div>
                            <div className="text-sm rounded-md border-amber-800 border-2 bg-amber-800 bg-opacity-20 px-2 line-clamp-1">{programs.filter((program) => program.category === 'Trees').length}</div>
                        </div>

                    </div>

                </div>

                {/*Search bar row*/}
                <div className={`w-full h-fit flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>

                    {/*Search Bar*/}
                    <div className="w-3/4 mr-1.5 h-fit text-sm">
                        {/*<div className="text-sm mb-1">Product</div>*/}

                        <div className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} w-full rounded-md flex items-center justify-center hover:scale-y-105 drop-shadow shadow-gray-900`}>
                            <MagnifyingGlassIcon className="text-gray-600 h-6 mr-6 ml-6"/>

                            <div className="w-full">

                                <Input
                                    className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} rounded-md p-1.5 w-full focus:outline-none`}
                                    placeholder="Search programs By Name"
                                    value={searchQuery}
                                    onChange={(e) => handleSearch(e.target.value)}
                                />

                            </div>

                        </div>

                    </div>

                    {/*Category*/}
                    <div className="w-1/4 ml-1.5 h-full text-sm">

                        <div className="flex flex-col w-full mr-3 h-full">
                            <Select
                                className={`${darkMode ? 'text-white bg-darkMainColor' : 'text-black bg-white'} focus:outline-none h-full drop-shadow shadow-gray-900 py-1 px-4 rounded-md hover:scale-y-105`}
                                // value={status}
                                onChange={(e) => filterProgramsByCatagory(e.target.value)}
                            >
                                <option value="">Select Category...</option>
                                <option value="Greens">Greens</option>
                                <option value="Stress Reduction">Stress Reduction</option>
                                <option value="Aerification">Aerification</option>
                                <option value="LDS Reduction">LDS Reduction</option>
                                <option value="Microbial">Microbial</option>
                                <option value="Spring Greenup">Spring Greenup</option>
                                <option value="Grow-In">Grow-In</option>
                                <option value="Bunkers">Bunkers</option>
                                <option value="Warm Season Turf">Warm Season Turf</option>
                                <option value="Fairways">Fairways</option>
                                <option value="Tees">Tees</option>
                                <option value="Flowers">Flowers</option>
                                <option value="Trees">Trees</option>
                            </Select>
                        </div>

                    </div>


                </div>

                {/*Catalog Body*/}
                <div className="w-full h-full rounded-md">

                    {/*PRODUCT INPUT*/}
                    <div className="w-full flex h-full flex-col">


                        <div className={`w-full h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg`}>

                            {/*CUSTOMERS MAP*/}
                            <div className={`overflow-y-auto h-full ${darkMode ? 'text-white' : 'text-black'} rounded-lg max-h-[calc(100vh-12.5rem)] scroll`}>

                                <div>

                                    {filteredPrograms.length === 0 ? (
                                        // Display custom dialog if sprays array is empty
                                        <div className="flex w-full h-full justify-center items-center py-10">

                                            <div className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                    <p className="text-xl">No Programs Present</p>
                                                    <p className="text-lg mt-2">Why may this occur?</p>
                                                    <p className="text-xs">1] There appears to be an issue loading programs</p>
                                                    <p className="text-xs">2] There does not seem to be any proven programs that match your search. </p>
                                                    <p className="text-xs">3] There are no proven programs that match your filtering. </p>

                                                </div>
                                            </div>

                                        </div>
                                    ) : (
                                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-3 auto-rows-auto">
                                            {filteredPrograms
                                                .sort((a, b) => a.id - b.id) // Ensure items are sorted by ID
                                                .map((program, index) => (
                                                    <ProvenCard
                                                        key={index}
                                                        program={program}/>
                                                ))}
                                        </div>

                                    )}

                                </div>

                            </div>


                        </div>


                    </div>

                </div>


            </div>



        </div>
    )
};


export default Proven;
