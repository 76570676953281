import React, {useState} from 'react';
import logo from "../main-logo.png";
import course from "../course.jpg";
import {Link, Navigate} from "react-router-dom";
import {useAuth} from "../contexts/authContext";
import {signInWithEmailAndPassword} from "firebase/auth";
import { auth } from "../firebase/firebase";
import {Button} from "@headlessui/react";


const Login = () => {

    const { userLoggedIn } = useAuth();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isSigningIn, setIsSigningIn] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (!isSigningIn){
            setIsSigningIn(true);
            try {
                await signInWithEmailAndPassword(auth, email, password);  // Pass auth, email, password
            } catch (error) {
                console.error("Sign in failed:", error);
                setIsSigningIn(false);  // Reset sign-in state on failure
            }
        }
    }

    return (
        <>

            {userLoggedIn && <Navigate to={'/Home'} replace={true} />}




            <div className="flex min-h-full flex-1 bg-bgColor animate-zoomIn">

                <Link className={`absolute right-0 z-50 mr-8 mt-8 rounded-lg hover:text-green-600 px-6 py-1`}
                      to={'/LandingPage'}>
                    Return to Home <span aria-hidden="true">&rarr;</span>
                </Link>

                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                alt="Your Company"
                                src={logo}
                                className="h-10 w-auto"
                            />
                            <h2 className="mt-8 text-2xl/9 font-bold tracking-tight text-gray-900">Sign in to your account</h2>
                            <p className="mt-2 text-sm/6 text-gray-500">
                                New Employee?{' '}
                                <a href="#" className="font-semibold text-green-600 hover:text-indigo-500">
                                    Contact to Gain Access
                                </a>
                            </p>
                        </div>

                        <div className="mt-10">
                            <div>
                                <form onSubmit={onSubmit} method="POST" className="space-y-6">
                                    <div>
                                        <label htmlFor="email"
                                               className="block text-sm font-medium leading-6 text-gray-900">
                                            Email address
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                name="email"
                                                type="email"
                                                required
                                                autoComplete="email"
                                                value={email} onChange={(e) => setEmail(e.target.value)}
                                                className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                                            Password
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="password"
                                                name="password"
                                                type="password"
                                                required
                                                autoComplete="current-password"
                                                value={password} onChange={(e) => setPassword(e.target.value)}
                                                className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center justify-between">

                                        <div className="ml-auto text-sm/6">
                                            <a href="#" className="font-semibold text-green-600 hover:text-indigo-500">
                                                Forgot password?
                                            </a>
                                        </div>
                                    </div>

                                    <div>
                                        <Button
                                            type="submit"
                                            disabled={isSigningIn}
                                            className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        >
                                            Sign in
                                        </Button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="animate-slideInRight relative hidden w-0 flex-1 lg:block border-l-4 border-neutral-400">
                    <img
                        alt=""
                        src={course}
                        className="absolute inset-0 size-full object-cover"
                    />
                </div>
            </div>





            {/*<div className="bg-bgColor flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">*/}


            {/*    <header className="absolute inset-x-0 top-0 z-50">*/}
            {/*        <nav aria-label="Global" className="flex items-center justify-between p-6 lg:px-8">*/}

            {/*            <div className="flex lg:flex-1">*/}

            {/*                <img*/}
            {/*                    alt=""*/}
            {/*                    src={logo}*/}
            {/*                    className="m-1.5 p-1.5 h-16 w-auto"*/}
            {/*                />*/}

            {/*            </div>*/}

            {/*            <div className="hidden lg:flex lg:flex-1 lg:justify-end">*/}
            {/*                <Link className="text-sm font-semibold leading-6 text-gray-900"*/}
            {/*                      to={'/LandingPage'}>*/}
            {/*                    Return to Home Page <span aria-hidden="true">&rarr;</span>*/}
            {/*                </Link>*/}
            {/*            </div>*/}
            {/*        </nav>*/}
            {/*    </header>*/}


            {/*    <div className="sm:mx-auto sm:w-full sm:max-w-sm">*/}
            {/*        <img*/}
            {/*            alt="Plant Food Company Inc."*/}
            {/*            src={logo}*/}
            {/*            className="mx-auto h-24 w-auto"*/}
            {/*        />*/}
            {/*        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">*/}
            {/*            Sign in to your account*/}
            {/*        </h2>*/}
            {/*    </div>*/}

            {/*    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">*/}
            {/*        <form onSubmit={onSubmit} method="POST" className="space-y-6">*/}
            {/*            <div>*/}
            {/*                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">*/}
            {/*                    Email address*/}
            {/*                </label>*/}
            {/*                <div className="mt-2">*/}
            {/*                    <input*/}
            {/*                        id="email"*/}
            {/*                        name="email"*/}
            {/*                        type="email"*/}
            {/*                        required*/}
            {/*                        autoComplete="email"*/}
            {/*                        value={email} onChange={(e) => setEmail(e.target.value)}*/}
            {/*                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div>*/}
            {/*                <div className="flex items-center justify-between">*/}
            {/*                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">*/}
            {/*                        Password*/}
            {/*                    </label>*/}
            {/*                    <div className="text-sm">*/}
            {/*                        /!*<a href="#" className="font-semibold text-bgDarkGreen hover:text-bgLightGreen">*!/*/}
            {/*                        /!*    Forgot password?*!/*/}
            {/*                        /!*</a>*!/*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className="mt-2">*/}
            {/*                    <input*/}
            {/*                        id="password"*/}
            {/*                        name="password"*/}
            {/*                        type="password"*/}
            {/*                        required*/}
            {/*                        autoComplete="current-password"*/}
            {/*                        value={password} onChange={(e) => setPassword(e.target.value)}*/}
            {/*                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div>*/}
            {/*                <button*/}
            {/*                    type="submit"*/}
            {/*                    disabled={isSigningIn}*/}
            {/*                    className="flex w-full justify-center rounded-md bg-bgDarkGreen px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-bgLightGreen focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
            {/*                >*/}
            {/*                    Sign in*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </form>*/}

            {/*        <p className="mt-10 text-center text-sm text-gray-500">*/}
            {/*            Not a Sales Staff?{' '}*/}
            {/*            <Link className="font-semibold leading-6 text-bgDarkGreen hover:text-bgLightGreen"*/}
            {/*                  to={'/SignUp'}>*/}
            {/*                Contact to Gain Access*/}
            {/*            </Link>*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
};


export default Login;
