import React, {useEffect, useState} from 'react';

import SideBar from "./SideBar";
import {db} from "../firebase/firebase";
import {useAuth} from "../contexts/authContext";
import {
    ArrowLongDownIcon,
    CheckCircleIcon,
    CurrencyDollarIcon,
    PhoneIcon, ShieldCheckIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {Button, Select} from "@headlessui/react";
import fipsCode from '../fipCodes.json';
import AddressInput from "./CustomerForm/AddressInput";
import {CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon, ServerIcon} from "@heroicons/react/16/solid";
import websiteSRC from "../website.png"
import {useDarkMode} from "../contexts/darkModeContext";



const NewCustomer = () => {

    const { currentUser } = useAuth();

    const { darkMode } = useDarkMode();

    const [date, setDate] = useState("");

    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [fullname, setFullname] = useState("");
    const [salesCode, setSalesCode] = useState("");
    const [salesEmail, setSalesEmail] = useState("");


    // MAIN DATA POINTS
    const [name, setName] = useState("");
    const [managementName, setManagementName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [selectedState, setSelectedState] = useState('NJ'); // Preselect NJ
    const [zip, setZip] = useState("");
    const [selectedCounty, setSelectedCounty] = useState('');
    const [fips, setFips] = useState("");
    const [country, setCountry] = useState("United States");
    const [industry, setIndustry] = useState("");


    // CONTACTS DATA POINTS
    const [contact, setContact] = useState("");
    const [contactPosition, setContactPosition] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [billingContact, setBillingContact] = useState("");
    const [billingPhone, setBillingPhone] = useState("");
    const [invoiceEmails, setInvoiceEmails] = useState("");
    const [statementEmails, setStatementEmails] = useState("");

    const [website, setWebsite] = useState("");


    // SHIPPING DATA POINTS
    const [shippingAddress, setShippingAddress] = useState([]);


    // TAXES DATA POINTS
    const [taxID, setTaxID] = useState("");
    const [salesTaxStatus, setSalesTaxStatus] = useState("");
    const [taxExempCode, setTaxExempCode] = useState("");


    // PAYMENT DATA POINTS
    const [paymentType, setPaymentType] = useState("");


    // DELIVERY DATA POINTS
    const [TTA, setTTA] = useState(false);
    const [LAS, setLAS] = useState(false);
    const [LDA, setLDA] = useState(false);
    const [PJR, setPJR] = useState(false);
    const [DAR, setDAR] = useState(false);
    const [HSA, setHSA] = useState(false);
    const [FLC, setFLC] = useState(false);
    const [deliveryInstructions, setDeliveryInstructions] = useState("");

    const toggleTTA = () => {setTTA(!TTA)};
    const toggleLAS = () => {setLAS(!LAS)};
    const toggleLDA = () => {setLDA(!LDA)};
    const togglePJR = () => {setPJR(!PJR)};
    const toggleDAR = () => {setDAR(!DAR)};
    const toggleHSA = () => {setHSA(!HSA)};
    const toggleFLC = () => {setFLC(!FLC)};


    const handleStateChange = (event) => {
        const stateCode = event.target.value;
        setSelectedState(stateCode);
        setSelectedCounty(''); // Reset county selection when state changes
    };

    const handleCountyChange = (event) => {
        const countyName = event.target.value;
        setSelectedCounty(countyName);

        // Find the selected county's data based on the selected county name
        const countyData = filteredCounties.find(county => county.county === countyName);

        // Set the fips code if county data exists, otherwise set it to an empty string
        if (countyData) {
            setFips(countyData.fips);
        } else {
            setFips("");  // Handle case where no county is selected or found
        }
    };

    // Filter counties based on the selected state
    const filteredCounties = fipsCode.county.filter(county => county.state === selectedState);


    const formatFipsCode = (fips) => {
        if (fips.length === 5) {
            return `${fips.slice(0, 2)}-${fips.slice(2)}`;
        }
        return fips;
    };


    const features = [
        {
            name: 'Representative Assistance',
            description:
                'After submitting your form, a PlantFood Company representative will contact you to help complete your financial section and guide you through the credit application process.',
            icon: PhoneIcon, // Consider using a phone or contact-related icon
        },
        {
            name: 'Secure and Confidential',
            description:
                'Your financial information will be handled with the utmost care. Our process ensures your data is securely protected every step of the way.',
            icon: ShieldCheckIcon, // A security-related icon like a shield
        },
    ];



    const tabOrder = ['main', 'contacts', 'shipping', 'taxes', 'delivery'];

    const handleNextTab = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        if (currentIndex < tabOrder.length - 1) {
            setActiveTab(tabOrder[currentIndex + 1]);
        }
    };

    const handlePreviousTab = () => {
        const currentIndex = tabOrder.indexOf(activeTab);
        if (currentIndex > 0) {
            setActiveTab(tabOrder[currentIndex - 1]);
        }
    };



    useEffect(() => {
        const fetchUserName = async () => {
            if (currentUser) {
                try {
                    // Check if user data is in localStorage
                    const storedUserData = localStorage.getItem(`userData_${currentUser.uid}`);

                    if (storedUserData) {
                        // Use the stored user data
                        const userData = JSON.parse(storedUserData);
                        setFname(userData.fname);
                        setLname(userData.lname);
                        setSalesCode(userData.salesCode);
                        setSalesEmail(userData.email);

                        setFullname(userData.fname + " " + userData.lname);
                    } else {
                        // Fetch user document from Firestore based on the current user's UID
                        const userDoc = await db.collection('Users').doc(currentUser.uid).get();
                        if (userDoc.exists) {
                            // Get the user's name from the document data
                            const userData = userDoc.data();
                            setFname(userData.fname);
                            setLname(userData.lname);
                            setSalesCode(userData.salesCode);
                            setSalesEmail(userData.email);

                            setFullname(userData.fname + " " + userData.lname);

                            // Save user data to localStorage
                            localStorage.setItem(`userData_${currentUser.uid}`, JSON.stringify(userData));
                        } else {
                            console.log('User document not found');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserName();
    }, [currentUser]);

    const [activeTab, setActiveTab] = useState('main');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const onSubmitMain = async (e) => {
        e.preventDefault();

        const collectionRef = db.collection("Customers").doc(currentUser.uid).collection("pending");

        const parsedFormData = {

            // Sales Info
            salesRep: fullname,
            salesCode: salesCode,
            status: "pending",
            salesEmail: salesEmail,

            // Main
            name: name,
            managementName: managementName,
            address1: address1,
            address2: address2,
            city: city,
            state: selectedState, // Using selectedState for state
            zip: zip,
            county: selectedCounty, // Using selectedCounty for county
            fips: fips,
            country: country,
            industry: industry,

            // Contacts
            contact: contact,
            contactPosition: contactPosition,
            contactPhone: contactPhone,
            contactEmail: contactEmail,
            billingContact: billingContact,
            billingPhone: billingPhone,
            invoiceEmails: invoiceEmails,
            statementEmails: statementEmails,
            website: website,

            // Shipping
            ShippingAddresses: shippingAddress,

            // Finances
            taxID: taxID,
            salesTaxStatus: salesTaxStatus,
            taxExempCode: taxExempCode,
            paymentType: paymentType,

            // Delivery
            TTA: TTA,
            LAS: LAS,
            LDA: LDA,
            PJR: PJR,
            DAR: DAR,
            HSA: HSA,
            FLC: FLC,
            deliveryInstructions: deliveryInstructions,


        };

        try {
            await collectionRef.add(parsedFormData);
            // handleNextTab();
            alert(`Customer Added to the Cloud`); // Optional: alert for user feedback

        } catch (error) {
            console.error('Error registering client:', error.message);
            // setIsRegistered(false);
        }
    };

    const handleAddComponent = () => {
        const newId = shippingAddress.length + Math.random(); // Create a unique id for each new address
        setShippingAddress([...shippingAddress, { id: newId }]); // Add a new address with its unique id
    };

// Function to remove an address input component based on ID
    const handleRemoveComponent = (idToRemove) => {
        setShippingAddress(shippingAddress.filter(item => item.id !== idToRemove)); // Remove the item with the matching id
    };

// Function to handle input changes from child components
    const handleAddressChange = (index, updatedAddress) => {
        const updatedAddresses = [...shippingAddress];
        updatedAddresses[index] = { ...updatedAddresses[index], ...updatedAddress }; // Ensure we maintain other properties like id
        setShippingAddress(updatedAddresses);
    };



    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter
            // handleNextTab(); // Navigate to the next tab
        }
    };

    // fetch time and date
    useEffect(() => {
        const fetchWeather = async () => {
            // Get current time

            // Get current date
            const currentDate = new Date().toLocaleDateString();
            setDate(currentDate);
        };

        fetchWeather();
    }, []);


    return (
        <div className={`${darkMode ? 'bg-darkBgColor' : 'bg-bgColor'} flex h-full w-full mt-16 overflow-y-hidden transition-all duration-500 ease-in-out`}>

            <SideBar/>

            <div className="flex flex-col w-full mt-3 mb-3 mr-3 animate-fadeIn">

                {/*ORDER FORM HEADER [Sales Rep Info]*/}
                <div className={`w-full flex mb-3 ${darkMode ? 'text-white' : 'text-black'}`}>
                    <div className="text-2xl w-1/4">New Customer Form</div>
                    <div className={`text-xl ${darkMode ? 'bg-darkMainColor' : 'bg-white border'} w-3/4 flex rounded-md drop-shadow shadow-gray-900`}>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3">Sales Rep:</div>
                            <div className="text-sm rounded-md border px-2">{fname + " " + lname}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3">Sales Rep Code:</div>
                            <div className="text-sm rounded-md border px-2">{salesCode}</div>
                        </div>

                        <div className="flex w-full flex-row items-center align-middle justify-center h-full">
                            <div className="font-semibold text-sm mr-3">Date:</div>
                            <div className="text-sm rounded-md border px-2">{date}</div>
                        </div>

                    </div>
                </div>

                <form onSubmit={onSubmitMain} method="POST" onKeyDown={handleKeyDown} className="h-full w-full flex flex-col justify-between">


                    <div className="flex flex-row w-full h-full">


                        {/* Tabs Navigation */}
                        <ul className={`me-3 flex list-none ${darkMode ? 'bg-darkMainColor' : 'bg-white border'} rounded-md justify-center items-center flex-col flex-wrap p-3 drop-shadow shadow-gray-900 max-h-[calc(100vh-3.5rem)] scroll overflow-y-auto`} role="tablist">

                            {/*MAIN*/}
                            <li role="presentation" className="flex-grow text-center">
                                <button
                                    onClick={() => handleTabClick('main')}
                                    className={`my-2 block hover:scale-105 px-7 py-2 text-xs rounded-full bg-bgColor font-medium uppercase leading-tight ${
                                        activeTab === 'main'
                                            ? 'bg-bgLightGreen text-white text-primary'
                                            : `hover:bg-opacity-80 ${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'}`
                                    }`}
                                    role="tab"
                                    aria-controls="tabs-main"
                                    aria-selected={activeTab === 'main'}
                                >
                                    Main
                                </button>
                            </li>

                            <li role="presentation" className="flex-grow  text-center">
                                <ArrowLongDownIcon className="h-8 text-bgLightGreen"/>
                            </li>

                            {/*CONTACTS*/}
                            <li role="presentation" className="flex-grow text-center">
                                <button
                                    onClick={() => handleTabClick('contacts')}
                                    className={`my-2 hover:scale-105 block px-7 py-2 text-xs rounded-full bg-bgColor font-medium uppercase leading-tight ${
                                        activeTab === 'contacts'
                                            ? 'bg-bgLightGreen text-white text-primary'
                                            : `hover:bg-opacity-80 ${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'}`
                                    }`}
                                    role="tab"
                                    aria-controls="tabs-contacts"
                                    aria-selected={activeTab === 'contacts'}
                                >
                                    Contacts
                                </button>
                            </li>

                            <li role="presentation" className="flex-grow  text-center">
                                <ArrowLongDownIcon className="h-8 text-bgLightGreen"/>
                            </li>

                            {/*SHIPPING*/}
                            <li role="presentation" className="flex-grow text-center">
                                <button
                                    onClick={() => handleTabClick('shipping')}
                                    className={`my-2 hover:scale-105 block px-7 py-2 text-xs rounded-full bg-bgColor font-medium uppercase leading-tight ${
                                        activeTab === 'shipping'
                                            ? 'bg-bgLightGreen text-white text-primary'
                                            : `hover:bg-opacity-80 ${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'}`
                                    }`}
                                    role="tab"
                                    aria-controls="tabs-shipping"
                                    aria-selected={activeTab === 'shipping'}
                                >
                                    Shipping
                                </button>
                            </li>

                            <li role="presentation" className="flex-grow  text-center">
                                <ArrowLongDownIcon className="h-8 text-bgLightGreen"/>
                            </li>

                            {/*TAXES*/}
                            <li role="presentation" className="flex-grow text-center">
                                <button
                                    onClick={() => handleTabClick('taxes')}
                                    className={`my-2 hover:scale-105 block px-7 py-2 text-xs rounded-full bg-bgColor font-medium uppercase leading-tight ${
                                        activeTab === 'taxes'
                                            ? 'bg-bgLightGreen text-white text-primary'
                                            : `hover:bg-opacity-80 ${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'}`
                                    }`}
                                    role="tab"
                                    aria-controls="tabs-taxes"
                                    aria-selected={activeTab === 'taxes'}
                                >
                                    Finances
                                </button>
                            </li>

                            <li role="presentation" className="flex-grow  text-center">
                                <ArrowLongDownIcon className="h-8 text-bgLightGreen"/>
                            </li>


                            {/*DELIVERY*/}
                            <li role="presentation" className="flex-grow text-center">
                                <button
                                    onClick={() => handleTabClick('delivery')}
                                    className={`my-2 hover:scale-105 block px-7 py-2 text-xs rounded-full bg-bgColor font-medium uppercase leading-tight ${
                                        activeTab === 'delivery'
                                            ? 'bg-bgLightGreen text-white text-primary'
                                            : `hover:bg-opacity-80 ${darkMode ? 'bg-darkBgColor text-white' : 'bg-bgColor text-black'}`
                                    }`}
                                    role="tab"
                                    aria-controls="tabs-delivery"
                                    aria-selected={activeTab === 'delivery'}
                                >
                                    Delivery
                                </button>
                            </li>

                        </ul>


                        {/* Tabs Content */}
                        <div className={`rounded-md p-3 ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} w-full drop-shadow shadow-gray-900 max-h-[calc(100vh-5.5rem)] scroll overflow-y-auto`}>


                            {/*MAIN DONE*/}
                            <div className={`${
                                activeTab === 'main' ? 'block opacity-100' : 'hidden opacity-0'
                            } transition-opacity duration-150 ease-linear h-full`}
                                 id="tabs-home"
                                 role="tabpanel"
                                 aria-labelledby="tabs-main"
                            >

                                <div className="h-full w-full flex flex-col justify-between">
                                    {/* Your tab content goes here */}
                                    <div className="flex-grow rounded-lg max-h-[calc(100vh-13.5rem)] scroll overflow-y-auto">
                                        {/* Replace with the content of the tab */}

                                        <div className="flex flex-col">

                                            <div className="w-full flex flex-row">

                                                {/*Customer Name*/}
                                                <div className="w-2/3 mr-2.5">
                                                    <label htmlFor="address"
                                                           className="block text-sm font-medium leading-6">
                                                        Customer Name
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            required
                                                            placeholder="Customer Name"
                                                            value={name} onChange={(e) => setName(e.target.value)}
                                                            className={`block ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                        />
                                                    </div>
                                                </div>

                                                {/*Management Name*/}
                                                <div className="w-1/3 ml-2.5">
                                                    <label htmlFor="region"
                                                           className="block text-sm font-medium leading-6">
                                                        Management Group Name
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            placeholder="Optional"
                                                            value={managementName} onChange={(e) => setManagementName(e.target.value)}
                                                            className={`block ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <label htmlFor="region" className="block text-sm font-medium leading-6 mt-5 mb-2">
                                                BILLING ADDRESS
                                            </label>

                                            {/*ADDRESS AREA*/}
                                            <div className={`w-full flex flex-col justify-between ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} p-3 rounded-lg`}>

                                                {/*Address 1*/}
                                                <div className="w-full flex flex-row">

                                                    <div className="w-full">
                                                        <label htmlFor="address"
                                                               className="block text-sm font-medium leading-6">
                                                            Street Address [1]:
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                required
                                                                placeholder="Street Address 1"
                                                                value={address1} onChange={(e) => setAddress1(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*Address 2*/}
                                                <div className="w-full flex flex-row mt-5">

                                                    <div className="w-full">
                                                        <label htmlFor="address"
                                                               className="block text-sm font-medium leading-6">
                                                            Street Address [2]:
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                placeholder="Optional"
                                                                value={address2} onChange={(e) => setAddress2(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>


                                                <div className="w-full flex flex-row mt-5">

                                                    {/*City*/}
                                                    <div className="w-1/2 mr-2.5">
                                                        <label htmlFor="address"
                                                               className="block text-sm font-medium leading-6">
                                                            City
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                placeholder="City"
                                                                required
                                                                value={city} onChange={(e) => setCity(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>

                                                    {/*State*/}
                                                    <div className="w-1/4 ml-2.5">
                                                        <label htmlFor="region"
                                                               className="block text-sm font-medium leading-6">
                                                            State
                                                        </label>
                                                        <div className="mt-2">
                                                            <Select
                                                                id="state"
                                                                value={selectedState}
                                                                onChange={handleStateChange}
                                                                className={`block h-full w-full p-2 rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} text-gray-900 shadow-sm`}
                                                            >
                                                                <option value="AL">Alabama</option>
                                                                <option value="AK">Alaska</option>
                                                                <option value="AZ">Arizona</option>
                                                                <option value="AR">Arkansas</option>
                                                                <option value="CA">California</option>
                                                                <option value="CO">Colorado</option>
                                                                <option value="CT">Connecticut</option>
                                                                <option value="DE">Delaware</option>
                                                                <option value="FL">Florida</option>
                                                                <option value="GA">Georgia</option>
                                                                <option value="HI">Hawaii</option>
                                                                <option value="ID">Idaho</option>
                                                                <option value="IL">Illinois</option>
                                                                <option value="IN">Indiana</option>
                                                                <option value="IA">Iowa</option>
                                                                <option value="KS">Kansas</option>
                                                                <option value="KY">Kentucky</option>
                                                                <option value="LA">Louisiana</option>
                                                                <option value="ME">Maine</option>
                                                                <option value="MD">Maryland</option>
                                                                <option value="MA">Massachusetts</option>
                                                                <option value="MI">Michigan</option>
                                                                <option value="MN">Minnesota</option>
                                                                <option value="MS">Mississippi</option>
                                                                <option value="MO">Missouri</option>
                                                                <option value="MT">Montana</option>
                                                                <option value="NE">Nebraska</option>
                                                                <option value="NV">Nevada</option>
                                                                <option value="NH">New Hampshire</option>
                                                                <option value="NJ">New Jersey</option>
                                                                <option value="NM">New Mexico</option>
                                                                <option value="NY">New York</option>
                                                                <option value="NC">North Carolina</option>
                                                                <option value="ND">North Dakota</option>
                                                                <option value="OH">Ohio</option>
                                                                <option value="OK">Oklahoma</option>
                                                                <option value="OR">Oregon</option>
                                                                <option value="PA">Pennsylvania</option>
                                                                <option value="RI">Rhode Island</option>
                                                                <option value="SC">South Carolina</option>
                                                                <option value="SD">South Dakota</option>
                                                                <option value="TN">Tennessee</option>
                                                                <option value="TX">Texas</option>
                                                                <option value="UT">Utah</option>
                                                                <option value="VT">Vermont</option>
                                                                <option value="VA">Virginia</option>
                                                                <option value="WA">Washington</option>
                                                                <option value="WV">West Virginia</option>
                                                                <option value="WI">Wisconsin</option>
                                                                <option value="WY">Wyoming</option>
                                                            </Select>
                                                        </div>
                                                    </div>

                                                    {/*Zipcode*/}
                                                    <div className="w-1/4 ml-2.5">
                                                        <label htmlFor="region"
                                                               className="block text-sm font-medium leading-6">
                                                            Zip Code
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                required
                                                                placeholder="Zip Code"
                                                                value={zip} onChange={(e) => setZip(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <label htmlFor="region" className="block text-sm font-medium leading-6 mt-5 mb-2">
                                                FIPS CODE
                                            </label>

                                            {/*FIPS CODE*/}
                                            <div className={`w-full flex ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} p-3 rounded-lg flex-row`}>

                                                {/*County*/}
                                                <div className="w-2/3">
                                                    <label htmlFor="region"
                                                           className="block text-sm font-medium leading-6">
                                                        County
                                                    </label>
                                                    <div className="mt-2">
                                                        <select
                                                            id="county"
                                                            value={selectedCounty}
                                                            onChange={handleCountyChange}
                                                            className={`block h-full w-full p-2 focus:outline-none rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} text-gray-900 shadow-sm`}
                                                        >
                                                            <option value="">--Select a County--</option>
                                                            {filteredCounties.map(county => (
                                                                <option key={county.fips}
                                                                        value={county.county}>{county.county}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                {/*FipsCode*/}
                                                <div className="w-1/3 ml-5">
                                                    <label htmlFor="region"
                                                           className="block text-sm font-medium leading-6">
                                                        Fips Code
                                                    </label>
                                                    <div className="mt-2">
                                                        {fips && (
                                                            <div>
                                                                <h3 className={`block w-full p-2 rounded-md ${darkMode ? 'bg-darkMainColor' : 'bg-white border'} py-1.5 shadow-sm`}>
                                                                    {formatFipsCode(fips)}
                                                                </h3>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>

                                            {/*COUNTRY AND INDUSTRY*/}
                                            <div className="w-full flex flex-row mt-5">

                                                {/*Country*/}
                                                <div className="w-3/4 mr-2.5">
                                                    <label htmlFor="region"
                                                           className="block text-sm font-medium leading-6">
                                                        Country
                                                    </label>
                                                    <div className="mt-2">
                                                        <Select
                                                            className={`block h-full w-full p-2 rounded-md ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} text-gray-900 shadow-sm`}
                                                            value={country}
                                                            onChange={(e) => setCountry(e.target.value)}
                                                        >
                                                            <option value="United States">United States</option>
                                                            <option value="Afghanistan">Afghanistan</option>
                                                            <option value="Albania">Albania</option>
                                                            <option value="Algeria">Algeria</option>
                                                            <option value="Andorra">Andorra</option>
                                                            <option value="Angola">Angola</option>
                                                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                            <option value="Argentina">Argentina</option>
                                                            <option value="Armenia">Armenia</option>
                                                            <option value="Australia">Australia</option>
                                                            <option value="Austria">Austria</option>
                                                            <option value="Azerbaijan">Azerbaijan</option>
                                                            <option value="Bahamas">Bahamas</option>
                                                            <option value="Bahrain">Bahrain</option>
                                                            <option value="Bangladesh">Bangladesh</option>
                                                            <option value="Barbados">Barbados</option>
                                                            <option value="Belarus">Belarus</option>
                                                            <option value="Belgium">Belgium</option>
                                                            <option value="Belize">Belize</option>
                                                            <option value="Benin">Benin</option>
                                                            <option value="Bhutan">Bhutan</option>
                                                            <option value="Bolivia">Bolivia</option>
                                                            <option value="Bosnia and Herzegovina">Bosnia and
                                                                Herzegovina
                                                            </option>
                                                            <option value="Botswana">Botswana</option>
                                                            <option value="Brazil">Brazil</option>
                                                            <option value="Brunei">Brunei</option>
                                                            <option value="Bulgaria">Bulgaria</option>
                                                            <option value="Burkina Faso">Burkina Faso</option>
                                                            <option value="Burundi">Burundi</option>
                                                            <option value="Cabo Verde">Cabo Verde</option>
                                                            <option value="Cambodia">Cambodia</option>
                                                            <option value="Cameroon">Cameroon</option>
                                                            <option value="Canada">Canada</option>
                                                            <option value="Central African Republic">Central African
                                                                Republic
                                                            </option>
                                                            <option value="Chad">Chad</option>
                                                            <option value="Chile">Chile</option>
                                                            <option value="China">China</option>
                                                            <option value="Colombia">Colombia</option>
                                                            <option value="Comoros">Comoros</option>
                                                            <option value="Congo (Congo-Brazzaville)">Congo
                                                                (Congo-Brazzaville)
                                                            </option>
                                                            <option value="Congo (Democratic Republic)">Congo
                                                                (Democratic
                                                                Republic)
                                                            </option>
                                                            <option value="Costa Rica">Costa Rica</option>
                                                            <option value="Croatia">Croatia</option>
                                                            <option value="Cuba">Cuba</option>
                                                            <option value="Cyprus">Cyprus</option>
                                                            <option value="Czechia">Czechia</option>
                                                            <option value="Denmark">Denmark</option>
                                                            <option value="Djibouti">Djibouti</option>
                                                            <option value="Dominica">Dominica</option>
                                                            <option value="Dominican Republic">Dominican Republic
                                                            </option>
                                                            <option value="Ecuador">Ecuador</option>
                                                            <option value="Egypt">Egypt</option>
                                                            <option value="El Salvador">El Salvador</option>
                                                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option value="Eritrea">Eritrea</option>
                                                            <option value="Estonia">Estonia</option>
                                                            <option value="Eswatini">Eswatini</option>
                                                            <option value="Ethiopia">Ethiopia</option>
                                                            <option value="Fiji">Fiji</option>
                                                            <option value="Finland">Finland</option>
                                                            <option value="France">France</option>
                                                            <option value="Gabon">Gabon</option>
                                                            <option value="Gambia">Gambia</option>
                                                            <option value="Georgia">Georgia</option>
                                                            <option value="Germany">Germany</option>
                                                            <option value="Ghana">Ghana</option>
                                                            <option value="Greece">Greece</option>
                                                            <option value="Grenada">Grenada</option>
                                                            <option value="Guatemala">Guatemala</option>
                                                            <option value="Guinea">Guinea</option>
                                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                            <option value="Guyana">Guyana</option>
                                                            <option value="Haiti">Haiti</option>
                                                            <option value="Honduras">Honduras</option>
                                                            <option value="Hungary">Hungary</option>
                                                            <option value="Iceland">Iceland</option>
                                                            <option value="India">India</option>
                                                            <option value="Indonesia">Indonesia</option>
                                                            <option value="Iran">Iran</option>
                                                            <option value="Iraq">Iraq</option>
                                                            <option value="Ireland">Ireland</option>
                                                            <option value="Israel">Israel</option>
                                                            <option value="Italy">Italy</option>
                                                            <option value="Jamaica">Jamaica</option>
                                                            <option value="Japan">Japan</option>
                                                            <option value="Jordan">Jordan</option>
                                                            <option value="Kazakhstan">Kazakhstan</option>
                                                            <option value="Kenya">Kenya</option>
                                                            <option value="Kiribati">Kiribati</option>
                                                            <option value="Kuwait">Kuwait</option>
                                                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option value="Laos">Laos</option>
                                                            <option value="Latvia">Latvia</option>
                                                            <option value="Lebanon">Lebanon</option>
                                                            <option value="Lesotho">Lesotho</option>
                                                            <option value="Liberia">Liberia</option>
                                                            <option value="Libya">Libya</option>
                                                            <option value="Liechtenstein">Liechtenstein</option>
                                                            <option value="Lithuania">Lithuania</option>
                                                            <option value="Luxembourg">Luxembourg</option>
                                                            <option value="Madagascar">Madagascar</option>
                                                            <option value="Malawi">Malawi</option>
                                                            <option value="Malaysia">Malaysia</option>
                                                            <option value="Maldives">Maldives</option>
                                                            <option value="Mali">Mali</option>
                                                            <option value="Malta">Malta</option>
                                                            <option value="Marshall Islands">Marshall Islands</option>
                                                            <option value="Mauritania">Mauritania</option>
                                                            <option value="Mauritius">Mauritius</option>
                                                            <option value="Mexico">Mexico</option>
                                                            <option value="Micronesia">Micronesia</option>
                                                            <option value="Moldova">Moldova</option>
                                                            <option value="Monaco">Monaco</option>
                                                            <option value="Mongolia">Mongolia</option>
                                                            <option value="Montenegro">Montenegro</option>
                                                            <option value="Morocco">Morocco</option>
                                                            <option value="Mozambique">Mozambique</option>
                                                            <option value="Myanmar">Myanmar</option>
                                                            <option value="Namibia">Namibia</option>
                                                            <option value="Nauru">Nauru</option>
                                                            <option value="Nepal">Nepal</option>
                                                            <option value="Netherlands">Netherlands</option>
                                                            <option value="New Zealand">New Zealand</option>
                                                            <option value="Nicaragua">Nicaragua</option>
                                                            <option value="Niger">Niger</option>
                                                            <option value="Nigeria">Nigeria</option>
                                                            <option value="North Korea">North Korea</option>
                                                            <option value="North Macedonia">North Macedonia</option>
                                                            <option value="Norway">Norway</option>
                                                            <option value="Oman">Oman</option>
                                                            <option value="Pakistan">Pakistan</option>
                                                            <option value="Palau">Palau</option>
                                                            <option value="Panama">Panama</option>
                                                            <option value="Papua New Guinea">Papua New Guinea</option>
                                                            <option value="Paraguay">Paraguay</option>
                                                            <option value="Peru">Peru</option>
                                                            <option value="Philippines">Philippines</option>
                                                            <option value="Poland">Poland</option>
                                                            <option value="Portugal">Portugal</option>
                                                            <option value="Qatar">Qatar</option>
                                                            <option value="Romania">Romania</option>
                                                            <option value="Russia">Russia</option>
                                                            <option value="Rwanda">Rwanda</option>
                                                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis
                                                            </option>
                                                            <option value="Saint Lucia">Saint Lucia</option>
                                                            <option value="Saint Vincent and the Grenadines">Saint
                                                                Vincent and the Grenadines
                                                            </option>
                                                            <option value="Samoa">Samoa</option>
                                                            <option value="San Marino">San Marino</option>
                                                            <option value="Sao Tome and Principe">Sao Tome and Principe
                                                            </option>
                                                            <option value="Saudi Arabia">Saudi Arabia</option>
                                                            <option value="Senegal">Senegal</option>
                                                            <option value="Serbia">Serbia</option>
                                                            <option value="Seychelles">Seychelles</option>
                                                            <option value="Sierra Leone">Sierra Leone</option>
                                                            <option value="Singapore">Singapore</option>
                                                            <option value="Slovakia">Slovakia</option>
                                                            <option value="Slovenia">Slovenia</option>
                                                            <option value="Solomon Islands">Solomon Islands</option>
                                                            <option value="Somalia">Somalia</option>
                                                            <option value="South Africa">South Africa</option>
                                                            <option value="South Korea">South Korea</option>
                                                            <option value="South Sudan">South Sudan</option>
                                                            <option value="Spain">Spain</option>
                                                            <option value="Sri Lanka">Sri Lanka</option>
                                                            <option value="Sudan">Sudan</option>
                                                            <option value="Suriname">Suriname</option>
                                                            <option value="Sweden">Sweden</option>
                                                            <option value="Switzerland">Switzerland</option>
                                                            <option value="Syria">Syria</option>
                                                            <option value="Taiwan">Taiwan</option>
                                                            <option value="Tajikistan">Tajikistan</option>
                                                            <option value="Tanzania">Tanzania</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Togo">Togo</option>
                                                            <option value="Tonga">Tonga</option>
                                                            <option value="Trinidad and Tobago">Trinidad and Tobago
                                                            </option>
                                                            <option value="Tunisia">Tunisia</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Turkmenistan">Turkmenistan</option>
                                                            <option value="Tuvalu">Tuvalu</option>
                                                            <option value="Uganda">Uganda</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="United Arab Emirates">United Arab Emirates
                                                            </option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="Uruguay">Uruguay</option>
                                                            <option value="Uzbekistan">Uzbekistan</option>
                                                            <option value="Vanuatu">Vanuatu</option>
                                                            <option value="Vatican City">Vatican City</option>
                                                            <option value="Venezuela">Venezuela</option>
                                                            <option value="Vietnam">Vietnam</option>
                                                            <option value="Yemen">Yemen</option>
                                                            <option value="Zambia">Zambia</option>
                                                            <option value="Zimbabwe">Zimbabwe</option>
                                                        </Select>
                                                    </div>
                                                </div>

                                                {/*Industry*/}
                                                <div className="w-1/4 ml-2.5">
                                                    <label htmlFor="region" className="block text-sm font-medium leading-6">
                                                        Industry Type
                                                    </label>
                                                    <div className="mt-2">
                                                        <Select
                                                            className={`block h-full w-full p-2 rounded-md ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} text-gray-900 shadow-sm`}
                                                            value={industry}
                                                            onChange={(e) => setIndustry(e.target.value)}
                                                        >
                                                            <option value="">Select...</option>
                                                            <option value="BOTANICAL">Botanical Garden</option>
                                                            <option value="DISTRIB">Product Distributor</option>
                                                            <option value="EMPLOYEE">PFC-EMPLOYEE</option>
                                                            <option value="FARM">Agriculture - Farm</option>
                                                            <option value="GOLF">Golf Course</option>
                                                            <option value="GOVERNMENT">Government Entity</option>
                                                            <option value="INDUSTRIAL">Industrial</option>
                                                            <option value="LAWNTREE">Lawn Care & Tree Care</option>
                                                            <option value="MRSALE">One Time Purchase Expected</option>
                                                            <option value="MUNICIPAL">Municipal/ School</option>
                                                            <option value="Non-Profit">Non-Profit Account</option>
                                                            <option value="OTHER">Other</option>
                                                            <option value="SPORTFLD">Sportsfield</option>

                                                        </Select>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                    </div>

                                    {/* Buttons aligned at the bottom */}
                                    <div className="w-1/2 mr-auto ml-auto flex flex-row justify-end space-x-2">
                                        <button
                                            type="submit"
                                            onClick={handleNextTab}
                                            className="w-full bg-bgLightGreen text-white px-4 py-1 rounded-lg hover:bg-opacity-80"
                                        >
                                            Next →
                                        </button>
                                    </div>
                                </div>

                            </div>


                            {/*CONTACTS DONE*/}
                            <div
                                className={`${
                                    activeTab === 'contacts' ? 'block opacity-100' : 'hidden opacity-0'
                                } transition-opacity duration-150 ease-linear h-full`}
                                id="tabs-contacts"
                                role="tabpanel"
                                aria-labelledby="tabs-contacts"
                            >
                                <div className="h-full w-full flex flex-col justify-between">
                                    {/* Your tab content goes here */}
                                    <div className="flex-grow rounded-lg max-h-[calc(100vh-13.5rem)] scroll overflow-y-auto">
                                        {/* Replace with the content of the tab */}

                                        <div className="flex flex-col">

                                            <div className="w-full flex flex-row">

                                                {/*Customer*/}
                                                <div className="w-2/3 mr-2.5">
                                                    <label htmlFor="address"
                                                           className="block text-sm font-medium leading-6">
                                                        Contact Name
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            required
                                                            placeholder="Contact Name"
                                                            value={contact} onChange={(e) => setContact(e.target.value)}
                                                            className={`block ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                        />
                                                    </div>
                                                </div>

                                                {/*Position*/}
                                                <div className="w-1/3 ml-2.5">
                                                    <label htmlFor="region"
                                                           className="block text-sm font-medium leading-6">
                                                        Contact Position
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            placeholder="Contact Position"
                                                            value={contactPosition}
                                                            onChange={(e) => setContactPosition(e.target.value)}
                                                            className={`block ${darkMode ? 'bg-darkBgColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                            <label htmlFor="region" className="block text-sm font-medium leading-6 mt-5 mb-2">
                                                CONTACT METHODS
                                            </label>

                                            {/*CONTACT AREA*/}
                                            <div className={`w-full flex flex-col justify-between ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} p-3 rounded-lg`}>


                                                <div className="w-full flex flex-row">

                                                    <div className="w-full">
                                                        <label htmlFor="address"
                                                               className="block text-sm font-medium leading-6">
                                                            Contact Phone
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                placeholder="Contact Phone"
                                                                value={contactPhone}
                                                                onChange={(e) => setContactPhone(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>


                                                <div className="w-full flex flex-row mt-5">

                                                    <div className="w-full mr-1.5">
                                                        <label htmlFor="address"
                                                               className="block text-sm font-medium leading-6">
                                                            Contact Email
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                placeholder="Optional"
                                                                value={contactEmail}
                                                                onChange={(e) => setContactEmail(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="w-full ml-1.5">
                                                        <label htmlFor="address"
                                                               className="block text-sm font-medium leading-6">
                                                            Website
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                placeholder="Optional"
                                                                value={website}
                                                                onChange={(e) => setWebsite(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>

                                            <label htmlFor="region" className="block text-sm font-medium leading-6 mt-5 mb-2">
                                                BILLING CONTACTS
                                            </label>

                                            {/*BILLING AREA*/}
                                            <div className={`w-full flex flex-col justify-between ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} p-3 rounded-lg`}>

                                                {/*Billing contact*/}
                                                <div className="w-full flex flex-row">

                                                    <div className="w-full">
                                                        <label htmlFor="address"
                                                               className="block text-sm font-medium leading-6">
                                                            Billing Contact
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                placeholder="Billing Contact Name"
                                                                value={billingContact}
                                                                onChange={(e) => setBillingContact(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                                {/*Billing phone*/}
                                                <div className="w-full flex flex-row mt-5">

                                                    <div className="w-full">
                                                        <label htmlFor="address"
                                                               className="block text-sm font-medium leading-6">
                                                            Billing Phone
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                required
                                                                placeholder="Billing Phone"
                                                                value={billingPhone}
                                                                onChange={(e) => setBillingPhone(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>

                                            <label htmlFor="region" className="block text-sm font-medium leading-6 mt-5 mb-2">
                                                INVOICE AND STATEMENTS
                                            </label>

                                            {/*INVOICE AREA*/}
                                            <div className={`w-full flex flex-col justify-between ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} p-3 rounded-lg`}>

                                                {/*Invoice Emails*/}
                                                <div className="w-full flex flex-row">

                                                    <div className="w-full">
                                                        <label htmlFor="address"
                                                               className="block text-sm font-medium leading-6">
                                                            Invoice Email
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                placeholder="Invoice Email"
                                                                value={invoiceEmails}
                                                                onChange={(e) => setInvoiceEmails(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>

                                                {/*Invoice Phone*/}
                                                <div className="w-full flex flex-row mt-5">

                                                    <div className="w-full">
                                                        <label htmlFor="address"
                                                               className="block text-sm font-medium leading-6">
                                                            Statement Email
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                placeholder="Statement Email"
                                                                required
                                                                value={statementEmails}
                                                                onChange={(e) => setStatementEmails(e.target.value)}
                                                                className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                            />
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    {/* Buttons aligned at the bottom */}
                                    <div className="w-full flex flex-row justify-end space-x-2">
                                        <button
                                            onClick={handlePreviousTab}
                                            className="w-full bg-bgLightGreen text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            ← Previous
                                        </button>
                                        <button
                                            onClick={handleNextTab}
                                            className="w-full bg-bgLightGreen text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            Next →
                                        </button>
                                    </div>
                                </div>
                            </div>


                            {/*SHIPPING DONE*/}
                            <div
                                className={`${
                                    activeTab === 'shipping' ? 'block opacity-100' : 'hidden opacity-0'
                                } transition-opacity duration-150 ease-linear h-full`}
                                id="tabs-shipping"
                                role="tabpanel"
                                aria-labelledby="tabs-shipping"
                            >
                                <div className="h-full w-full flex flex-col justify-between">
                                    {/* Your tab content goes here */}
                                    <div className="flex-grow h-full">
                                        {/* Replace with the content of the tab */}

                                        <div className="flex flex-col h-full">

                                            <div className="w-full flex flex-row">

                                                {/*Shipping Label*/}
                                                <div className="w-2/3 mr-2.5">
                                                    <label htmlFor="address"
                                                           className="block text-sm font-medium leading-6">
                                                        Shipping Locations
                                                    </label>
                                                    <div className="mt-2">
                                                        <div className="block w-full p-2 rounded-md py-1.5">
                                                            You may add more than one shipping location to a customer's
                                                            account.
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*Shipping Button*/}
                                                <div className="w-1/3 ml-2.5">
                                                    <label htmlFor="region"
                                                           className="block text-sm font-medium leading-6">
                                                        Add Multiple Locations
                                                    </label>
                                                    <div className="mt-2">
                                                        <Button
                                                            onClick={handleAddComponent}
                                                            className="block w-full p-2 bg-bgLightGreen hover:bg-opacity-80 text-white rounded-md py-1.5 shadow-sm"
                                                        >Add Shipping Location</Button>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="w-full h-full mt-3 border shadow-md rounded-lg p-3 mb-3">

                                                <div className="max-h-[calc(100vh-20rem)] scroll overflow-y-auto">
                                                    {shippingAddress.length === 0 ? (
                                                        // Display custom dialog if sprays array is empty
                                                        <div className="flex w-full h-full justify-center mt-auto mb-auto items-center py-10">

                                                            <div className={`flex items-center ${darkMode ? 'text-white bg-darkAccentColor' : 'text-black bg-bgColor border'} w-1/3 h-fit mr-auto ml-auto justify-center rounded-lg drop-shadow`}>
                                                                <div className="p-4 rounded-md shadow-gray-900 flex items-center justify-center flex-col m-10">
                                                                    <p className="text-xl">No Shipping Location</p>
                                                                    <p className="text-lg mt-2">Add a Location</p>
                                                                    <p className="text-xs">1] Press the "Add Shipping Location" button above.</p>
                                                                    <p className="text-xs">2] Fill out the following form.</p>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    ) : (
                                                        // Display SprayCard components if sprays array is not empty
                                                        shippingAddress.map((item, index) => (
                                                            <AddressInput
                                                                id={item.id}
                                                                key={item.id}
                                                                index={index}
                                                                onChange={handleAddressChange}
                                                                onRemove={handleRemoveComponent}
                                                            />
                                                        ))
                                                    )}
                                                </div>

                                            </div>


                                        </div>

                                    </div>

                                    {/* Buttons aligned at the bottom */}
                                    <div className="w-full flex flex-row justify-end space-x-2">
                                        <button
                                            onClick={handlePreviousTab}
                                            className="w-full bg-bgLightGreen text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            ← Previous
                                        </button>
                                        <button
                                            onClick={handleNextTab}
                                            className="w-full bg-bgLightGreen text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            Next →
                                        </button>
                                    </div>
                                </div>
                            </div>


                            {/*FINANCES*/}
                            <div
                                className={`${
                                    activeTab === 'taxes' ? 'block opacity-100' : 'hidden opacity-0'
                                } transition-opacity duration-150 ease-linear h-full`}
                                id="tabs-taxes"
                                role="tabpanel"
                                aria-labelledby="tabs-taxes"
                            >
                                <div className="h-full w-full flex flex-col justify-between">
                                    {/* Your tab content goes here */}
                                    <div className="flex-grow max-h-[calc(100vh-13.5rem)] scroll overflow-y-auto">
                                        {/* Replace with the content of the tab */}

                                        <div className="flex flex-row w-full h-full">


                                            {/*Fields*/}
                                            <div className="flex flex-col w-1/3 mr-1.5">

                                                <label htmlFor="region" className="block text-sm font-medium leading-6 mb-2">
                                                    TAXES
                                                </label>

                                                {/*TAXES AREA*/}
                                                <div className={`w-full flex flex-col justify-between ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} p-3 rounded-lg`}>

                                                    {/*Tax ID#*/}
                                                    <div className="w-full flex flex-row">

                                                        <div className="w-full">
                                                            <label htmlFor=""
                                                                   className="block text-sm font-medium leading-6">
                                                                Tax ID#
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    placeholder="Tax ID#"
                                                                    required
                                                                    value={taxID} onChange={(e) => setTaxID(e.target.value)}
                                                                    className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>

                                                    {/*Sales Tax Status*/}
                                                    <div className="w-full flex flex-row mt-5">

                                                        <div className="w-full">
                                                            <label htmlFor=""
                                                                   className="block text-sm font-medium leading-6">
                                                                Sales Tax Status
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    placeholder="Sales Tax Status"
                                                                    value={salesTaxStatus} onChange={(e) => setSalesTaxStatus(e.target.value)}
                                                                    className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>

                                                    {/*Exempt Code*/}
                                                    <div className="w-full flex flex-row mt-5">

                                                        <div className="w-full">
                                                            <label htmlFor=""
                                                                   className="block text-sm font-medium leading-6">
                                                                Tax Exemption code
                                                            </label>
                                                            <div className="mt-2">
                                                                <input
                                                                    required
                                                                    placeholder="Optional"
                                                                    value={taxExempCode} onChange={(e) => setTaxExempCode(e.target.value)}
                                                                    className={`block ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} focus:outline-none w-full p-2 rounded-md py-1.5 text-gray-900 shadow-sm`}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                                <label htmlFor="region" className="block text-sm font-medium leading-6 mt-5 mb-2">
                                                    PAYMENT
                                                </label>

                                                {/*PAYMENT*/}
                                                <div className={`w-full flex ${darkMode ? 'bg-darkBgColor' : 'bg-bgColor border'} p-3 rounded-lg flex-row`}>

                                                    <div className="w-full">
                                                        <label htmlFor="region"
                                                               className="block text-sm font-medium leading-6">
                                                            Payment Type
                                                        </label>
                                                        <div className="mt-2">
                                                            <select
                                                                id="county"
                                                                value={paymentType} onChange={(e) => setPaymentType(e.target.value)}
                                                                className={`block h-full w-full p-2 rounded-md ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border text-black'} text-gray-900 shadow-sm`}
                                                            >
                                                                <option value="">--Select a Payment Option--</option>
                                                                <option value="Check">Check</option>
                                                                <option value="ACH">ACH</option>
                                                                <option value="Credit">Credit Card (3% Service Fee)</option>

                                                            </select>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>


                                            {/*Content*/}
                                            <div className="flex flex-col w-2/3 ml-1.5 h-full">

                                                <div className="mx-auto max-w-7xl px-6 mt-auto mb-auto">
                                                    <div className="mx-auto max-w-2xl lg:text-center">
                                                        <h2 className="text-base font-semibold leading-7 text-green-600">Next
                                                            Steps</h2>
                                                        <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">
                                                            You're Almost Done!
                                                        </p>
                                                        <p className="mt-6 text-lg leading-8">
                                                            Upon completing your onboarding form a
                                                            representative from PlantFood Company will be in touch
                                                            shortly to assist you with completing your financial section
                                                            and help you fill out your credit application.
                                                        </p>
                                                        <p className="mt-6 text-lg leading-8 text-green-600">
                                                            We look forward to working with you!
                                                        </p>
                                                    </div>
                                                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                                                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                                                            {features.map((feature) => (
                                                                <div key={feature.name} className="relative pl-16">
                                                                <dt className="text-base font-semibold leading-7">
                                                                        <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-bgLightGreen">
                                                                            <feature.icon aria-hidden="true" className="h-6 w-6 text-white"/>
                                                                        </div>
                                                                        {feature.name}
                                                                    </dt>
                                                                    <dd className="mt-2 text-base leading-7">{feature.description}</dd>
                                                                </div>
                                                            ))}
                                                        </dl>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                    </div>

                                    {/* Buttons aligned at the bottom */}
                                    <div className="w-full flex flex-row justify-end space-x-2">
                                        <button
                                            onClick={handlePreviousTab}
                                            className="w-full bg-bgLightGreen text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            ← Previous
                                        </button>
                                        <button
                                            onClick={handleNextTab}
                                            className="w-full bg-bgLightGreen text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            Next →
                                        </button>
                                    </div>
                                </div>
                            </div>


                            {/*DELIVERY DONE*/}
                            <div
                                className={`${
                                    activeTab === 'delivery' ? 'block opacity-100' : 'hidden opacity-0'
                                } transition-opacity duration-150 ease-linear h-full`}
                                id="tabs-delivery"
                                role="tabpanel"
                                aria-labelledby="tabs-delivery"
                            >
                                <div className="h-full w-full flex flex-col justify-between">
                                    {/* Your tab content goes here */}
                                    <div className="flex-grow max-h-[calc(100vh-13.5rem)] scroll overflow-y-auto">

                                        <div className="flex flex-row h-full">

                                            {/*Questions / Notes*/}
                                            <div className="w-1/3 flex flex-col max-h-[calc(87vh-5.5rem)] scroll overflow-y-auto mr-1.5">

                                                {/*Delivery Questions*/}
                                                <div className={`flex flex-col w-full ${darkMode ? 'bg-darkBgColor' : 'border'} rounded-lg p-3 mb-3`}>

                                                    <div className={`flex flex-row w-full ${darkMode ? 'bg-darkMainColor' : 'bg-bgColor'} rounded-lg drop-shadow mb-3`}>
                                                        <div className="w-3/4 p-3 ">Delivery Questions?</div>
                                                        <div className="w-1/4 text-center p-3">[Yes] [No]</div>
                                                    </div>

                                                    <div className="flex w-full flex-row">
                                                        <div className="space-y-7 w-3/4 p-3">
                                                            <div className="p-1 border-b">Tractor Trailer Accessible?</div>
                                                            <div className="p-1 border-b">Limited Access Site?</div>
                                                            <div className="p-1 border-b">Loading Dock Available?</div>
                                                            <div className="p-1 border-b">Lift Gate & Pallet Jack Required?</div>
                                                            <div className="p-1 border-b">Delivery Appointment Required?</div>
                                                            <div className="p-1 border-b">Heated Storage Available?</div>
                                                            <div className="p-1 border-b">Forklift Available w/ 3,000 lbs Capacity?</div>
                                                        </div>

                                                        <div className={`ml-5 w-1/4 space-y-5 ${darkMode ? 'bg-darkMainColor' : 'bg-bgColor'} drop-shadow p-3 rounded-lg`}>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="tta"
                                                                    name="tta"
                                                                    checked={TTA}
                                                                    onChange={toggleTTA} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="tta" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${TTA ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {TTA ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${TTA ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {TTA ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="las"
                                                                    name="las"
                                                                    checked={LAS}
                                                                    onChange={toggleLAS} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="las" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${LAS ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {LAS ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${LAS ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {LAS ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="lda"
                                                                    name="lda"
                                                                    checked={LDA}
                                                                    onChange={toggleLDA} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="lda" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${LDA ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {LDA ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${LDA ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {LDA ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="pjr"
                                                                    name="pjr"
                                                                    checked={PJR}
                                                                    onChange={togglePJR} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="pjr" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${PJR ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {PJR ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${PJR ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {PJR ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="dar"
                                                                    name="dar"
                                                                    checked={DAR}
                                                                    onChange={toggleDAR} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="dar" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${DAR ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {DAR ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${DAR ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {DAR ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="hsa"
                                                                    name="hsa"
                                                                    checked={HSA}
                                                                    onChange={toggleHSA} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="hsa" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${HSA ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {HSA ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${HSA ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {HSA ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                            <div className="p-1">
                                                                <input
                                                                    type="checkbox"
                                                                    id="flc"
                                                                    name="flc"
                                                                    checked={FLC}
                                                                    onChange={toggleFLC} // Call the toggle function from context
                                                                    className="sr-only w-fit"
                                                                />
                                                                <label htmlFor="flc" className="cursor-pointer">

                                                                    <div
                                                                        className={`mr-auto ml-auto w-14 h-8 rounded-full relative text-xs pt-1.5 font-extrabold text-white ${FLC ? 'bg-bgLightGreen pl-1' : 'bg-red-300 drop-shadow shadow-gray-900 pl-8'}`}>

                                                                        {FLC ? 'Yes' : 'No'}

                                                                        <div
                                                                            className={`dot absolute left-1 top-1 w-6 h-6 rounded-full transition-transform duration-300 bg-bgColor mr-auto ml-auto flex items-center justify-center text-white font-bold ${FLC ? 'translate-x-full' : 'drop-shadow shadow-gray-900'}`}>
                                                                            {/* Display Yes or No based on the darkMode state */}

                                                                            {FLC ? (
                                                                                <CheckCircleIcon
                                                                                    className="w-5 h-full flex-none text-bgLightGreen mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            ) : (
                                                                                <XCircleIcon
                                                                                    className="w-5 h-full flex-none text-red-300 mr-auto ml-auto mt-auto mb-auto"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                </label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                {/*Delivery Notes*/}
                                                <div className={`flex flex-col w-full ${darkMode ? 'bg-darkBgColor' : 'border'} rounded-lg p-3`}>
                                                    <div className="mb-1">Special Delivery Instructions</div>

                                                    <textarea
                                                        id=""
                                                        name=""
                                                        autoComplete=""
                                                        placeholder="Notes..."
                                                        value={deliveryInstructions}
                                                        onChange={(e) => setDeliveryInstructions(e.target.value)}
                                                        className={`block w-full focus:outline-none ${darkMode ? 'bg-darkMainColor text-white' : 'bg-white border ring-1 ring-inset ring-gray-300'} p-2 h-full rounded-md shadow-gray-900 py-1.5 placeholder:text-gray-400`}
                                                    />
                                                </div>

                                            </div>

                                            {/*Info Center*/}
                                            <div className="w-2/3 flex flex-col mt-auto mb-auto h-full ml-1.5">

                                                <div
                                                    className={`mx-auto max-w-7xl px-6 lg:px-8 mt-auto mb-auto overflow-hidden`}>
                                                    <div
                                                        className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                                                        <div
                                                            className="lg:pr-8 lg:pt-4 max-h-[calc(87vh-5.5rem)] scroll overflow-y-auto">
                                                            <div className="lg:max-w-lg">
                                                                <h2 className="text-base font-semibold leading-7 text-green-600">Thank
                                                                    You for Joining Us</h2>
                                                                <p className="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">You're
                                                                    All Set!</p>
                                                                <p className="mt-6 text-xs leading-8">
                                                                    Thank you for completing the onboarding
                                                                    process.
                                                                    We're excited to have you on board as a
                                                                    valued
                                                                    customer.
                                                                    For more information about our products,
                                                                    services,
                                                                    and the latest updates, visit our website at
                                                                    <a href="https://www.plantfoodco.com/"
                                                                       className="text-green-600 hover:text-indigo-800"> www.plantfoodco.com</a>.
                                                                    There, you’ll find product guides,
                                                                    application tips,
                                                                    and support resources to help you maximize
                                                                    the
                                                                    benefits of
                                                                    our fertilizers.
                                                                </p>
                                                                <p className="mt-6 text-xs leading-8">
                                                                    If you have any further questions or need
                                                                    assistance, feel free to reach out to our
                                                                    customer
                                                                    support team through
                                                                    our <a
                                                                    href="https://www.plantfoodco.com/contact/"
                                                                    className="text-green-600 hover:text-indigo-800"> Contact
                                                                    Us</a> page.
                                                                </p>
                                                                {/*<dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">*/}
                                                                {/*    {features.map((feature) => (*/}
                                                                {/*        <div key={feature.name}*/}
                                                                {/*             className="relative pl-9">*/}
                                                                {/*            <dt className="inline font-semibold text-gray-900">*/}
                                                                {/*                <feature.icon aria-hidden="true"*/}
                                                                {/*                              className="absolute left-1 top-1 h-5 w-5 text-bgLightGreen"/>*/}
                                                                {/*                {feature.name}*/}
                                                                {/*            </dt>*/}
                                                                {/*            {' '}*/}
                                                                {/*            <dd className="inline text-xs">{feature.description}</dd>*/}
                                                                {/*        </div>*/}
                                                                {/*    ))}*/}
                                                                {/*</dl>*/}
                                                            </div>
                                                        </div>
                                                        <img
                                                            alt="Product screenshot"
                                                            src={websiteSRC}
                                                            width={2432}
                                                            height={1442}
                                                            className="w-[48rem] max-w-none mt-auto mb-auto rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    {/* Buttons aligned at the bottom */}
                                    <div className="w-full flex flex-row justify-end space-x-2">
                                        <button
                                            onClick={handlePreviousTab}
                                            className="w-full bg-bgLightGreen text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            ← Previous
                                        </button>
                                        <button
                                            onClick={onSubmitMain}
                                            className="w-full bg-bgLightGreen text-white px-4 py-1 rounded hover:bg-opacity-80"
                                        >
                                            SUBMIT TO PLANT
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </div>

                </form>

            </div>


        </div>
    )
};


export default NewCustomer;
